import sellerServices from '../../../../data/services/sellerServices'

const fetchBrandDelete = async data => {
  try {
    const deleted = await sellerServices.brandsDelete(data)
    return deleted
  } catch (e) {
    return null
  }
}
export default fetchBrandDelete
