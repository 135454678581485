import { createAsyncThunk } from '@reduxjs/toolkit'

import orderService from '../../../data/services/orderService'
import { decodeJwt } from '../../../data/secrets/decodeJwt'

const fechOrderBilling = createAsyncThunk(
  'order/billing',
  async ({ id }, { signal }) => {
    if (!id) {
      return null
    }
    const data = decodeJwt(process.env.REACT_APP_KEY_ORDERS_H512)(
      await orderService.orderBilling({
        id,
        signal
      })
    )

    return data
  }
)
export default fechOrderBilling
