import sellerServices from '../../../data/services/sellerServices'
import { createAsyncThunk } from '@reduxjs/toolkit'

const fetchSellers = createAsyncThunk(
  'sellers/list',
  async (query, { signal }) => {
    const sellers = await sellerServices.sellerList({
      query,
      signal
    })
    return sellers
  }
)
export default fetchSellers
