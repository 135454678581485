import { createAsyncThunk } from '@reduxjs/toolkit'
import productAdminServices from '../../../data/services/productAdminServices'
import normalizeProducts from './normalizeProducts'
import isEmpty from 'lodash/isEmpty'

const getDirection = (prevSkip, skip) =>
  prevSkip <= skip ? 'next' : 'back'
export const getSortBy = new Map([
  ['title', 'TITLE'],
  ['updatedAt', 'UPDATED_AT'],
  ['vendor', 'VENDOR'],
  ['productType', 'PRODUCT_TYPE'],
  ['createdAt', 'CREATED_AT']
])

const fetchProducts = createAsyncThunk(
  'products/list',

  async (
    {
      sellerList = [],
      sortBy = 'UPDATED_AT',
      brands = [],
      limit = 20,
      skip = 0,
      query = '',
      status = undefined,
      category = undefined
    },
    { rejectWithValue, getState, signal }
  ) => {
    try {
      const brandList = getState().brands.allBrands
      const brandListWithSeller = !isEmpty(sellerList)
        ? [...brandList].filter(brand =>
            sellerList?.some(
              seller => String(seller) === String(brand.seller.nameSeller)
            )
          )
        : brandList
      const currentBrandList = !isEmpty(brands)
        ? brandListWithSeller.filter(({ brandName }) =>
            brands?.some(brand => String(brand) === String(brandName))
          )
        : brandListWithSeller

      const state = getState().products.list
      const prevSkip = getState().products.list.pagination.skip
      const direction = skip === 0 ? 'next' : getDirection(prevSkip, skip)

      const data = await productAdminServices.getProducts({
        brands: currentBrandList,
        brandsAll: brandList,
        title: query || state.query,
        status: status || state.statusList,
        cursor:
          skip === 0
            ? null
            : direction !== 'next'
              ? state.pagination.startCursor
              : state.pagination.endCursor,
        direction,
        limit,
        sortKey: sortBy ? getSortBy.get(sortBy[0].id) : 'UPDATED_AT',
        reverse: sortBy ? sortBy[0].desc : true,
        category,
        signal
      })

      const { products, pageInfo } = data

      const normalize = normalizeProducts(products)?.map(product => ({
        ...product,
        productDetail: `productos/${product.id.split('Product/')[1]}`,
        to: {
          pathname: `/productos/${product.id.split('Product/')[1]}`,
          state: product
        }
      }))
      if ([...normalize].length === 0) {
        return rejectWithValue('Sin contenido')
      }
      const brandListState = getState().brands?.allBrands
      const normalizeSeller = normalize.map(data => {
        const seller = brandListState.find(
          brand => brand.brandName === data.vendor
        )
        return {
          seller: seller?.seller?.nameSeller || '',
          ...data
        }
      })
      return {
        products: normalizeSeller,
        pagination: { ...pageInfo, skip }
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  {
    condition: ({ sortBy }, { getState }) => {
      const state = getState().brands
      if (state?.status !== 'finished') {
        return false
      }
      const sortKey = sortBy[0]?.id
      if (!getSortBy.has(sortKey)) {
        return false
      }
    }
  }
)
export default fetchProducts
