import { useEffect } from 'react'
import { useFieldArray } from 'react-hook-form'
import PropTypes from 'prop-types'

import Card from '../../molecules/CardWithTitleAndButton'

export function SellersCampaignCard({ control }) {
  const { append } = useFieldArray({
    control,
    name: 'sellers'
  })

  useEffect(() => {
    append('')
  }, [append])

  return (
    <Card
      title="Sellers"
      buttonTitle=" + Agregar Seller"
      handleClick={() => append('')}
    />
  )
}

SellersCampaignCard.propTypes = {
  control: PropTypes.any
}

export default SellersCampaignCard
