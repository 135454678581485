import sessionStore from '../../../data/sessionPersistentStore'
import { createSlice } from '@reduxjs/toolkit'
import { createSelector } from '@reduxjs/toolkit'
import dayjs from 'dayjs'

const initialState = {
  dates: sessionStore.isEmptyRangeDatePayout()
    ? [null, null]
    : sessionStore.getRangeDatePayout()
}

const rangeOfDaysPayoutSlice = createSlice({
  name: 'datePayout',
  initialState,
  reducers: {
    addRangeOfDaysPayout(state, action) {
      const dates = action.payload
      const newDates = [
        dayjs(dates[0]).isValid()
          ? dayjs(dates[0]).startOf('day').format()
          : null,
        dayjs(dates[1]).isValid()
          ? dayjs(dates[1]).endOf('day').format()
          : null
      ]
      state.dates = newDates
      sessionStore.setRangeDatePayout(newDates)
    },
    resetRangeOfDaysPayout(state) {
      sessionStore.removeRangeDatePayout()
      state.dates = [null, null]
    }
  }
})

export const { addRangeOfDaysPayout, resetRangeOfDaysPayout } =
  rangeOfDaysPayoutSlice.actions

const getDaySelector = state => state.ui.rangeOfDayPayout

export const getCurrentDatePayout = createSelector(
  [getDaySelector],
  state =>
    state?.dates
      ? state.dates.map(date => (date ? dayjs(date).toDate() : null))
      : [null, null]
)

const reducer = rangeOfDaysPayoutSlice.reducer
export default reducer
