import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  addOneSeller,
  addSellers,
  getSellers,
  allSellers,
  removeAllSeller
} from '../../../../domain/features/sellers/getSellers'
import fetchSellers from '../../../../domain/features/sellers/fetchSellers'
import CustomSelectStatus from '../CustomSelectStatus/CustomSelectStatus'
import { useState } from 'react'

const SelectWithChipsSellersAutocomplete = ({ ...props }) => {
  const sellers = useSelector(allSellers)
  const allSellersState = useSelector(getSellers)

  const dispatch = useDispatch()
  const [sellerList, setSellerList] = useState([])
  useEffect(() => {
    const promise = dispatch(fetchSellers())
    return () => promise.abort()
  }, [dispatch])

  const handleChange = (event, value) => {
    const isDelete = value.find(field => field.id === 9999)
    if (isDelete) {
      dispatch(removeAllSeller([]))
    } else {
      value.map(val => dispatch(addOneSeller(val.title)))
    }
  }
  useEffect(() => {
    if (sellerList.length > 0) {
      dispatch(addSellers(sellerList))
    } else {
      dispatch(removeAllSeller([]))
    }
  }, [sellerList, dispatch])

  return (
    <div>
      <CustomSelectStatus
        label="Sellers"
        defaultStatus={allSellersState.allSellers}
        setSelectedStatus={setSellerList}
        selectedStatus={sellers}
        onChange={handleChange}
        value={sellers.map(sl => {
          return { title: sl }
        })}
        {...props}
      />
    </div>
  )
}

export default SelectWithChipsSellersAutocomplete
