import map from 'lodash/fp/map'
import pipe from 'lodash/fp/pipe'
import filter from 'lodash/fp/filter'
import round from '../../utils/round'
import tableDateFormat from '../../adpaters/days/tableDateFormat'

import normalizeOrder from '../orders/normalizeOrder'

const normalizeLiquidations = ({
  payout,
  suborders,
  accountingStatement,
  id,
  sellerName,
  payoutDate,
  idLiq,
  idFact,
  fact,
  idPago,
  number,
  seller,
  commission,
  otherDiscount,
  dispatchedSale,
  totalSale,
  excelReport,
  pdfReport,
  payoutReport,
  commissionReport,
  commissionRecalc,
  payoutRecalc
}) => ({
  payoutDate,
  id,
  numberId: number,
  sellerName,
  sellerRut: seller?.rut,
  date: tableDateFormat(payoutDate),
  totalSale: round(totalSale),
  payout: round(payout),
  commission: round(commission) + round(otherDiscount),
  dispatchedSale: round(dispatchedSale),
  accountingStatement,
  idLiquidate: idLiq,
  idBill: idFact,
  bill: fact,
  idPaid: idPago,
  payoutReport,
  commissionReport,
  payoutRecalc,
  commissionRecalc,
  report: {
    excelReport,
    pdfReport
  },
  detail: {
    sellerName,
    payoutId: id,
    orders: pipe(
      map(normalizeOrder),
      filter(
        order =>
          String(order.sellerName).toLowerCase() ===
          String(sellerName).toLowerCase()
      )
    )(suborders)
  }
})

export default normalizeLiquidations
