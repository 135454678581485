export const GeneralitiesItems = [
  {
    name: 'Accesorios',
    placeholder: 'Ej: “Desagüe | Rebalse | Sifón”',
    nameController: 'productAccesories'
  },
  {
    name: 'Armado',
    placeholder: 'Ej: “No requiere armado”',
    nameController: 'built'
  },
  {
    name: 'Cantidad de programas',
    placeholder: 'Ej: “6”',
    nameController: 'ovenProgramsNumber'
  },
  {
    name: 'Cantidad de velocidades',
    placeholder: 'Ej: “3”',
    nameController: 'speedsNumber'
  },
  {
    name: 'Capacidad útil total (L)',
    placeholder: 'Ej: “3.5”',
    nameController: 'totalUsableCapacity'
  },
  {
    name: 'Clase energética',
    placeholder: 'Ej: “A+”',
    nameController: 'energyClass'
  },
  {
    name: 'Consideraciones',
    placeholder: 'Ej: “Producto  no incluye regulador de gas.”',
    nameController: 'productConsiderations'
  },
  {
    name: 'Consumo (KwH/Mes)',
    placeholder: 'Ej: “29.4”',
    nameController: 'consume'
  },
  {
    name: 'Enchufe',
    placeholder: 'Ej: “No incluye”',
    nameController: 'plug'
  },
  {
    name: 'Instalación',
    placeholder: 'Ej: “Free Standing”',
    nameController: 'productInstalation'
  },
  {
    name: 'Intensidad',
    placeholder: '',
    nameController: 'intensity'
  },
  {
    name: 'Largo del cable (cm)',
    placeholder: '',
    nameController: 'cableLength'
  },
  {
    name: 'Luz',
    placeholder: 'Ej: “Led”',
    nameController: 'light'
  },
  {
    name: 'Mandos',
    placeholder: 'Ej: “Análogos”',
    nameController: 'commands'
  },
  {
    name: 'Medidas encastre (alto x ancho x fondo)',
    placeholder: 'Ej: “800 x 600 x 600 mm”',
    nameController: 'embeddedMeasurements'
  },
  {
    name: 'Panel',
    placeholder: 'Ej: “Análogo”',
    nameController: 'panel'
  },
  {
    name: 'Potencia',
    placeholder: 'Ej: “7600 W”',
    nameController: 'power'
  },
  {
    name: 'Seguridad',
    placeholder: '',
    nameController: 'security'
  },
  {
    name: 'Temperatura',
    placeholder: 'Ej: “50 - 250 °C”',
    nameController: 'temperature'
  },
  {
    name: 'Tensión',
    placeholder: 'Ej: “220 - 240 V”',
    nameController: 'productTension'
  },
  {
    name: 'Tipo de energía',
    placeholder: 'Ej: “Gas licuado”',
    nameController: 'energyType'
  },
  {
    name: 'Video',
    placeholder: 'Ej: ”youtube.com/watch?v=example”  ',
    helperText:
      'Ingresar URL de video en Youtube según indicaciones del manual. ',
    nameController: 'video'
  },
  {
    name: 'Aspiración máxima (m3/H)',
    placeholder: 'Ej: “350”',
    nameController: 'maxSuction'
  },
  {
    name: 'Cantidad de quemadores',
    placeholder: 'Ej: “4”',
    nameController: 'burnersNumber'
  },
  {
    name: 'Diámetro del tubo (cm)',
    placeholder: 'Ej: “12”',
    nameController: 'tubeDiameter'
  },
  {
    name: 'Niveles de calor quemadores',
    placeholder: 'Ej: “2”',
    nameController: 'burnersHeatLevels'
  },
  {
    name: 'Ruido máximo (dBA)',
    placeholder: 'Ej: “72”',
    nameController: 'maxNoise'
  },
  {
    name: 'Tipo de Campana',
    placeholder: 'Ej: “Bajo mueble telescópicas”',
    nameController: 'bellType'
  },
  {
    name: 'Tipo de encimera',
    placeholder: 'Ej: “Encimera vitroceramica”',
    nameController: 'countertopType'
  },
  {
    name: 'Tipo de horno',
    placeholder: 'Ej: “Horno a carbón”',
    nameController: 'ovenType'
  },
  {
    name: 'Tipo de parrilla',
    placeholder: 'Ej: “Parrilla a gas”',
    nameController: 'grillType'
  },
  {
    name: 'Cantidad de botellas',
    placeholder: 'Ej: “12”',
    nameController: 'bottlesQuantity'
  },
  {
    name: 'Cantidad de programas lavado',
    placeholder: 'Ej: “15”',
    nameController: 'washingProgramsNumber'
  },
  {
    name: 'Cantidad de programas secado',
    placeholder: 'Ej: “15”',
    nameController: 'dryProgramsNumber'
  },
  {
    name: 'Capacidad útil freezer (L)',
    placeholder: 'Ej: “357”',
    nameController: 'freezerUsableCapacity'
  },
  {
    name: 'Capacidad útil lavadora (kg)',
    placeholder: 'Ej: “20kg”',
    nameController: 'washingMachineUsableCapacity'
  },
  {
    name: 'Capacidad útil refrigerador (L)',
    placeholder: 'Ej: “114”',
    nameController: 'refrigeratorUsableCapacity'
  },
  {
    name: 'Capacidad útil secadora (kg)',
    placeholder: 'Ej: “6kg”',
    nameController: 'dryerUsableCapacity'
  },
  {
    name: 'Gas refrigerante',
    placeholder: 'Ej: “R600A”',
    nameController: 'refrigerantGas'
  },
  {
    name: 'Tecnología de frío',
    placeholder: 'Ej: “No frost”',
    nameController: 'coldTechnology'
  },
  {
    name: 'Tipo de carga',
    placeholder: 'Ej: “Carga por parte superior”',
    nameController: 'loadType'
  },
  {
    name: 'Tipo de freezer',
    placeholder: 'Ej: “Top freezer”',
    nameController: 'freezerType'
  },
  {
    name: 'Tipo de función',
    placeholder: 'Ej: “Lavadora Secadora”',
    nameController: 'functionType'
  },
  {
    name: 'Tipo de grifería',
    placeholder: 'Ej: “Monomando extensible”',
    nameController: 'tapType'
  },
  {
    name: 'Tipo de refrigerador',
    placeholder: 'Ej: “Combinado”',
    nameController: 'refrigeratorType'
  }
]
