import styled from 'styled-components'
import Field from '../Field'
import InputAdornment from '@material-ui/core/InputAdornment'
import { bool, string, object } from 'prop-types'

const ContainerInputWeight = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 13px;
`

const FieldWeight = ({ name, helperText, error, ...props }) => {
  return (
    <ContainerInputWeight>
      <Field
        {...props}
        type="number"
        step="0.01"
        fullWidth
        InputProps={{
          endAdornment: <InputAdornment position="end">kg</InputAdornment>
        }}
        id={name}
        name={name}
        error={error}
        helperText={helperText}
      />
    </ContainerInputWeight>
  )
}

FieldWeight.propTypes = {
  label: string,
  required: bool,
  placeholder: string,
  helperText: string,
  name: string,
  error: object
}

export default FieldWeight
