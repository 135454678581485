// eslint-disable-next-line import/no-anonymous-default-export
export default [
  'Accesorios de Cocina',
  'Accesorios de Compras',
  'Accesorios de Limpieza',
  'Accesorios de Repostería',
  'Accesorios para Batidoras',
  'Accesorios para Cafeteras',
  'Accesorios para Parrillas',
  'Accesorios para Pizza',
  'Accesorios para Vino',
  'Arroceras',
  'Balanzas de Baño',
  'Balanzas de Cocina',
  'Basureros',
  'Baterias (Juego de ollas)',
  'Batidoras de Mano',
  'Batidoras de Pedestal',
  'Bolsas de Compras',
  'Bolsos Loncheras',
  'Botellas Reutilizables',
  'Bowls',
  'Café en Grano',
  'Café en Polvo',
  'Cafeteras Americanas',
  'Cafeteras de Cápsulas',
  'Cafeteras de Prensa Francesa',
  'Cafeteras Expresso',
  'Cafeteras Italianas Moka',
  'Cafeteras Superautomaticas',
  'Calientaplatos',
  'Campanas Bajo Mueble',
  'Campanas de Pared',
  'Campanas Isla',
  'Cápsulas de Café',
  'Cavas',
  'Chocolate en Polvo',
  'Cocinas',
  'Cocteleras',
  'Coladores',
  'Combinaciones y Kits',
  'Contenedores',
  'Contenedores Loncheras',
  'Coolers',
  'Copas',
  'Cubiertos',
  'Cuchillos',
  'Decantadores',
  'Delantales',
  'Descorchadores',
  'Deshidratadores',
  'Encimeras a Gas',
  'Encimeras de Inducción',
  'Encimeras de Plato Eléctrico',
  'Encimeras Vitroceramicas',
  'Enfriadores de Vinos',
  'Espumadores de Leche',
  'Exprimidores de Jugo',
  'Filtros de Agua',
  'Filtros para Cafeteras',
  'Filtros para Campanas',
  'Fondues',
  'Freezers',
  'Freidoras',
  'Frigobares',
  'Frigobars',
  'Fuentes',
  'Fundas de Cuchillos',
  'Griferías',
  'Grills de Hierro',
  'Guantes',
  'Hervidor de Huevos',
  'Hervidores',
  'Hervidores de Huevos',
  'Hieleras',
  'Hornos',
  'Hornos de Pizza',
  'Individuales',
  'Jarros',
  'Lavadoras',
  'Lavadoras Secadoras',
  'Lavaplatos',
  'Lavavajillas',
  'Licuadoras',
  'Mandolinas',
  'Máquina Procesadora',
  'Máquinas de Cabritas',
  'Máquinas de Crepe',
  'Máquinas de Cupcakes',
  'Máquinas de Donuts',
  'Máquinas de Helados',
  'Máquinas de Hielo',
  'Máquinas de Omelette',
  'Máquinas de Pan',
  'Máquinas de Pastas',
  'Máquinas de Queques',
  'Máquinas de Reciclaje',
  'Microondas',
  'Minidomésticos',
  'Moledoras de Carne',
  'Molinillos de Café',
  'Molinillos de Especias',
  'Morteros',
  'Ollas',
  'Ollas a Presión',
  'Ollas Electricas a Presión',
  'Organización',
  'Organizadores',
  'Paños',
  'Parrillas a Carbón',
  'Parrillas a Gas',
  'Pecheras',
  'Planchas',
  'Planchas de Hierro',
  'Planchas Eléctricas',
  'Platos',
  'Posavasos',
  'Procesadores',
  'Purificadores',
  'Queseras',
  'Refrigeradores',
  'Refrigeradores Integrados',
  'Refrigeradores Side by Side',
  'Robots de Cocina',
  'Roners',
  'Sal y Especias',
  'Sartenes',
  'Secadoras',
  'Secaplatos',
  'Selladoras al Vacío',
  'Sets Aperitivo',
  'Slow Juicers',
  'Tablas'
]
