import PropTypes from 'prop-types'
import styled from 'styled-components'
import FieldInputController from '../../molecules/FieldInputController'
import Card from '../../molecules/CardWithTitle'
import FieldRadioController from '../../molecules/FieldRadioController'

const Row = styled('div')`
  width: 100%;
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 1100px) {
    width: 100%;
    grid-template-columns: 1fr;
  }
`

export function ShippingManagementCard({ control }) {
  return (
    <Card title="Gestión de envíos">
      <Row>
        <div>
          <FieldRadioController
            required
            name="shippingManagment"
            label="Bultos"
            placeholder=""
            control={control}
            options={[
              {
                label: 'Monobulto',
                value: 'monobulto'
              },
              {
                label: 'Multibulto',
                value: 'multibulto'
              }
            ]}
            tooltip={
              'El seller puede realizar envíos de un solo bulto (monobulto, con una sola etiqueta) o de múltiples bultos (multibulto, con una etiqueta para cada bulto).'
            }
          />
        </div>
        <div>
          <FieldInputController
            required
            name="enviameID"
            label="ID de seller en Envíame"
            placeholder="ID envíame"
            control={control}
            tooltip={
              'Código identificador del seller proporcionado por Envíame.'
            }
          />
        </div>
      </Row>
    </Card>
  )
}

ShippingManagementCard.propTypes = {
  control: PropTypes.any,
  clearError: PropTypes.any,
  setValue: PropTypes.any
}

export default ShippingManagementCard
