import React from 'react'
import styled from 'styled-components'
import IconButtonMUI from '@material-ui/core/IconButton'
import Brightness2OutlinedIcon from '@material-ui/icons/Brightness2Outlined'
import WbSunnyOutlinedIcon from '@material-ui/icons/WbSunnyOutlined'
import { useDarkMode } from '../../../styleSheet/DarkMode'
import ToolTip from '../../atomics/Tooltip'

const IconButton = styled(IconButtonMUI)`
  padding-left: 10px;
  color: ${({ theme }) => theme.palette.info.main};
  &:disabled {
    cursor: not-allowed;
    pointer-events: auto;
    color: #cccccc !important;
  }
`

const ButtonDark = ({ ...props }) => {
  const [modeTheme, setModeTheme] = useDarkMode()

  return (
    <ToolTip title="Alternar tema claro/oscuro">
      <IconButton
        {...props}
        color="inherit"
        aria-label="mode-dark-or-light"
        data-testid="ThemeModeButton__IconButton--button"
        onClick={() =>
          setModeTheme(modeTheme === 'dark' ? 'light' : 'dark')
        }
      >
        {modeTheme === 'dark' ? (
          <Brightness2OutlinedIcon />
        ) : (
          <WbSunnyOutlinedIcon />
        )}
      </IconButton>
    </ToolTip>
  )
}

export default ButtonDark
