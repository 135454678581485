import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'
import styled from 'styled-components'
import Field from '../../atomics/Field/Field'

const InputStyled = styled(Field)`
  width: 100%;
`

const InputController = ({ control, name, defaultValue, ...props }) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({
        field: { onChange, onBlur, ref, ...fields },
        fieldState: { error }
      }) => (
        <InputStyled
          {...{ name, ...props }}
          onChange={onChange}
          onBlur={onBlur}
          inputRef={ref}
          error={error}
          {...fields}
        />
      )}
    />
  )
}

InputController.propTypes = {
  errors: PropTypes.object,
  label: PropTypes.string,
  control: PropTypes.any,
  defaultValue: PropTypes.any,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  helperText: PropTypes.string
}

export default InputController
