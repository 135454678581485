import styled from 'styled-components'
import TooltipHelp from '../../molecules/TooltipHelp/TooltipHelp'

const Container = styled('label')`
  display: inline-flex;
  align-items: center;
  gap: 5px;
`
const Light = styled('label')`
  width: 13px;
  height: 13px;
  border-radius: 100%;
  background-color: ${({ active, firstLogin }) =>
    firstLogin ? '#FFC96B' : active ? '#92E6B5' : '#CCCCCC'};
`

const getText = (active, firstLogin) => {
  return firstLogin
    ? 'Usuario ha sido creado y aún no ha ingresado al sistema.'
    : active
      ? 'Usuario ha sido creado y ha ingresado al sistema.'
      : 'Usuario ha sido deshabilitado temporalmente.'
}

const UserStatus = ({ isActive, firstLogin }) => {
  return (
    <Container>
      {firstLogin ? 'Habilitado' : isActive ? 'Activo' : 'Deshabilitado'}{' '}
      <Light active={isActive} firstLogin={firstLogin} />
      <TooltipHelp text={getText(isActive, firstLogin)} />
    </Container>
  )
}

export default UserStatus
