import es from 'dayjs/locale/es'
import { memo } from 'react'
import InputBase from '@material-ui/core/InputBase'
import DayjsAdapter from '@date-io/dayjs'
import {
  DateRangePicker,
  LocalizationProvider,
  DateRangeDelimiter
} from '@material-ui/pickers'
import styled from 'styled-components'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import { AdormentRight } from '../../Adorment'
import Text from '../../Typography/Text'

const Delimiter = styled(DateRangeDelimiter)`
  margin: 0px;
  margin-right: 8px;
  margin-left: 8px;
`
const DateText = styled(Text)`
  font-weight: bold;
  padding-left: 14px;
  margin-right: 14px;
`
const InputContainer = styled('div')`
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top-left-radius: ${({ withborder }) =>
    withborder === 'true' ? '0px' : '5px'};
  border-bottom-left-radius: ${({ withborder }) =>
    withborder === 'true' ? '0px' : '5px'};
  border-top-right-radius: ${({ withborder }) =>
    withborder === 'true' ? '5px' : '0px'};
  border-bottom-right-radius: ${({ withborder }) =>
    withborder === 'true' ? '5px' : '0px'};
  max-width: auto;
  border: 1px solid #b7b7b7;
  box-sizing: border-box;
`

const InputWrapper = styled(props => <InputBase {...props} />)`
  height: 36px;
  width: 8rem;
  font-weight: 500;
  color: ${({ theme }) =>
    theme.palette.type === 'dark' ? '#FFFFFF' : '#202223'};
  @media (max-width: 700px) {
    width: 100%;
  }
`

const InputWrapperEnd = styled(InputWrapper)`
  width: 8rem;
  @media (max-width: 700px) {
    width: 100%;
  }
`

const InputWrapperStart = styled(InputWrapper)`
  width: ${({ withborder }) => (withborder === 'true' ? '12rem' : '9rem')};
  min-width: ${({ withborder }) =>
    withborder === 'true' ? '12rem' : '9rem'};
`

const isEqualValue = (prevProps, nextProps) =>
  prevProps?.value === nextProps?.value

const InputRangeDate = memo(
  ({ prefixName, value, withborder, ...props }) => (
    <LocalizationProvider dateAdapter={DayjsAdapter} locale={es}>
      <DateRangePicker
        {...props}
        value={value}
        disableFuture
        variant="inline"
        invalidDateMessage="Formato inválido"
        inputFormat="DD/MM/YYYY"
        disableAutoMonthSwitching
        animateYearScrolling
        renderInput={(startProps, endProps) => {
          return (
            <InputContainer withborder={String(withborder)}>
              <InputWrapperStart
                id="start-inputDate"
                ref={startProps.inputRef}
                startAdornment={<DateText>{prefixName}</DateText>}
                withborder={String(withborder)}
                {...startProps}
              />
              <Delimiter>/</Delimiter>
              <InputWrapperEnd
                id="end-inputDate"
                ref={endProps.inputRef}
                endAdornment={
                  <AdormentRight position="end">
                    {endProps.focused ? (
                      <ArrowDropUpIcon />
                    ) : (
                      <ArrowDropDownIcon />
                    )}
                  </AdormentRight>
                }
                {...endProps}
              />
            </InputContainer>
          )
        }}
      />
    </LocalizationProvider>
  ),
  isEqualValue
)

InputRangeDate.displayName = 'InputRangeDate'
export default InputRangeDate
