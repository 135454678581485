import { fetchCommissionsDecoded } from '../client/fetchDecoded'
import persistentStore from '../persistentStore'

const brandServices = baseUrl => ({
  getSlugSellerBrands: async ({ slug_seller, token }) => {
    const data = await fetchCommissionsDecoded(
      `${baseUrl}/brands/${slug_seller}`,
      {
        headers: {
          authorization: token || persistentStore.getToken(),
          app: process.env.REACT_APP_APPLICATION_NAME
        }
      }
    )
    return data
  },
  getAllBrands: async () => {
    const data = await fetchCommissionsDecoded(`${baseUrl}/brands`, {
      headers: {
        authorization: persistentStore.getToken(),
        app: process.env.REACT_APP_APPLICATION_NAME
      }
    })
    return data
  }
})

export default brandServices(process.env.REACT_APP_API_COMMISIONS_PATH)
