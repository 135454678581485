import React from 'react'
import isEmpty from 'lodash/isEmpty'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { liquidateSelector } from '../../../../domain/features/liquidate/liquidateSlice'
import { getAveragePayouts } from '../../../../domain/features/payouts/getPayout'
import getReportExcel from '../../../../domain/reports/getReportExcel'
import SubmitButton from '../../atomics/Button/SubmitButton/SubmitButton'
import Navigate from '../../atomics/Navigate'
import Text from '../../atomics/Typography/Text'
import ModalLiquidate from '../../organims/ModalLiquidate'

const Divider = styled('div')`
  margin-bottom: 1rem;
`

const Space = styled('div')`
  height: 4rem;
`

const NavigateModal = styled(Navigate)`
  text-decoration: none;
  text-decoration-line: none;
  text-transform: capitalize;
`

const SpaceMini = styled('div')`
  height: 1rem;
`

const Container = styled('div')`
  margin-left: 0rem;
`

const typeTitle = new Map([
  ['finished', 'Liquidación Exitosa'],
  ['error', 'Estado Liquidación'],
  ['idle', 'Confirmación Liquidación'],
  ['loading', 'Enviando Liquidación']
])

const typeSutitle = x =>
  new Map([
    [
      'finished',
      'Se han contabilizado exitosamente los Payouts seleccionados. '
    ],
    [
      'error',
      x
        ? 'Ha ocurrido un error, por favor intente más tarde'
        : 'Se ha intentado realizar la contabilización de los Payouts seleccionados obteniendo los siguientes resultados:'
    ],
    [
      'idle',
      'Se han seleccionado payouts para liquidar con los siguientes totales:'
    ],
    [
      'loading',
      'Se han seleccionado payouts para liquidar con los siguientes totales:'
    ]
  ])

const typeFooter = x =>
  new Map([
    [
      'finished',
      'En “Liquidaciones” podrá observar la evolución de las liquidaciones, que próximamente serán facturadas y pagadas.'
    ],
    [
      'error',
      x
        ? ''
        : 'Presione “Descargar” para observar el informe de errores y en caso de no poder solucionarlos, contáctese con el equipo de soporte.'
    ],
    [
      'idle',
      'Presione “Continuar” para confirmar el comienzo del proceso de liquidación.'
    ],
    ['loading', 'Enviando a SAP el proceso de liquidación.']
  ])

const translateAverage = new Map([
  ['totalSale', 'Venta'],
  ['dispatchedSale', 'Venta despachada'],
  ['commission', 'Comisión'],
  ['otherDiscount', 'Otros descuentos'],
  ['payout', 'Payout']
])

const InfoLiquidate = ({ listOfAverages, totalSeller }) => (
  <Container>
    <Text color="primary">Cantidad de sellers: {totalSeller}</Text>
    <Divider />
    {Object.entries(listOfAverages).map(([key, value]) =>
      key === 'totalSale' ? null : (
        <Text color="primary">
          {translateAverage.get(key)}: {value?.toLocaleString('es-CL')}
        </Text>
      )
    )}
  </Container>
)
InfoLiquidate.displayName = 'InfoLiquidate'

const ErrorLiquidate = ({ totalSuccess, totalError }) => (
  <Container>
    {totalSuccess > 0 ? (
      <Text color="primary">
        Contabilizaciones correctas: {totalSuccess}
      </Text>
    ) : null}
    <Divider />
    {totalError ? (
      <Text color="primary">Contabilizaciones fallidas: {totalError}</Text>
    ) : null}
  </Container>
)
ErrorLiquidate.displayName = 'ErrorLiquidate'

const handlerClickDownloadButton = async (e, fileName, format, rows) => {
  return getReportExcel({
    e,
    sheet: 'Estado liquidación',
    fileName,
    format,
    rows,
    columns: [
      { header: 'Id', key: 'Id' },
      { header: 'IdAc', key: 'IdAc' },
      { header: 'Estado', key: 'Estado' },
      { header: 'Detalle', key: 'Detalle' }
    ]
  })
}

const ModalLiquidatePayout = ({
  open,
  onClose,
  onSubmit,
  payoutsViews
}) => {
  const { status, errorList, successList } = useSelector(liquidateSelector)

  const listOfAverages = useSelector(getAveragePayouts)

  const typeBody = React.useMemo(
    () =>
      new Map([
        ['finished', <SpaceMini />],
        [
          'error',
          <ErrorLiquidate
            totalError={errorList?.length}
            totalSuccess={successList?.length}
            key="error"
          />
        ],
        [
          'idle',
          <InfoLiquidate
            key="idle"
            listOfAverages={listOfAverages}
            totalSeller={payoutsViews?.length}
          />
        ],
        [
          'loading',
          <InfoLiquidate
            key="loading"
            listOfAverages={listOfAverages}
            totalSeller={payoutsViews?.length}
          />
        ]
      ]),
    [
      errorList?.length,
      listOfAverages,
      payoutsViews?.length,
      successList?.length
    ]
  )

  return (
    <ModalLiquidate
      open={open}
      onClose={() => {
        onClose()
        return 'backdropClick'
      }}
      disableEnforceFocus
      title={typeTitle.get(status)}
      headerText={typeSutitle(isEmpty(errorList)).get(status)}
      body={() => typeBody.get(status)}
      status={status}
      footerText={typeFooter(isEmpty(errorList)).get(status)}
      footer={() =>
        isEmpty(errorList) && status === 'error' ? (
          <Space />
        ) : status === 'error' ? (
          <SubmitButton
            variant="contained"
            color="primary"
            data-testid="Footer__Button--download"
            onClick={e =>
              handlerClickDownloadButton(
                e,
                'Estado liquidación',
                'xlsx',
                errorList
              )
            }
          >
            Descargar
          </SubmitButton>
        ) : status === 'finished' ? (
          <NavigateModal to="/liquidaciones">
            <SubmitButton
              variant="contained"
              color="primary"
              data-testid="Footer__Button--liquidar"
              disabled={status === 'loading'}
              isLoading={status === 'loading'}
              title=" Ir a Liquidaciones"
            >
              Ir a liquidaciones
            </SubmitButton>
          </NavigateModal>
        ) : (
          <SubmitButton
            variant="contained"
            color="primary"
            data-testid="Footer__Button--liquidar"
            disabled={status === 'loading'}
            isLoading={status === 'loading'}
            onClick={onSubmit}
          >
            Continuar
          </SubmitButton>
        )
      }
    />
  )
}

export default ModalLiquidatePayout
