import { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm, useFormState, useWatch } from 'react-hook-form'
import { DevTool } from '@hookform/devtools'
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'

import { getMeta } from '../../../domain/adpaters/graphql/getKey'
import { getKeyByValue } from '../../../domain/adpaters/map/getKeyByValue'
import { decomposeMeasurement } from '../../../domain/adpaters/measurementProduct/decomposeMeasurement'
import fetchUpdateProductStatusFromDetail from '../../../domain/features/productStatusUpdate/fetchUpdateProductStatusFromDetail'
import fetchProductUpdate from '../../../domain/features/productUpdate/fetchProductUpdate'
import { getProductDetailStatus } from '../../../domain/features/productStatusUpdate/getProductDetailStatus'
import { resetProductCreate } from '../../../domain/features/productCreate/productCreateSlice'
import fetchProductDetail from '../../../domain/features/productDetail/fetchProductDetail'
import { getProductDetail } from '../../../domain/features/productDetail/getProductDetail'
import { getBrands } from '../../../domain/features/brandsSlugSeller/getBrands'
import { getProductUpdate } from '../../../domain/features/productUpdate/getProductUpdate'
import { schema } from '../../../domain/schemas/addProductSchema'

import ProductsTemplate from '../templates/ProductsTemplate'
import StickyBar from '../organims/StickyBar/StickyBar'
import Alert, { typeAlert } from '../atomics/Alert/Alert'
import { useAuth } from '../pages/auth/useAuth'

const stateUpdate = x =>
  new Map([
    ['finished', `Cambios guardados para ${x}`],
    ['loading', `Guardando cambios para ${x} `],
    ['error', `Error al cargar ${x}`]
  ])
const stateDetail = x =>
  new Map([
    ['finished', `Carga exitosa para ${x}`],
    ['loading', `Cargando ${x} `],
    ['error', `Error al cargar ${x}`]
  ])
const stateProduct = x =>
  new Map([
    ['finished', `Cambios guardados para ${x}`],
    ['loading', `Cambiando estado para ${x} `],
    ['error', `Error al cambiar estado para ${x}`]
  ])

const ProductDetails = () => {
  const navigate = useNavigate()
  const { state } = useLocation()

  const [passableEdited, setPassableEdited] = useState(false)
  const [statusUpdate, setStatusUpdate] = useState(undefined)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(resetProductCreate())
  }, [dispatch])

  const params = useParams()
  useEffect(() => {
    const promise = dispatch(
      fetchProductDetail({ id: `gid://shopify/Product/${params.id}` })
    )
    return () => promise.abort()
  }, [dispatch, params.id])

  const { currentUser: user } = useAuth()
  const role = useMemo(
    () => String(user?.role)?.toLowerCase(),
    [user?.role]
  )

  const {
    product,
    media,
    status: statusProductDetail
  } = useSelector(getProductDetail)
  const productBase = useMemo(
    () => (product ? product : state),
    [product, state]
  )

  const metafields = useCallback(
    key => getMeta(productBase?.metafields)(key)?.value || '',
    [productBase?.metafields]
  )

  const [mediaList, setMediaList] = useState([])
  useEffect(() => {
    if (media) {
      setMediaList(media.filter(med => med.mediaContentType === 'IMAGE'))
    }
  }, [media, setMediaList])

  const [allFiles, setAllFiles] = useState(product?.files || {})
  useEffect(() => {
    if (product?.files) {
      setAllFiles(product?.files)
    }
  }, [product?.files, setAllFiles])

  const { allBrands } = useSelector(getBrands)

  const sellerName = useMemo(() => {
    const seller = allBrands?.find(
      brand =>
        String(product?.vendor) === String(brand?.brandName) ||
        String(state?.vendor) === String(brand?.brandName)
    )
    return seller ? seller?.seller?.nameSeller : null
  }, [product.vendor, state, allBrands])

  const defaultValues = useMemo(
    () => ({
      seller: sellerName,
      id: productBase?.id,
      productName: productBase?.title,
      category: productBase?.productType,
      brand: productBase?.vendor,
      detailedDescription: productBase?.descriptionHtml?.replace(
        /<br>/g,
        '\n'
      ),
      tags: productBase?.tags?.join(','),
      productWidth: decomposeMeasurement(
        metafields('measurements_product'),
        1
      ),
      productHeight: decomposeMeasurement(
        metafields('measurements_product'),
        0
      ),
      productDepth: decomposeMeasurement(
        metafields('measurements_product'),
        2
      ),
      productMaterial: metafields('materials'),
      productWarranty: metafields('warranty'),
      specialFeatures: metafields('features'),
      manufacturedCountry: metafields('fabricado_en'),
      productTension: metafields('tension'),
      productInstalation: metafields('installation'),
      productAccesories: metafields('accesories'),
      productConsiderations: metafields('considerations'),
      plug: metafields('plug'),
      commands: metafields('knob'),
      power: metafields('power'),
      speedsNumber: metafields('speed'),
      energyClass: metafields('rating_energy'),
      totalUsableCapacity: metafields('cp_useful_total'),
      embeddedMeasurements: metafields('measurements_enchase'),
      panel: metafields('panel'),
      energyType: metafields('type_power'),
      light: metafields('light'),
      built: metafields('armed'),
      consume: metafields('consumption_kwh'),
      temperature: metafields('temperature'),
      burnersHeatLevels: metafields('level_heat_burner'),
      countertopType: metafields('type_surface'),
      burnersNumber: metafields('total_burners'),
      ovenType: metafields('type_oven'),
      ovenProgramsNumber: metafields('total_programs'),
      maxSuction: metafields('max_suction'),
      bellType: metafields('type_campaign'),
      maxNoise: metafields('max_noise'),
      grillType: metafields('type_grill'),
      freezerType: metafields('type_freezer'),
      refrigeratorType: metafields('type_refrigerator'),
      refrigeratorUsableCapacity: metafields('cp_useful_freezer'),
      freezerUsableCapacity: metafields('cp_useful_refrigerator'),
      refrigerantGas: metafields('gas_refrigerant'),
      washingProgramsNumber: metafields('total_programs_washing'),
      dryProgramsNumber: metafields('total_programs_drying'),
      loadType: metafields('type_load'),
      manual: metafields('manual_de_uso'),
      dataSheet: metafields('ficha_tecnica'),
      certificate: metafields('certificado_QR'),
      efficiency: metafields('eficiencia_energetica'),
      inpage: metafields('inpage'),
      descriptionImage: metafields('description_image'),
      intensity: metafields('intensity'),
      security: metafields('security'),
      cableLength: metafields('cable_length'),
      tubeDiameter: metafields('diameter_tube'),
      coldTechnology: metafields('technology_cooling'),
      tapType: metafields('type_tap'),
      washingMachineUsableCapacity: metafields(
        'cp_useful_washing_machine'
      ),
      dryerUsableCapacity: metafields('cp_useful_washer_dryer'),
      functionType: metafields('type_function'),
      bottlesQuantity: metafields('bottles_quantity'),
      video: productBase?.video,
      actualVideo: productBase?.video,
      media: media,
      variants: productBase?.variants,
      mediaIds: productBase?.mediaIds
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [metafields, productBase, sellerName, media]
  )
  const {
    handleSubmit,
    formState: { errors, isSubmitting, isDirty },
    control,
    reset,
    setValue,
    register,
    clearErrors,
    setError
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues,
    criteriaMode: 'firstError',
    shouldFocusError: true
  })

  const {
    dirtyFields: {
      category,
      brand,
      detailedDescription,
      productWidth,
      productHeight,
      productDepth,
      productMaterial,
      productWarranty,
      specialFeatures,
      manufacturedCountry,
      productTension,
      productInstalation,
      productAccesories,
      productConsiderations,
      plug,
      commands,
      power,
      speedsNumber,
      energyClass,
      totalUsableCapacity,
      embeddedMeasurements,
      panel,
      energyType,
      light,
      built,
      consume,
      temperature,
      burnersHeatLevels,
      countertopType,
      burnersNumber,
      ovenType,
      ovenProgramsNumber,
      maxSuction,
      bellType,
      maxNoise,
      grillType,
      freezerType,
      refrigeratorType,
      refrigeratorUsableCapacity,
      freezerUsableCapacity,
      refrigerantGas,
      dryProgramsNumber,
      loadType,
      manual,
      dataSheet,
      certificate,
      efficiency,
      inpage,
      intensity,
      security,
      cableLength,
      tubeDiameter,
      coldTechnology,
      tapType,
      washingMachineUsableCapacity,
      dryerUsableCapacity,
      functionType,
      variants,
      video,
      productName: title
    }
  } = useFormState({
    control
  })

  useEffect(() => {
    reset(defaultValues)
  }, [defaultValues, reset])

  const sellerWatch = useWatch({ name: 'seller', control })
  const brandList = useMemo(() => {
    return sellerWatch
      ? allBrands?.filter(
          ({ seller }) =>
            String(sellerWatch) === String(seller?.nameSeller)
        )
      : allBrands
  }, [sellerWatch, allBrands])

  const handleUpdateProduct = async data => {
    // setFormData(data)
    data.seller = sellerWatch
    if (data.video) {
      if (data.video === data.actualVideo) {
        const { video, actualVideo, ...rest } = data
        dispatch(
          fetchProductUpdate({
            data: rest,
            allFiles,
            status: product?.status || state?.status,
            passableEdited
          })
        )
      } else {
        if (data.actualVideo) {
          const videoToDelete = productBase?.media?.filter(
            med => med?.embedUrl === data.actualVideo
          )
          dispatch(
            fetchProductUpdate({
              data: {
                ...data,
                mediaToDelete: data.mediaToDelete
                  ? [...data.mediaToDelete, ...videoToDelete]
                  : [...videoToDelete]
              },
              allFiles,
              status: product?.status || state?.status,
              passableEdited
            })
          )
        } else {
          dispatch(
            fetchProductUpdate({
              data,
              allFiles,
              status: product?.status || state?.status,
              passableEdited
            })
          )
        }
      }
    } else {
      const { video, actualVideo, ...rest } = data
      if (data.actualVideo) {
        const videoToDelete = productBase?.media?.filter(
          med => med?.embedUrl === actualVideo
        )
        dispatch(
          fetchProductUpdate({
            data: {
              ...rest,
              mediaToDelete: data.mediaToDelete
                ? [...data.mediaToDelete, ...videoToDelete]
                : [...videoToDelete]
            },
            allFiles,
            status: product?.status || state?.status,
            passableEdited
          })
        )
      } else {
        dispatch(
          fetchProductUpdate({
            data: rest,
            allFiles,
            status: product?.status || state?.status,
            passableEdited
          })
        )
      }
    }
  }

  const statuses = new Map([
    ['ACTIVE', 'Aprobado'],
    ['DRAFT', 'Pendiente aprobación'],
    ['ARCHIVED', 'Deshabilitar']
  ])

  useEffect(() => {
    setStatusUpdate(statuses.get(product?.status || state?.status))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, state, setStatusUpdate])

  const newStatus = new Map([
    ['Solicitar aprobación', 'DRAFT'],
    ['Deshabilitar', 'ARCHIVED'],
    ['Aprobar', 'ACTIVE']
  ])

  const statusMap = statuses.get(product?.status || state?.status)

  const optionMap =
    role === 'operations'
      ? new Map([
          ['ACTIVE', 'Solicitar aprobación'],
          ['ARCHIVED', 'Solicitar aprobación']
        ])
      : new Map([
          ['ACTIVE', 'Deshabilitar'],
          ['DRAFT', 'Aprobar'],
          ['ARCHIVED', 'Solicitar aprobación']
        ])
  const option = optionMap.get(getKeyByValue(statuses, statusUpdate))

  const statusOnChange = data => {
    const status = newStatus.get(data.target.value)
    setStatusUpdate(statuses.get(status))
    dispatch(
      fetchUpdateProductStatusFromDetail({
        id: state?.id,
        status
      })
    )
  }

  const { status, mediaStatus } = useSelector(getProductUpdate)
  const productName = useWatch({ name: 'productName', control })

  useEffect(() => {
    if (
      title ||
      category ||
      brand ||
      detailedDescription ||
      productWidth ||
      productHeight ||
      productDepth ||
      productMaterial ||
      productWarranty ||
      specialFeatures ||
      manufacturedCountry ||
      productTension ||
      productInstalation ||
      productAccesories ||
      productConsiderations ||
      plug ||
      commands ||
      power ||
      speedsNumber ||
      energyClass ||
      totalUsableCapacity ||
      embeddedMeasurements ||
      panel ||
      energyType ||
      light ||
      built ||
      consume ||
      temperature ||
      burnersHeatLevels ||
      countertopType ||
      burnersNumber ||
      ovenType ||
      ovenProgramsNumber ||
      maxSuction ||
      bellType ||
      maxNoise ||
      grillType ||
      freezerType ||
      refrigeratorType ||
      refrigeratorUsableCapacity ||
      freezerUsableCapacity ||
      refrigerantGas ||
      dryProgramsNumber ||
      loadType ||
      manual ||
      dataSheet ||
      certificate ||
      efficiency ||
      inpage ||
      intensity ||
      security ||
      cableLength ||
      tubeDiameter ||
      coldTechnology ||
      tapType ||
      washingMachineUsableCapacity ||
      dryerUsableCapacity ||
      functionType ||
      (variants?.length > 0 &&
        variants?.filter(v => v?.ownSku)?.length > 0) ||
      (variants?.length > 0 &&
        variants?.filter(v => v?.color)?.length > 0) ||
      (variants?.length > 0 &&
        variants?.filter(v => v?.weight)?.length > 0) ||
      video
    ) {
      setPassableEdited(true)
    } else {
      setPassableEdited(false)
    }
  }, [
    title,
    category,
    brand,
    detailedDescription,
    productWidth,
    productHeight,
    productDepth,
    productMaterial,
    productWarranty,
    specialFeatures,
    manufacturedCountry,
    productTension,
    productInstalation,
    productAccesories,
    productConsiderations,
    plug,
    commands,
    power,
    speedsNumber,
    energyClass,
    totalUsableCapacity,
    embeddedMeasurements,
    panel,
    energyType,
    light,
    built,
    consume,
    temperature,
    burnersHeatLevels,
    countertopType,
    burnersNumber,
    ovenType,
    ovenProgramsNumber,
    maxSuction,
    bellType,
    maxNoise,
    grillType,
    freezerType,
    refrigeratorType,
    refrigeratorUsableCapacity,
    freezerUsableCapacity,
    refrigerantGas,
    dryProgramsNumber,
    loadType,
    manual,
    dataSheet,
    certificate,
    efficiency,
    inpage,
    intensity,
    security,
    cableLength,
    tubeDiameter,
    coldTechnology,
    tapType,
    washingMachineUsableCapacity,
    dryerUsableCapacity,
    functionType,
    video,
    variants,
    setPassableEdited
  ])

  const variantListWatch = useWatch({
    control,
    name: 'variants'
  })

  const isDirtyCompareAtPrice = useMemo(
    () => variants && variants[0]?.compareAtPrice,
    [variants]
  )

  const compareAtPrice = useMemo(
    () => variantListWatch && variantListWatch[0]?.compareAtPrice,
    [variantListWatch]
  )

  useEffect(() => {
    if (compareAtPrice && isDirtyCompareAtPrice) {
      return variantListWatch.map((field, index) =>
        setValue(`variants[${index}]`, { ...field, compareAtPrice })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compareAtPrice, isDirtyCompareAtPrice, setValue])

  const [openAlert, setOpenAlert] = useState(false)
  const handleCloseAlert = (_, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenAlert(false)
  }
  useEffect(
    () => (status !== 'idle' ? setOpenAlert(true) : null),
    [status]
  )
  const [openDetail, setOpenDetail] = useState(false)
  const handleCloseDetail = (_, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenDetail(false)
  }
  useEffect(
    () => (statusProductDetail !== 'idle' ? setOpenDetail(true) : null),
    [statusProductDetail]
  )

  const { status: statusProduct } = useSelector(getProductDetailStatus)
  const [openProduct, setOpenProduct] = useState(false)
  const handleCloseProduct = (_, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenProduct(false)
  }
  useEffect(
    () => (statusProduct !== 'idle' ? setOpenProduct(true) : null),
    [statusProduct]
  )

  return (
    <>
      {process.env.NODE_ENV === 'development' ? (
        <DevTool control={control} placement="top-left" />
      ) : null}

      <StickyBar
        isOpen={isDirty}
        onCancel={() => navigate('/productos')}
        onSubmit={handleSubmit(handleUpdateProduct)}
        isSubmitting={isSubmitting || status === 'loading'}
        isError={!isEmpty(errors)}
        disabled={mediaStatus === 'loading' || status === 'loading'}
      />

      <ProductsTemplate
        isDetailsView
        sellerName={sellerName}
        onBack={() => navigate('/productos')}
        onSubmit={handleSubmit(handleUpdateProduct)}
        setAllFiles={setAllFiles}
        allFiles={allFiles}
        control={control}
        errors={errors}
        isSubmitting={isSubmitting}
        brandList={brandList}
        statusOnChange={statusOnChange}
        productName={state?.title || productName}
        productStatus={statusUpdate || statusMap}
        option={option}
        mediaList={mediaList}
        setMediaList={setMediaList}
        variants={productBase?.variants}
        productId={params.id}
        role={role}
        register={register}
        setValue={setValue}
        clearErrors={clearErrors}
        setError={setError}
        viewOnlineStorePreviewUrl={productBase?.onlineStorePreviewUrl}
      />
      <Alert
        key="product-update"
        open={openAlert}
        onClose={handleCloseAlert}
        title={stateUpdate(productName).get(status)}
        isLoading={status === 'loading'}
        severity={typeAlert.get(status)}
        isError={status === 'error'}
      />
      <Alert
        key="product-detail"
        open={openDetail}
        onClose={handleCloseDetail}
        title={stateDetail(productName ? productName : params.id).get(
          statusProductDetail
        )}
        isLoading={statusProductDetail === 'loading'}
        severity={typeAlert.get(statusProductDetail)}
        isError={statusProductDetail === 'error'}
      />
      <Alert
        key="product-status"
        open={openProduct}
        onClose={handleCloseProduct}
        title={stateProduct(productName).get(statusProduct)}
        isLoading={statusProduct === 'loading'}
        severity={typeAlert.get(statusProduct)}
        isError={statusProduct === 'error'}
      />
    </>
  )
}

export default ProductDetails
