import { useLocation } from 'react-router-dom'
import React from 'react'
import styled from 'styled-components'
import ErrorOutline from '@material-ui/icons/ErrorOutline'
import Button from '@material-ui/core/Button'
import HomeOutlined from '@material-ui/icons/HomeOutlined'
import Title from '../../atomics/Typography/Title'
import imageweb from '../../../assets/logoWeb.webp'
import { SubTitleNavbar } from '../../atomics/Typography/TitleNavBar'
import Text from '../../atomics/Typography/Text'
import Navigate from '../../atomics/Navigate'

const Container = styled('div')`
  height: 100vh;
  width: 100%;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  align-self: center;
  flex-direction: row;
  overflow: hidden;
`

const Column = styled(Container)`
  flex-direction: column;
`
const ColumnLeft = styled(Column)`
  overflow-x: hidden;
  background-color: ${({ theme }) => theme.palette.third.dark};
  @media (max-width: 700px) {
    flex-direction: column;
  }
`

const Img = styled('img')`
  height: 180px;
  width: 180px;
  border: 1px solid #c1c1c1;
  margin-bottom: 15px;
  @media (max-width: 700px) {
    margin-bottom: 10px;
    height: 120px;
    width: 120px;
  }
  @media (max-width: 500px) {
    margin-bottom: 10px;
    height: 80px;
    width: 80px;
  }
`

const TitleLogo = styled(Title)`
  font-size: 24px;
  font-style: bold;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.04em;
  text-align: center;

  @media (max-width: 800px) {
    align-self: center;
    padding-left: 0;
    margin-bottom: 1%;
  }
`
const TitleKC = styled(Title)`
  font-size: 24px;
  font-style: light;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.04em;
  text-align: center;
  color: ${({ theme }) => theme.palette.third.light};
  margin-bottom: 2%;
  @media (max-width: 800px) {
    align-self: center;
    padding-left: 0;
    margin-bottom: 1%;
  }
`

const IconError = styled(ErrorOutline)`
  font-size: 60px;
  color: ${({ theme }) => theme.palette.error.dark};
  display: flex;
  flex-direction: row;
`
const Divider = styled('div')`
  margin-top: 1rem;
`
Divider.displayName = 'Divider'

const Error404 = () => {
  const { pathname, state } = useLocation()

  return (
    <Container>
      <ColumnLeft>
        <TitleKC variant="h3">Back Office</TitleKC>{' '}
        <Img alt="logo" src={imageweb} />
        <SubTitleNavbar variant="h4">
          Administración interna Kitchen Center
        </SubTitleNavbar>
      </ColumnLeft>
      <Column>
        <IconError />

        <TitleLogo variant="h1" aria-label="No tiene permisos">
          Error 404
        </TitleLogo>

        <Divider />
        <Text>
          La ruta actual <b>{pathname}</b> no se existe en la aplicación
        </Text>

        <Divider />
        <Navigate
          data-testid="Link_error404--back"
          to="/pedidos"
          state={state}
        >
          <Button variant="contained" endIcon={<HomeOutlined />}>
            Volver a los pedidos
          </Button>
        </Navigate>
      </Column>
    </Container>
  )
}

export default Error404
