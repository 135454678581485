import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'
import { bool, string, object, func } from 'prop-types'

const Input = styled(TextField)`
  .MuiOutlinedInput-root {
    height: 36px;
    font-size: 13px;
    margin-top: 1%;
    border-color: #7c7c7c;
    & fieldset {
      border-color: #d1d0d0;
    }
  }
  .MuiOutlinedInput-input:-webkit-autofill {
    height: 0px;
  }
  .MuiFormLabel-root {
    line-height: 21.09px;
    font-size: 19px;
    color: #212121;
    font-weight: 400, normal;
    left: -15px;
  }
  .Mui-required {
    color: #212121;
  }
  .Mui-disabled {
    cursor: not-allowed;
  }
  .MuiOutlinedInput-root.Mui-error {
    color: ${({ error }) => (!error ? '#b7b7b7' : '#e72020')};
  }
  .MuiFormHelperText-root {
    padding-top: 6px;
    font-weight: 400, normal;
    font-size: 11px;
    line-height: 12.89px;
    margin-left: 0px;
  }
  .customtextfield {
    & input::placeholder {
      font-size: 13px;
    }
  }
`

const Field = ({ error, name, helperText, ...props }) => {
  return (
    <Input
      fullWidth
      variant="outlined"
      InputLabelProps={{ shrink: false }}
      id={name}
      name={name}
      error={!!error}
      helperText={error ? error?.message : helperText}
      {...props}
    />
  )
}

Field.propTypes = {
  label: string,
  required: bool,
  placeholder: string,
  helperText: string,
  error: object,
  name: string,
  onChange: func,
  onBlur: func
}
Field.defaultProps = {
  label: '',
  required: false,
  placeholder: ''
}

export default Field
