import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import fetchOrderBilling from './fetchOrderBilling'
import normalizeOrderBilling from './normalizeOrderBilling'
import normalizeOrderDelivery from './normalizeOrderDelivery'

export const orderBillingAdapter = createEntityAdapter()

export const orderBillingSlice = createSlice({
  name: 'order_billing',
  initialState: orderBillingAdapter.getInitialState({
    status: 'idle',
    billing: {},
    delivery: {},
    documentType: ''
  }),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchOrderBilling.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(fetchOrderBilling.rejected, state => {
      state.status = 'error'
    })
    builder.addCase(fetchOrderBilling.fulfilled, (state, { payload }) => {
      state.status = 'finished'
      state.billing = payload ? normalizeOrderBilling(payload) : null
      state.delivery = payload ? normalizeOrderDelivery(payload) : null
      state.documentType = payload ? payload.document_type : null
    })
  }
})

const reducer = orderBillingSlice.reducer
export default reducer
