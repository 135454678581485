import React from 'react'
import { bool, node } from 'prop-types'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'

const CancelContainer = styled('div')`
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-right: 2%;
  .MuiButtonBase-root:disabled {
    cursor: ${({ isLoading }) => (isLoading ? 'wait' : 'not-allowed')};
    pointer-events: auto;
  }
`

const Spinner = styled(CircularProgress)`
  color: ${({ theme }) =>
    theme.palette.type === 'dark' ? '#FFFFFF' : '#202223'};
`

const CancelButton = ({ isLoading, children, ...props }) => {
  return (
    <CancelContainer isLoading={isLoading}>
      <Button
        variant="contained"
        data-testid="CancelButton__button--cancel"
        endIcon={isLoading ? <Spinner size="1.2rem" /> : null}
        size="large"
        {...props}
      >
        <Typography>{children || 'Cancelar'}</Typography>
      </Button>
    </CancelContainer>
  )
}

CancelButton.propTypes = {
  isLoading: bool,
  children: node
}
CancelButton.defaultProps = {
  isLoading: false,
  children: undefined
}

export default CancelButton
