import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import fetchUpdateProductStatusFromDetail from './fetchUpdateProductStatusFromDetail'

export const productUpdateStatusAdpater = createEntityAdapter({
  selectId: ({ id }) => String(id)
})

export const productUpdateStatusSlice = createSlice({
  name: 'product/update/status',
  initialState: productUpdateStatusAdpater.getInitialState({
    status: 'idle',
    entities: {}
  }),
  reducers: {},
  extraReducers: {
    [fetchUpdateProductStatusFromDetail.pending]: state => {
      state.status = 'loading'
    },
    [fetchUpdateProductStatusFromDetail.rejected]: state => {
      state.status = 'error'
    },
    [fetchUpdateProductStatusFromDetail.fulfilled]: (
      state,
      { payload }
    ) => {
      state.status = 'finished'
      productUpdateStatusAdpater.setOne(state, {
        id: payload.id,
        status: payload.status
      })
    }
  }
})

const reducer = productUpdateStatusSlice.reducer
export default reducer
