import React from 'react'
import PropTypes from 'prop-types'
import Card from '../../atomics/CardProduct'
import Title from '../../atomics/Typography/TitleProduct'
import styled from 'styled-components'
import isFunc from 'lodash/isFunction'

const StyledCard = styled(Card)`
  width: 100%;
  padding: 0px;
  @media (max-width: 1100px) {
    height: auto;
  }
`

const StyledTitle = styled(Title)`
  font-style: normal;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0.2px;
  margin: 0;
  width: 100%;
  font-weight: 700;
`

const ContainerTitle = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 15px 40px;
  border-bottom: 1px solid #b7b7b7;
`
const ContainerCard = styled('div')`
  padding: 30px 40px;
  width: 100%;
`

const CardWithTitleAndButton = ({
  title,
  children,
  render = () => undefined
}) => {
  return (
    <StyledCard>
      <ContainerTitle>
        {title && <StyledTitle>{title}</StyledTitle>}
        {isFunc(render) ? render() : null}
      </ContainerTitle>
      {children && <ContainerCard>{children}</ContainerCard>}
    </StyledCard>
  )
}
CardWithTitleAndButton.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  render: PropTypes.func
}
CardWithTitleAndButton.defaultProps = {
  render: () => undefined,
  children: null
}
export default CardWithTitleAndButton
