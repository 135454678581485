import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import fetchOrderUpdateStatus from './fetchOrderUpdateStatus'

export const orderUpdateStatusAdapter = createEntityAdapter()

export const orderUpdateStatusSlice = createSlice({
  name: 'update/suborder/status',
  initialState: orderUpdateStatusAdapter.getInitialState({
    status: 'idle',
    suborder: {}
  }),
  reducers: {
    resetEditValidateStatus(state) {
      state.status = 'idle'
      state.suborder = {}
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchOrderUpdateStatus.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(fetchOrderUpdateStatus.rejected, state => {
      state.status = 'error'
    })
    builder.addCase(
      fetchOrderUpdateStatus.fulfilled,
      (state, { payload }) => {
        state.status = 'finished'
        state.suborder = payload
      }
    )
  }
})
export const { resetEditValidateStatus } = orderUpdateStatusSlice.actions
const reducer = orderUpdateStatusSlice.reducer
export default reducer
