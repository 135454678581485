import { bool, string, func } from 'prop-types'
import styled from 'styled-components'
import CheckboxMUI from '@material-ui/core/Checkbox'

const StyleCheckbox = styled(CheckboxMUI)`
  width: 8px;
  height: 8px;
  border-radius: 1px;
  .Mui-disabled && {
    cursor: not-allowed;
  }
`

const Checkbox = ({ ...props }) => {
  return (
    <StyleCheckbox
      {...props}
      sx={{
        color: 'gray'
      }}
    />
  )
}

Checkbox.propTypes = {
  disabled: bool,
  label: string,
  variant: string,
  onChange: func,
  checked: bool,
  indeterminate: bool
}

export default Checkbox
