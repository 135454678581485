import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import fetchCategory from '../../../../domain/features/category/fetchCategory'
import { getCategory } from '../../../../domain/features/category/getCategory'
import CustomSelectStatus from '../CustomSelectStatus/CustomSelectStatus'

export default function SelectCategory({
  isallfilter,
  isfilterleft,
  onChange,
  value,
  label
}) {
  const dispatch = useDispatch()
  useEffect(() => {
    const promise = dispatch(fetchCategory())
    return () => promise.abort()
  }, [dispatch])
  const { categoryOnlyNameList, status } = useSelector(getCategory)

  return (
    <CustomSelectStatus
      label={label}
      defaultStatus={categoryOnlyNameList}
      selectedStatus={value}
      setSelectedStatus={onChange}
      isallfilter={isallfilter}
      isfilterleft={isfilterleft}
      disabled={status === 'loading'}
      width="120px"
    />
  )
}

SelectCategory.propTypes = {
  isallfilter: PropTypes.bool,
  isfilterleft: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.any,
  label: PropTypes.string
}
SelectCategory.defaultProps = {
  label: 'Categoría:'
}
