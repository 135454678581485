import React, { memo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import IconButton from '@material-ui/core/Button'
import TooltipMUI from '@material-ui/core/Tooltip'
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined'
import fetchDeleteProduct from '../../../../domain/features/productSearchApi/fetchProductDelete'
import { useAuth } from '../../pages/auth/useAuth'

const TooltipStyled = styled(TooltipMUI)`
  margin-left: 10px;
`

const StyleButtonFile = styled(IconButton)`
  color: ${({ theme }) => theme.palette.error.main};
  &:hover {
    color: ${({ theme }) => theme.palette.error.dark};
  }
`

const DeleteProductButton = memo(({ id, productName }) => {
  const { currentUser: user } = useAuth()
  const dispatch = useDispatch()

  const navigate = useNavigate()
  const handleDeleteProduct = React.useCallback(() => {
    dispatch(
      fetchDeleteProduct({
        id: `gid://shopify/Product/${id}`
      })
    )
    navigate('/productos')
  }, [dispatch, id, navigate])
  return user?.role === 'admin' || user?.role === 'operations' ? (
    <TooltipStyled
      title={`Eliminar permanentemente ${productName}`}
      followCursor={true}
      arrow={true}
    >
      <StyleButtonFile color="inherit" onClick={handleDeleteProduct}>
        <DeleteOutlineOutlinedIcon fontSize="large" />
      </StyleButtonFile>
    </TooltipStyled>
  ) : null
})
DeleteProductButton.propTypes = {
  productName: PropTypes.string,
  id: PropTypes.string
}
DeleteProductButton.displayName = 'DeleteProduct'
export default DeleteProductButton
