import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import ModalMUI from '@material-ui/core/Modal'
import DialogActions from '@material-ui/core/DialogActions'
import styled from 'styled-components'
import isEmpty from 'lodash/isEmpty'

import Title from '../../atomics/Typography/Title'
import FieldInputController from '../../molecules/FieldInputController'
import FieldPriceController from '../../molecules/FieldPriceController'
import FieldQuantityController from '../../molecules/FieldQuantityController/FieldQuantityController'
import CheckboxesMedia from '../../organims/CheckboxesMedia/CheckboxesMedia'
import { useAuth } from '../../pages/auth/useAuth'
import FieldColorAutocomplete from '../../atomics/FieldColorAutocomplete'
import { useEffect } from 'react'

const Center = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Modal = styled(ModalMUI)`
  z-index: 9999 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20%;
  margin-right: 20%;
  overflow: auto;
  @media (max-width: 1100px) {
    margin: 1%;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: flex-start;
  }
`

const ContainerForm = styled('div')`
  margin-top: 3%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const ContainerImageList = styled('div')`
  gap: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const ModalForm = styled('form')`
  background-color: ${({ theme }) => theme.palette.background.default};
  box-shadow: 0px 0px 3.12996px 1.04332px rgba(0, 0, 0, 0.25);
  border-radius: 8.26805px;
  padding-left: 2%;
  padding-right: 2%;
  padding-bottom: 2%;
  padding-top: 50px;
`
const TitleVariantModal = styled(Title)`
  font-size: 30px;
  line-height: 32px;
`

const ContainerTitle = styled('div')`
  margin-bottom: 14px;
`

const ContainerSubtitle = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
`

const SubtitleVariantModal = styled(Title)`
  font-weight: 400;
  font-size: 14px;
  line-height: 16.41px;
`
const Row = styled('div')`
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-top: 2%;
  @media (max-width: 1100px) {
    flex: 1;
    flex-wrap: wrap;
    flex-direction: column;
  }
`

const ButtonSave = styled(Button)`
  color: #ffffff;
  height: 10%;
  font-size: 15px;
  font-weight: 500px;
  line-height: 32px;
  background-color: #008060;
`
const ButtonCancel = styled(Button)`
  color: #000000;
  border: 1px solid #000000;
  font-size: 15px;
  font-weight: 500px;
  line-height: 32px;
  background-color: #ffffff;
`

const ContainerButtons = styled(DialogActions)``

const Label = styled('label')`
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
`

const FielPrice = styled(FieldPriceController)`
  width: 90%;
  @media (max-width: 1100px) {
    width: auto;
    max-width: 100%;
  }
`
const FieldOwnSku = styled(FieldInputController)`
  width: 90%;
  @media (max-width: 1100px) {
    width: auto;
    max-width: 100%;
  }
`

const TitleImages = styled(Title)`
  font-weight: 700px;
  font-size: 19px;
  line-height: 32px;
`

const ContainerImageCard = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 3%;
  margin-bottom: 2%;
`

const ModalTemplate = ({
  open,
  onSubmit,
  onClose,
  title,
  subtitle,
  control,
  media,
  index,
  errors
}) => {
  const { currentUser: user } = useAuth()
  const isValid =
    String(user?.role) === 'admin' || String(user?.role) === 'operations'

  useEffect(() => {
    // Función que maneja el evento de la tecla "Esc"
    const handleEsc = event => {
      const isEscape =
        event.key === 'Escape' || // Valor estándar moderno
        event.key === 'Esc' || // Algunas versiones antiguas de navegadores
        event.keyCode === 27 || // Para navegadores antiguos que usan keyCode
        event.which === 27 // Alternativa para navegadores más viejos

      if (isEscape) {
        onClose()
      }
    }

    // Agregar el event listener solo si el modal está abierto
    if (open) {
      window.addEventListener('keydown', handleEsc)
    }

    // Limpiar el event listener cuando se cierre el modal
    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
  }, [open, onClose])
  return (
    <Center>
      <Modal
        disableAutoFocus
        disableEnforceFocus
        open={open}
        onClose={onClose}
        aria-labelledby={title}
        aria-describedby={subtitle}
      >
        <ModalForm>
          {title ? (
            <ContainerTitle>
              <TitleVariantModal>{title}</TitleVariantModal>
            </ContainerTitle>
          ) : null}
          {subtitle ? (
            <ContainerSubtitle>
              <SubtitleVariantModal>{subtitle}</SubtitleVariantModal>
            </ContainerSubtitle>
          ) : null}
          <ContainerForm>
            <Label>Color *</Label>
            <FieldColorAutocomplete
              fullWidth
              name={`variants[${index}].color`}
              defaultValue="Sin color"
              control={control}
              placeholder="Ingresa color"
              helperText="Escribe el color de la variante. En caso que no aplique, se debe colocar “Sin color”"
            />
            <Row>
              <ContainerSubtitle>
                <Label>Precio oferta</Label>
                <FielPrice
                  fullWidth
                  name={`variants[${index}].price`}
                  control={control}
                  placeholder="00000"
                  helperText="Ingresa un precio de oferta (iva incluído) solo en el caso de tener un descuento."
                />
              </ContainerSubtitle>
              <ContainerSubtitle>
                <Label>Cantidad disponible *</Label>
                <FieldQuantityController
                  fullWidth
                  name={`variants[${index}].inventoryQuantity`}
                  control={control}
                  placeholder="0"
                  helperText="Ingresa la cantidad de productos disponibles para la venta. Recomendamos que este valor sea 0 hasta que tu producto esté aprobado."
                />
              </ContainerSubtitle>
            </Row>
            <Row>
              <ContainerSubtitle>
                <Label>SKU Propio</Label>
                <FieldOwnSku
                  fullWidth
                  name={`variants[${index}].ownSku`}
                  control={control}
                  placeholder="Ingresa SKU propio"
                  helperText="Ingresa el SKU de la variante."
                />
              </ContainerSubtitle>
              <ContainerSubtitle>
                <Label>SKU</Label>
                <FieldInputController
                  fullWidth
                  disabled={!isValid}
                  name={`variants[${index}].sku`}
                  control={control}
                  placeholder="Ingresa SKU Kitchen center"
                  helperText="Ingresa SKU Kitchen center."
                />
              </ContainerSubtitle>
            </Row>
          </ContainerForm>
          {!isEmpty(media) ? (
            <ContainerImageCard>
              <TitleImages>Imagen de la variante</TitleImages>
              <ContainerImageList>
                <CheckboxesMedia
                  items={media}
                  control={control}
                  name={`variants[${index}].mediaIds`}
                  defaultValue={`variants[${index}].mediaIds`}
                  index={index}
                  isVariantModalOpened={true}
                />
              </ContainerImageList>
            </ContainerImageCard>
          ) : null}
          <ContainerButtons>
            <ButtonCancel onClick={onClose}>Cancelar</ButtonCancel>
            <ButtonSave
              type="submit"
              onClick={onSubmit}
              disabled={
                (errors.variants || []).length > index &&
                errors.variants[index]
              }
            >
              Guardar cambios
            </ButtonSave>
          </ContainerButtons>
        </ModalForm>
      </Modal>
    </Center>
  )
}
ModalTemplate.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  control: PropTypes.any,
  index: PropTypes.number,
  media: PropTypes.array,
  errors: PropTypes.any
}
ModalTemplate.defaultProps = {
  open: false,
  media: []
}
export default ModalTemplate
