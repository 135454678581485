import {
  createEntityAdapter,
  createSelector,
  createSlice
} from '@reduxjs/toolkit'

export const chipsAdapter = createEntityAdapter({
  selectId: chips => chips?.label
})

export const chipsSlice = createSlice({
  name: 'chips',
  initialState: chipsAdapter.getInitialState([]),
  reducers: {
    addAllChips: chipsAdapter.setAll,
    addOneChip(state, { payload }) {
      if (payload?.includes(undefined)) {
        return
      }
      return chipsAdapter.addMany(state, payload)
    },
    addChip: chipsAdapter.addOne,
    removeOneChip: chipsAdapter.removeOne,
    removeAllChips: chipsAdapter.removeAll
  }
})

export const {
  addAllChips,
  addOneChip,
  removeOneChip,
  addChip,
  removeAllChips
} = chipsSlice.actions

const getStatechips = state => state.ui.chips

const chipSelectors = chipsAdapter.getSelectors(getStatechips)
export const allchips = chipSelectors.selectAll

export const getChips = createSelector([getStatechips], chip => chip)
const reducer = chipsSlice.reducer
export default reducer
