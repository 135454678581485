import PropTypes from 'prop-types'
import styled from 'styled-components'
import MenuItem from '@material-ui/core/MenuItem'

import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import Input from '../../atomics/Input/Input'
import { useState } from 'react'
import { useEffect } from 'react'
import Autocomplete from '../../atomics/Autocomplete'

const ContainerSelect = styled(FormControl)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 36px;
  .MuiSelect-selectMenu {
    min-height: auto;
    padding-top: 10px;
    padding-bottom: 10px;
  }
`

const CheckboxStyled = styled(Checkbox)`
  width: 18px;
  height: 18px;
  padding-right: 20px;
  padding-top: 11px;
`

const InputStyled = styled(Input)`
  height: 36px;
  padding: 0;
  width: 100%;
  display: block;
  @media (max-width: 700px) {
    min-width: 100%;
    max-width: 100%;
  }
`
const Li = styled('li')`
  padding: 0px;
  width: 100%;
  padding-bottom: ${({ isEnd }) => (isEnd === 'true' ? '20px' : '0px')};
`

const MenuItemStyled = styled(MenuItem)`
  padding: 0px;
`

const ContainerDelete = styled(MenuItemStyled)`
  position: fixed;
  bottom: 4px;
  left: 0px;
  padding-left: 15px;
  background-color: #ffffff;
  width: 100%;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-color: #b7b7b7;
  border-top-style: solid;
  border-top-width: 1px;
  margin-top: 10px;
  opacity: 1 !important;
  color: #2469ce;
  color: ${props => (props.disabled ? '#B7B7B7' : '#2469ce')};
`

const Text = styled('p')`
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.2px;
`

const MenuDelete = ({ ...props }) => (
  <ContainerDelete {...props}>
    <Text>Borrar selección</Text>
  </ContainerDelete>
)

const MenuDefault = ({ value, defaultValue, ...props }) => (
  <MenuItemStyled value={value} {...props}>
    <CheckboxStyled checked={defaultValue?.includes(value)} />
    {value}
  </MenuItemStyled>
)

export default function CustomSelectStatus({
  label,
  selectedStatus,
  setSelectedStatus,
  defaultStatus = [],
  isallfilter,
  isfilterleft,
  ...props
}) {
  const [options, setOptions] = useState([])
  const handleSelectBase = (list, set) => name => {
    if (list?.some(item => item === name)) {
      const index = list?.indexOf(name)
      const values = [...list]
      values?.splice(index, 1)
      set(values)
    } else {
      set([...list, name])
    }
  }

  useEffect(() => {
    if (defaultStatus?.length > 0 && Array.isArray(defaultStatus)) {
      const current = []
      ;(defaultStatus || [])?.map((title, index) =>
        current?.push({ title, id: index })
      )
      current?.push({ title: 'Borrar selección', id: 9999 })
      setOptions(current)
    }
  }, [defaultStatus])

  const handleSelect = handleSelectBase(selectedStatus, setSelectedStatus)

  return (
    <ContainerSelect variant="outlined">
      <Autocomplete
        {...props}
        multiple
        disableCloseOnSelect
        isallfilter={isallfilter}
        isfilterleft={isfilterleft}
        renderTags={() => {}}
        id={`${label}`}
        onChange={(_, value) => {
          const isDelete = value.find(field => field.id === 9999)
          if (isDelete) {
            setSelectedStatus([])
          } else {
            value.map(val => handleSelect(val.title))
          }
        }}
        value={selectedStatus?.map(ss => {
          return { title: ss }
        })}
        label={label}
        options={options}
        selectedStatus={selectedStatus}
        setSelectedStatus={setSelectedStatus}
        getOptionLabel={({ title }) => title}
        renderOption={({ title, id }) => {
          return (
            <Li
              key={title}
              isEnd={String(defaultStatus?.length === id + 1)}
            >
              {id !== 9999 && (
                <MenuDefault
                  value={title}
                  defaultValue={selectedStatus}
                  onClick={() => handleSelect(title)}
                />
              )}
              {id === 9999 && (
                <MenuDelete
                  onClick={() => setSelectedStatus([])}
                  disabled={selectedStatus?.length === 0}
                />
              )}
            </Li>
          )
        }}
        renderInput={params => (
          <InputStyled {...params} placeholder={label} />
        )}
      />
    </ContainerSelect>
  )
}

CustomSelectStatus.propTypes = {
  defaultStatus: PropTypes.arrayOf(PropTypes.string),
  selectedStatus: PropTypes.arrayOf(PropTypes.string),
  setSelectedStatus: PropTypes.func
}
CustomSelectStatus.defaultProps = {
  defaultStatus: [],
  selectedStatus: [],
  setSelectedStatus: x => x
}
