import styled from 'styled-components'
import { Autocomplete as AC } from '@material-ui/lab'
import Popper from '@material-ui/core/Popper'

const ACWithStyles = styled(AC)`
  width: ${props => (props.width ? props.width : '100px')};
  .MuiInputBase-input {
    white-space: nowrap;
    max-width: auto;

    min-width: ${({ width }) => (width ? `${width}` : '90px')};
    @media (max-width: 1100px) {
      min-width: 100%;
      width: 100%;
    }
  }
  .MuiOutlinedInput-input:-webkit-autofill {
    height: 0px;
  }
  .MuiOutlinedInput-root {
    height: 36px;
    white-space: nowrap;
    font-size: 14px;
    margin: 0px;
    padding: 0px;
    padding-left: 14px;
    border-color: #7c7c7c;
    border-top-right-radius: ${({ isallfilter }) =>
      isallfilter === 'true' ? '0px' : '5px'};
    border-bottom-right-radius: ${({ isallfilter }) =>
      isallfilter === 'true' ? '0px' : '5px'};
    border-top-left-radius: ${({ isfilterleft }) =>
      isfilterleft === 'true' ? '0px' : '5px'};
    border-bottom-left-radius: ${({ isfilterleft }) =>
      isfilterleft === 'true' ? '0px' : '5px'};
  }
  .Mui-disabled {
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'default')};
  }
  @media (max-width: 700px) {
    width: 100%;
  }
`
const PopperMy = function (props) {
  return (
    <Popper
      {...props}
      style={{ width: 'fit-content' }}
      placement="bottom-start"
    />
  )
}
const Autocomplete = ({
  selectedStatus,
  label,
  options,
  isallfilter,
  isfilterleft,
  ...props
}) => {
  return (
    <ACWithStyles
      {...props}
      multiple
      noOptionsText="No hay resultados"
      isallfilter={String(isallfilter)}
      isfilterleft={String(isfilterleft)}
      PopperComponent={PopperMy}
      renderTags={() => {}}
      id={`${label}`}
      disableCloseOnSelect
      disableClearable
      fullWidth
      value={[
        selectedStatus?.map(ss => {
          return { title: ss }
        })
      ]}
      options={options}
    />
  )
}
export default Autocomplete
