import request from '../client/request'

const productApiService = (baseUrl, endpoint) => ({
  updateProductStatusById: async ({ id, status }) => {
    const body = JSON.stringify({
      id,
      status
    })
    const response = await request(`${baseUrl}${endpoint}/status`, {
      method: 'POST',
      data: body
    })
    const data = response.data
    return data
  },
  updateProductImagePosition: async ({ productId, imageId, position }) => {
    const body = JSON.stringify({
      productId,
      imageId,
      position
    })
    const response = await request(
      `${baseUrl}${endpoint}/media/position`,
      {
        method: 'POST',
        data: body
      }
    )
    const data = response.data
    return data
  }
})

export default productApiService(
  process.env.REACT_APP_API_PRODUCT_PATH,
  '/product/update'
)
