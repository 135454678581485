import { useState } from 'react'
import isNaN from 'lodash/isNaN'
import { useSpring, animated, config } from 'react-spring'
import VisibilitySensor from 'react-visibility-sensor'
import styled from 'styled-components'
import { statusColorMapper } from '../../../../domain/adpaters/map/statusColorMapper'
import Row from '../Styles/Row'
import Text from '../Typography/Text'

const Animated = styled(VisibilitySensor)`
  box-sizing: content-box;
  white-space: nowrap;
`

const ProgressBar = styled('div')`
  width: 60%;
  max-width: 100%;
  height: 25px;
  background-color: #f5f5f5;
  border-radius: 2.57975px;
  margin: 10px 0;
  margin-top: 0px;
  margin-left: 0px;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
`

const ProgressBarPrg = styled(animated.div)`
  height: 40px;
  min-height: 100%;
  max-height: 100%;
  border-radius: 2.57975px;
  background-color: ${({ status, theme }) =>
    status || theme.palette.warning.main};
  padding-left: 10px;
  padding-top: 5px;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  white-space: nowrap;
  display: block;
  position: absolute;
  text-overflow: clip;
  line-height: normal;
`

const TextType = styled(Text)`
  display: flex;
  white-space: nowrap;
  font-size: 12.8546px;
  line-height: 15px;
  margin-left: 10px;
  color: ${({ theme }) =>
    theme.palette.type === 'dark' ? '#FFFFFF' : '#212121'};
`

const Container = styled(Row)`
  align-items: baseline;
`

const LoadBar = ({ ...props }) => {
  const [isInView, setIsInView] = useState(false)

  const onVisibilityChange = isInView => setIsInView(isInView)

  const progressSpringStyleProps = useSpring({
    width: isInView
      ? `${isNaN(props.percent) ? 0 : props.percent}%`
      : `0%`,
    config: config.molasses
  })

  return (
    <Animated onChange={onVisibilityChange}>
      <Container>
        <ProgressBar>
          <ProgressBarPrg
            style={progressSpringStyleProps}
            status={statusColorMapper.get(props.type)}
          >
            {isNaN(props.percent) ? 0 : props.percent} %
          </ProgressBarPrg>
        </ProgressBar>
        <TextType>{props.skillname}</TextType>
      </Container>
    </Animated>
  )
}
export default LoadBar
