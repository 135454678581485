import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import {
  addOneType,
  allTypes,
  removeAllType,
  removeOneType
} from '../../../../domain/features/ui/typeSlice'

import SelectWithChips from '../../molecules/SelectWithChips'
import { typeList } from '../ModalAfterSale/typesAfterSale'

const Select = styled(SelectWithChips)`
  max-width: 20rem;
  min-width: auto;
`

const SelectType = ({ label, isallfilter, list }) => {
  const accountingStatement = useSelector(allTypes)

  const dispatch = useDispatch()
  const handleChange = useCallback(
    event => {
      return event.target.value !== 'Borrar selección'
        ? dispatch(addOneType(event.target.value))
        : dispatch(removeAllType())
    },
    [dispatch]
  )

  const handleDelete = useCallback(
    value => {
      dispatch(removeOneType(value))
    },
    [dispatch]
  )

  return (
    <Select
      label={label || 'Tipo'}
      isCheckbox
      isallfilter={isallfilter}
      value={accountingStatement}
      onChange={handleChange}
      list={list || Object.values(typeList)}
      handleDeleteChip={handleDelete}
      handleDeleteAll={value => dispatch(removeAllType(value))}
    />
  )
}

export default SelectType
