import { useMemo } from 'react'
import styled from 'styled-components'
import OthersForms from '../../molecules/OthersForms/OthersForms'
import { GeneralitiesItems } from './GeneralitiesList'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { searchAttribuesByCategory } from '../../../../domain/features/category/getCategory'
import { useWatch } from 'react-hook-form'
import { isEmpty } from 'lodash'
import FieldFile from '../FieldFile/FieldFile'
import CardProduct from '../../atomics/CardProduct/CardProduct'
import TitleProduct from '../../atomics/Typography/TitleProduct'
import Text from '../../atomics/Typography/Text'

const SubContainer = styled('div')`
  display: flex;
  width: 100%;
`

const SubContainerColumn = styled(SubContainer)`
  flex-direction: column;
  margin-bottom: 3%;
  margin-top: 3%;
`

const StyledText = styled(Text)`
  font-size: 18px;
`

const ContainerFiles = styled('div')`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 1%;
  margin-top: 2%;
`

const ProductExtraOptionsForm = ({
  control,
  errors,
  allFiles,
  setAllFiles,
  setValue,
  clearErrors,
  register
}) => {
  const handleDeleteFile = e => {
    const { name } = e.target
    const newFiles = { ...allFiles }
    delete newFiles[name]
    setAllFiles(newFiles)
    setValue(name, null, { shouldDirty: true })
    clearErrors(name)
  }
  const category = useWatch({ name: 'category', control })
  const fieldsProduct = useSelector(searchAttribuesByCategory(category))

  const fieldListMemo = useMemo(
    () =>
      fieldsProduct
        ? GeneralitiesItems.filter(({ name }) =>
            fieldsProduct?.some(
              field =>
                String(field).toLowerCase() === String(name).toLowerCase()
            )
          )
        : GeneralitiesItems,
    [fieldsProduct]
  )

  const isViewFile = name =>
    fieldsProduct
      ? fieldsProduct?.some(field => String(field) === String(name))
      : []

  return (
    <CardProduct>
      <TitleProduct>Otros atributos</TitleProduct>
      {isEmpty(category) ? (
        <StyledText>
          Selecciona la categoría del producto en “Información general”
          para agregar más especificaciones del mismo.
        </StyledText>
      ) : (
        <Text>
          Es importante que agregues las especificaciones necesarias
          dependiendo de la categoría del producto.
        </Text>
      )}
      <SubContainerColumn>
        {isEmpty(category) ? null : (
          <>
            <ContainerFiles>
              {!isViewFile('Manual de uso') ? null : (
                <FieldFile
                  name="manual"
                  label="Manual de uso"
                  tooltipTitle="Adjuntar archivo (PDF)"
                  allFiles={allFiles}
                  handleDeleteFile={handleDeleteFile}
                  register={register}
                  control={control}
                  errors={errors}
                />
              )}
              {!isViewFile('Ficha técnica') ? null : (
                <FieldFile
                  name="dataSheet"
                  label="Ficha técnica"
                  tooltipTitle="Adjuntar archivo (PDF)"
                  allFiles={allFiles}
                  handleDeleteFile={handleDeleteFile}
                  register={register}
                  control={control}
                  errors={errors}
                />
              )}
              {!isViewFile('Certificado QR') ? null : (
                <FieldFile
                  label="Certificado QR"
                  name="certificate"
                  tooltipTitle="Adjuntar archivo (PNG o JPG)"
                  allFiles={allFiles}
                  handleDeleteFile={handleDeleteFile}
                  register={register}
                  control={control}
                  errors={errors}
                />
              )}
              {!isViewFile('Eficiencia energética') ? null : (
                <FieldFile
                  label="Eficiencia energética"
                  name="efficiency"
                  tooltipTitle="Adjuntar archivo (PNG o JPG)"
                  allFiles={allFiles}
                  handleDeleteFile={handleDeleteFile}
                  register={register}
                  control={control}
                  errors={errors}
                />
              )}
              {!isViewFile('Inpage') ? null : (
                <FieldFile
                  label="Inpage"
                  name="inpage"
                  tooltipTitle="Adjuntar archivo (PDF)"
                  allFiles={allFiles}
                  handleDeleteFile={handleDeleteFile}
                  register={register}
                  control={control}
                  errors={errors}
                />
              )}
              {!isViewFile('Foto encastre') ? null : (
                <FieldFile
                  label="Foto encastre"
                  name="descriptionImage"
                  tooltipTitle="Adjuntar archivo (PNG o JPG)"
                  allFiles={allFiles}
                  handleDeleteFile={handleDeleteFile}
                  register={register}
                  control={control}
                  errors={errors}
                />
              )}
            </ContainerFiles>
            <OthersForms
              items={fieldListMemo}
              errors={errors}
              control={control}
            />
          </>
        )}
      </SubContainerColumn>
    </CardProduct>
  )
}

ProductExtraOptionsForm.propTypes = {
  control: PropTypes.any,
  errors: PropTypes.object,
  allFiles: PropTypes.object,
  setAllFiles: PropTypes.func
}

export default ProductExtraOptionsForm
