import styled from 'styled-components'
import PropTypes from 'prop-types'
import FieldPriceController from '../../molecules/FieldPriceController'
import FieldController from '../../molecules/FieldInputController'
import FieldQuantityController from '../../molecules/FieldQuantityController/FieldQuantityController'
import TitleProduct from '../../atomics/Typography/TitleProduct'
import CardProduct from '../../atomics/CardProduct/CardProduct'
import Label from '../../atomics/Label'
import { useAuth } from '../../pages/auth/useAuth'

const SubContainer = styled('div')`
  display: flex;
  width: 90%;
  margin-right: 5%;
  @media (max-width: 1100px) {
    flex-wrap: wrap;
    width: 100%;
    margin-right: 0px;
  }
`
const SubContainerQuantity = styled('div')`
  margin-top: 10%;
`
const SubContainerColumn = styled(SubContainer)`
  flex-direction: column;
  margin-bottom: 1.4rem;
`

const SubContainerColumnMargined = styled(SubContainerColumn)`
  margin-right: -5%;
  @media (max-width: 1100px) {
    margin-right: 0px;
    width: 100%;
  }
`

const ProductPriceInventoryForm = ({ control }) => {
  const { currentUser: user } = useAuth()
  const isValid =
    String(user?.role) === 'admin' || String(user?.role) === 'operations'

  return (
    <CardProduct>
      <TitleProduct>Precio e inventario</TitleProduct>
      <SubContainer>
        <SubContainerColumn>
          <Label>Precio normal *</Label>
          <FieldPriceController
            control={control}
            name="variants[0].compareAtPrice"
            placeholder="0000"
            helperText="Ingresa el precio de venta normal del producto, IVA incluido."
          />
        </SubContainerColumn>
        <SubContainerColumnMargined>
          <Label>Precio oferta</Label>
          <FieldPriceController
            control={control}
            name="variants[0].price"
            placeholder="0000"
            helperText="Ingresa un precio de oferta (iva incluído) solo en el caso de tener un descuento."
          />
        </SubContainerColumnMargined>
      </SubContainer>
      <SubContainer>
        <SubContainerColumn>
          <Label>SKU Propio</Label>
          <FieldController
            control={control}
            name="variants[0].ownSku"
            placeholder="Ingresa SKU Propio"
            helperText="Ingresa el SKU de tu producto a nivel de variante. Nosotros agregaremos otro correspondiente a Kitchen Center."
          />
          <SubContainerQuantity>
            <Label>Cantidad disponible *</Label>
            <FieldQuantityController
              control={control}
              name="variants[0].inventoryQuantity"
              placeholder="0"
            />
          </SubContainerQuantity>
        </SubContainerColumn>
        <SubContainerColumnMargined>
          <Label>SKU</Label>
          <FieldController
            control={control}
            name="variants[0].sku"
            placeholder="Ingresa SKU Kitchen Center"
            helperText="Ingresa el SKU de Kitchen Center."
            disabled={!isValid}
          />
        </SubContainerColumnMargined>
      </SubContainer>
    </CardProduct>
  )
}

ProductPriceInventoryForm.propTypes = {
  control: PropTypes.any
}

export default ProductPriceInventoryForm
