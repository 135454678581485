import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import afterSaleService from '../../../data/services/afterSaleService'
import fechOrderDetail from '../orderDetail/fetchOrderDetail'

const fetchAfterSale = createAsyncThunk(
  'afterSale/create',
  async (
    {
      id,
      type,
      items,
      status,
      penalty,
      transport,
      reason,
      isWithdrawable
    },
    { rejectWithValue, signal, dispatch }
  ) => {
    const source = axios.CancelToken.source()
    signal.addEventListener('abort', () => {
      source.cancel()
    })
    try {
      const data = await afterSaleService.create({
        id,
        type,
        items,
        status,
        penalty: penalty || 0,
        transport: transport || 0,
        isWithdrawable,
        reason,
        cancelToken: source.token
      })

      if (data?.isAxiosError) {
        return rejectWithValue({ message: data.response.data.message })
      }

      dispatch(fechOrderDetail({ parentId: id }))

      return data
    } catch (error) {
      return rejectWithValue({ error })
    }
  }
)
export default fetchAfterSale
