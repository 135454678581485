import { initializeApp } from 'firebase/app'
import {
  getAuth,
  setPersistence,
  indexedDBLocalPersistence
} from 'firebase/auth'

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MESASUREMENT_ID
}

const firebase = initializeApp(firebaseConfig)

const auth = getAuth(firebase)
auth.languageCode = 'es'
setPersistence(auth, indexedDBLocalPersistence)

export { auth }
export default firebase
