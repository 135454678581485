import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/Button'

import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import styled from 'styled-components'
import { useRef } from 'react'
import Navigate from '../Navigate'
import Tooltip from '../Tooltip'

const GoProduct = styled(Navigate)`
  margin: 0px;
  padding: 0px;
`

const IconButtonBase = styled(IconButton)`
  color: ${({ theme, color }) => color || theme.palette.text.primary};
  z-index: 5;
  &:disabled {
    cursor: not-allowed;
    pointer-events: auto;
  }
`

const ButtonEditProduct = ({
  to,
  titleTooltip,
  color,
  fontSize = 'large'
}) => {
  const iconbuttonRef = useRef()

  return (
    <GoProduct to={to}>
      <Tooltip title={titleTooltip}>
        <IconButtonBase ref={iconbuttonRef} color={color}>
          <EditOutlinedIcon fontSize={fontSize || 'large'} />
        </IconButtonBase>
      </Tooltip>
    </GoProduct>
  )
}
ButtonEditProduct.propTypes = {
  to: PropTypes.any,
  titleTooltip: PropTypes.string,
  color: PropTypes.string,
  fontSize: PropTypes.string
}
ButtonEditProduct.defaultProps = {
  to: undefined,
  titleTooltip: '',
  fontSize: 'large',
  color: 'inherit'
}
export default ButtonEditProduct
