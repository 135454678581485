import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import fetchPayoutDownload from './fetchPayoutsDownload'

export const payoutsDownloadAdapter = createEntityAdapter()

export const payoutsDownloadSlicer = createSlice({
  name: 'payouts/download',
  initialState: payoutsDownloadAdapter.getInitialState({
    status: 'idle',
    url: {},
    errors: null
  }),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchPayoutDownload.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(fetchPayoutDownload.rejected, (state, { payload }) => {
      state.status = 'error'
      state.errors = payload
    })
    builder.addCase(
      fetchPayoutDownload.fulfilled,
      (state, { payload }) => {
        state.status = 'finished'
        state.url = payload
      }
    )
  }
})

const reducer = payoutsDownloadSlicer.reducer
export default reducer
