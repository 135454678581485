import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useScroll, animated } from '@react-spring/web'
import isString from 'lodash/isString'
import styled from 'styled-components'
import AppBar from '@material-ui/core/AppBar'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Fade from '@material-ui/core/Fade'
import Toolbar from '@material-ui/core/Toolbar'
import MenuButton from '../../atomics/MenuButton'
import UserMenu from '../../molecules/UserMenu'
import ButtonPreviewProduct from '../../atomics/ButtonPreviewProduct/ButtonPreviewProduct'
import ButtonBack from '../../atomics/ButtonBack/ButtonBack'

import { isOpenDrawer } from '../../../../domain/features/ui/drawerSlice'
import { drawerMinWith, drawerWidth } from '../Drawer/Drawer'
import getFullDate from '../../../../domain/adpaters/days/getFullDate'

const ContainerRight = styled('div')`
  display: flex;
  flex-direction: column;
  margin-left: 2%;
  white-space: nowrap;
`

const ButtonBackHeader = styled(ButtonBack)`
  margin: 0px;
  width: 30px;
  min-width: 30px;
  height: 30px;
  margin-right: 2%;
  @media (max-width: 700px) {
    margin-right: 11px;
  }
`

const ImageHeader = styled('img')`
  background-color: #313234;
  border: 1px solid #c1c1c1;
`

const Row = styled('div')`
  display: flex;
  flex-direction: row;
`

const RowTracking = styled(Row)`
  justify-content: baseline;
  align-items: center;

  margin-left: ${({ open }) =>
    open === 'true'
      ? `${drawerWidth - 40}px`
      : `${drawerMinWith + 100}px`};

  @media (max-width: 1100px) {
    margin-left: 0px;
  }
`

const ContainerTitle = styled('div')`
  display: flex;
  flex-direction: column;
  margin-left: 2%;
  margin-right: 1%;
  padding-right: 2%;
  white-space: nowrap;
  @media (max-width: 1100px) {
    overflow: hidden;
    text-transform: ellipse;
    margin-left: 3%;
  }
  @media (max-width: 700px) {
    width: 100px;
    margin-left: 5px;
  }
`

const H1 = styled('h1')`
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
`

const H2 = styled('h2')`
  margin: 0;
  padding: 0;
  font-size: 12px;
  line-height: 14px;
  color: #c1c1c1;

  @media (max-width: 700px) {
    font-size: 11px;
    line-height: 12x;
  }
`

const H3 = styled('h3')`
  margin: 0;
  padding: 0;
  font-size: 10px;
  line-height: 12px;
  color: #b7b7b7;
  @media (max-width: 700px) {
    font-size: 8px;
    line-height: 10x;
  }
`

const H3Right = styled(H3)`
  text-align: right;
  transition: all 0.3s ease-in-out;
  &:hover {
    color: #ffffff;
    scale: 1.2;
    transform: translate(-6%);
  }
`

const Logo = styled('img')`
  border: 1px solid #c1c1c1;
  background-color: ${({ theme }) =>
    theme.palette.type !== 'dark' ? '#313234' : '#FFFFFF'};
`

const HeaderBar = styled(animated(AppBar))`
  background-color: ${({ theme }) =>
    theme.palette.type === 'light' ? '#313234' : '#202223'};
  height: 58px;
`

const ToolbarContainer = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
`
ToolbarContainer.displayName = 'ToolbarContainer'

const ToolbarLeft = styled(ToolbarContainer)`
  justify-content: flex-start;
`
ToolbarLeft.displayName = 'ToolbarLeft'

const ToolbarRight = styled(ToolbarContainer)`
  justify-content: flex-end;
`
ToolbarRight.displayName = 'ToolbarRight'

const Header = () => {
  const [isView, setView] = useState(false)
  const { scrollYProgress } = useScroll({
    onChange: ({ value: { scrollY } }) => {
      if (scrollY > 90) {
        setView(true)
      } else {
        setView(false)
      }
    },
    default: {
      immediate: true
    }
  })

  const { state, pathname } = useLocation()
  const optionMap = new Map([
    ['ACTIVE', 'Aprobado'],
    ['DRAFT', 'Deshabilitado'],
    ['ARCHIVED', 'Pendiente aprobación']
  ])
  const matches = useMediaQuery('(max-width:1100px)')
  const isBig = useMediaQuery('(max-width:1340px)')
  const open = useSelector(isOpenDrawer)

  return (
    <HeaderBar
      position="sticky"
      elevation={1}
      color="transparent"
      style={scrollYProgress}
    >
      <Toolbar variant="regular">
        <ToolbarLeft>
          {matches &&
          pathname.match('/^/productos/d+$/') === null &&
          isView ? (
            <ButtonBackHeader />
          ) : (
            <MenuButton />
          )}
          {matches && (
            <Logo
              src={
                state?.featuredImage?.url ||
                'https://cdn.shopify.com/s/files/1/0104/4391/5319/files/kitchencenter_logo.svg?6927'
              }
              alt="logo"
              width="44px"
              height="44px"
            />
          )}
          {pathname.match('/^/productos/d+$/') === null &&
            state?.title && (
              <Fade
                in={isView}
                timeout={{ appear: 800, enter: 1000, exit: 800 }}
              >
                <RowTracking open={String(open)}>
                  {!matches && <ButtonBackHeader />}
                  {!matches && state?.featuredImage?.url && (
                    <ImageHeader
                      src={
                        state?.featuredImage?.url ||
                        state?.items[0]?.image ||
                        state?.itemList[0]?.image ||
                        'https://cdn.shopify.com/s/files/1/0104/4391/5319/files/kitchencenter_logo.svg?6927'
                      }
                      alt={state?.featuredImage?.alt || 'Sin Imagen'}
                      width="44px"
                      height="44px"
                    />
                  )}
                  <ContainerTitle>
                    <H1>{state?.title}</H1>
                    <H2>{state?.vendor || state?.brand}</H2>
                    {state?.status && (
                      <H3>
                        Estado:{' '}
                        {optionMap.get(state?.status) || state?.status}
                      </H3>
                    )}
                  </ContainerTitle>
                </RowTracking>
              </Fade>
            )}
        </ToolbarLeft>
        <ToolbarRight>
          {pathname.match('/^/productos/d+$/') === null &&
            isView &&
            state?.updatedAt && (
              <RowTracking>
                {!isBig && (
                  <ContainerRight>
                    {state?.publishedAt && (
                      <H3Right>
                        Publicado: {getFullDate(state?.publishedAt)}
                      </H3Right>
                    )}
                    {state?.updatedAt && (
                      <H3Right>
                        Actualizado: {getFullDate(state?.updatedAt)}
                      </H3Right>
                    )}
                    {isString(getFullDate(state?.createdAt)) && (
                      <H3Right>
                        Creado: {getFullDate(state?.createdAt)}
                      </H3Right>
                    )}
                  </ContainerRight>
                )}
                {state?.onlineStorePreviewUrl && (
                  <ButtonPreviewProduct
                    color="#FFFFFF"
                    titleTooltip="Ver vista previa"
                    to={state?.onlineStorePreviewUrl}
                  />
                )}
              </RowTracking>
            )}

          <UserMenu />
        </ToolbarRight>
      </Toolbar>
    </HeaderBar>
  )
}
export default Header
