import map from 'lodash/fp/map'
import round from '../../utils/round'
import normalizeOrder from '../orders/normalizeOrder'

const normalizePayout = ({
  brandDetail,
  commission,
  payout,
  dispatchedSale,
  relatedSuborders,
  sellerName,
  isDispatched,
  accountingStatement,
  totalSale,
  otherDiscount,
  allStatus,
  ...queryInfo
}) => ({
  ...queryInfo,
  dispatchedSale: round(dispatchedSale),
  payout: round(payout),
  commission: round(commission),
  totalSale: round(totalSale),
  otherDiscount: otherDiscount ? round(otherDiscount) : 0,
  subRows:
    brandDetail?.map(
      ({
        brand,
        commission,
        dispatchedSale,
        isDispatched,
        accountingStatement,
        payout,
        type,
        status,
        otherDiscount,
        totalSale,
        ...detail
      }) => ({
        ...detail,
        sellerName: brand,
        brand,
        payout: round(payout),
        totalSale: round(totalSale),
        commission: round(commission),
        dispatchedSale: round(dispatchedSale),
        otherDiscount: otherDiscount ? round(otherDiscount) : 0,
        isDispatched: isDispatched ? 'Despachado' : status,
        status: allStatus,
        type,
        detail: {}
      })
    ) || [],
  detail: {
    sellerName,
    orders: map(normalizeOrder)(relatedSuborders),
    status: allStatus
  },
  sellerName
})

export default normalizePayout
