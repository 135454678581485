import React, { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import SelectWithChips from '../../molecules/SelectWithChips'
import {
  addOneSeller,
  getSellers,
  removeOneSeller,
  allSellers,
  removeAllSeller
} from '../../../../domain/features/sellers/getSellers'
import fetchSellers from '../../../../domain/features/sellers/fetchSellers'

const Select = styled(SelectWithChips)`
  max-width: 13.5rem;
  min-width: auto;
`

const SelectWithChipsSellers = ({ isallfilter, isfilterleft }) => {
  const sellers = useSelector(allSellers)
  const allSellersState = useSelector(getSellers)

  const dispatch = useDispatch()
  useEffect(() => {
    const promise = dispatch(fetchSellers())
    return () => promise.abort()
  }, [dispatch])

  const handleChange = useCallback(
    event => {
      dispatch(addOneSeller(event.target.value))
    },
    [dispatch]
  )

  const handleDelete = useCallback(
    value => {
      dispatch(removeOneSeller(value))
    },
    [dispatch]
  )

  return (
    <Select
      label="Sellers"
      isCheckbox
      isallfilter={isallfilter}
      isfilterleft={isfilterleft}
      value={sellers || ''}
      onChange={handleChange}
      list={allSellersState?.allSellers || []}
      handleDeleteChip={handleDelete}
      disabled={allSellersState?.status === 'loading'}
      handleDeleteAll={value => dispatch(removeAllSeller(value))}
    />
  )
}

export default SelectWithChipsSellers
