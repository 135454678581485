import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import {
  addOnereason,
  allReason,
  removeAllreason,
  removeOnereason
} from '../../../../domain/features/ui/reasonSlice'

import SelectWithChips from '../../molecules/SelectWithChips'

const Select = styled(SelectWithChips)`
  max-width: 20rem;
  min-width: auto;
`

export const reasonList = [
  'Sin Stock',
  'Anular Orden / Arrepentimiento de Compra',
  'Retracto / Arrepentimiento de Compra',
  'Producto Defectuoso/Fallado',
  'SKU Erróneo',
  'Pedido Incompleto',
  'Despacho Atrasado',
  'Producto Dañado (por despacho)',
  'Desconocimiento de Entrega'
]

const SelectReason = ({ label, isallfilter, list }) => {
  const accountingStatement = useSelector(allReason)

  const dispatch = useDispatch()
  const handleChange = useCallback(
    event => {
      return event.target.value !== 'Borrar selección'
        ? dispatch(addOnereason(event.target.value))
        : dispatch(removeAllreason())
    },
    [dispatch]
  )

  const handleDelete = useCallback(
    value => {
      dispatch(removeOnereason(value))
    },
    [dispatch]
  )

  return (
    <Select
      label={label || 'Motivo postventa'}
      isCheckbox
      isallfilter={isallfilter}
      value={accountingStatement}
      onChange={handleChange}
      list={list || reasonList}
      handleDeleteChip={handleDelete}
      handleDeleteAll={value => dispatch(removeAllreason(value))}
    />
  )
}

export default SelectReason
