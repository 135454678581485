import { fetchCommissionsDecoded } from '../client/fetchDecoded'
import persistentStore from '../persistentStore'

const sellerServices = (baseUrl, endpoint) => ({
  sellerList: async ({ query, signal }) => {
    let params = ''
    if (query) {
      params = `?query=${query}`
    }
    try {
      const data = await fetchCommissionsDecoded(
        `${baseUrl}/${endpoint}${params}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            authorization: persistentStore.getToken(),
            app: process.env.REACT_APP_APPLICATION_NAME
          },
          signal
        }
      )
      return data
    } catch (error) {
      throw error
    }
  },
  sellerDetail: async ({ slug }) => {
    const data = await fetchCommissionsDecoded(
      `${baseUrl}/${endpoint}/${slug}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          authorization: persistentStore.getToken(),
          app: process.env.REACT_APP_APPLICATION_NAME
        }
      }
    )
    return data
  },

  sellerCreate: async data => {
    return await fetchCommissionsDecoded(`${baseUrl}/${endpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: persistentStore.getToken(),
        app: process.env.REACT_APP_APPLICATION_NAME
      },
      body: JSON.stringify(data)
    })
  },

  sellerUpdate: async data => {
    return await fetchCommissionsDecoded(
      `${baseUrl}/${endpoint}/${data.slugSeller}`,
      {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          authorization: persistentStore.getToken(),
          app: process.env.REACT_APP_APPLICATION_NAME
        },
        body: JSON.stringify(data)
      }
    )
  },

  brandsUpdate: async data => {
    return await fetchCommissionsDecoded(`${baseUrl}/brands/update`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        authorization: persistentStore.getToken(),
        app: process.env.REACT_APP_APPLICATION_NAME
      },
      body: JSON.stringify({
        sellerName: data.sellerName,
        brandName: data.brandName,
        newBrandName: data.newBrandName
      })
    })
  },

  brandsCheck: async data => {
    return await fetchCommissionsDecoded(`${baseUrl}/brands/check`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: persistentStore.getToken(),
        app: process.env.REACT_APP_APPLICATION_NAME
      },
      body: JSON.stringify({
        sellerName: data.sellerName,
        brands: data.brands || []
      })
    })
  },

  brandsDelete: async data => {
    return await fetchCommissionsDecoded(`${baseUrl}/brands/delete`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        authorization: persistentStore.getToken(),
        app: process.env.REACT_APP_APPLICATION_NAME
      },
      body: JSON.stringify({
        sellerName: data.sellerName,
        brandName: data.brandName
      })
    })
  }
})

export default sellerServices(
  process.env.REACT_APP_API_COMMISIONS_PATH,
  'sellers'
)
