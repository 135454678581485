import React from 'react'
import isNil from 'lodash/isNil'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import PropTypes from 'prop-types'
import Tooltip from '@material-ui/core/Tooltip'
import Popover from '@material-ui/core/Popover'
import Text from '../../atomics/Typography/Text'
import IconDownloadTemplate from '../../atomics/Icon/IconDownloadTemplate'
import IconDownloadSvg from '../../atomics/Icon/IconDownloadSvg'

const ButtonDownloaStyled = styled(IconButton)`
  border: solid 1px #d1d0d0;
  border-radius: 5px;
  padding: 10px;
  &:disabled {
    cursor: not-allowed;
    pointer-events: auto;
    color: #cccccc !important;
    fill: #cccccc !important;
  }
`

const ButtonOptionDropdown = styled(Button)`
  padding-left: 10%;
`
const Container = styled('div')`
  margin-left: 10px;
`

const DropdownContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 180px;
  padding-top: 7px;
`

const StyledText = styled(Text)`
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.2px;
  text-align: left;
  text-transform: none;
  padding-bottom: 7px;
`
const ButtonDownload = ({
  type,
  disabled,
  isViewer,
  handleClickOptionOne,
  handleClickOptionTwo,
  optionOneText,
  toolTipText,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  return (
    <>
      {type === 'select' ? (
        <Container>
          <Tooltip
            title={toolTipText ? toolTipText : 'Descargar planilla'}
          >
            <ButtonDownloaStyled
              aria-describedby={id}
              variant="text"
              disabled={disabled || isViewer}
              onClick={handleClick}
            >
              {disabled ? (
                <IconDownloadSvg disabled={disabled || isViewer} />
              ) : (
                <IconDownloadTemplate disabled={disabled || isViewer} />
              )}
            </ButtonDownloaStyled>
          </Tooltip>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
          >
            <DropdownContainer>
              <ButtonOptionDropdown
                variant="text"
                size="large"
                onClick={handleClickOptionOne}
                disabled={disabled || isViewer}
              >
                <StyledText>{optionOneText}</StyledText>
              </ButtonOptionDropdown>
            </DropdownContainer>
            {!isNil(handleClickOptionTwo) ? (
              <ButtonOptionDropdown
                variant="text"
                size="large"
                onClick={handleClickOptionTwo}
                disabled={disabled || isViewer}
              >
                <StyledText>Listado completosssss</StyledText>
              </ButtonOptionDropdown>
            ) : null}
          </Popover>
        </Container>
      ) : (
        <ButtonDownloaStyled
          variant="text"
          disabled={disabled || isViewer}
          {...props}
        >
          <IconDownloadTemplate />
        </ButtonDownloaStyled>
      )}
    </>
  )
}

ButtonDownload.propTypes = {
  type: PropTypes.string,
  disabled: PropTypes.bool,
  handleClickOptionOne: PropTypes.func,
  handleClickOptionTwo: PropTypes.func,
  toolTipText: PropTypes.string
}
ButtonDownload.defaultProps = {
  type: 'select',
  disabled: false,
  isViewer: false,
  handleClickOptionOne: () => undefined,
  handleClickOptionTwo: () => undefined,
  toolTipText: undefined
}
export default ButtonDownload
