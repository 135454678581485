export const colors = new Map([
  ['success', ' #92E6B5'],
  ['warning', '#FFC96B'],
  ['error', '#FC8E8E'],
  ['pending', '#C4C4C4'],
  ['cancelacion', '#FFC96B'],
  ['devolucion', '#FD9840'],
  ['reenvio', '#7CC7E2'],
  ['aftersale', '#FF6E6E']
])
export const statusColorMapper = new Map([
  ['No despachado', colors.get('warning')],
  ['Despachado', colors.get('success')],
  ['Ingresado', colors.get('warning')],
  ['Rechazado', colors.get('error')],
  ['Devuelto', colors.get('success')],
  ['Cancelado', colors.get('error')],
  ['Facturado', colors.get('success')],
  ['Pagado', colors.get('success')],
  ['Contabilizado', colors.get('warning')],
  ['Contabilización Fallida', colors.get('error')],
  ['Procesando', colors.get('pending')],
  ['Facturado - No pagado', colors.get('warning')],
  ['DRAFT', colors.get('warning')],
  ['ACTIVE', colors.get('success')],
  ['Cancelación', colors.get('cancelacion')],
  ['Devolución', colors.get('devolucion')],
  ['Reenvío', colors.get('reenvio')],
  ['postventa', colors.get('aftersale')]
])
