import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import fetchBrands from './fetchBrands'
import fetchMultipleBrands from './fetchMultipleBrands'
import isArray from 'lodash/isArray'
import sortString from '../../utils/sortString'
import persistentStore from '../../../data/persistentStore'

export const brandsAdapter = createEntityAdapter({
  selectId: ({ id }) => String(id)
})

export const brandSlice = createSlice({
  name: 'brands',
  initialState: brandsAdapter.getInitialState({
    status: 'idle',
    entities: {},
    allBrands: persistentStore.isAllBrands()
      ? persistentStore.getAllBrands()
      : [],
    brandOnlyNameList: persistentStore.isBrands()
      ? persistentStore.getBrands()
      : []
  }),
  reducers: {},
  extraReducers: {
    [fetchBrands.loading]: state => {
      state.status = 'loading'
    },
    [fetchBrands.rejected]: state => {
      state.status = 'error'
    },
    [fetchBrands.fulfilled]: (state, { payload }) => {
      state.status = 'finished'
      brandsAdapter.setAll(state, payload)
    },
    [fetchMultipleBrands.pending]: state => {
      state.status = 'loading'
    },
    [fetchMultipleBrands.rejected]: state => {
      state.status = 'error'
    },
    [fetchMultipleBrands.fulfilled]: (state, { payload }) => {
      state.status = 'finished'
      state.brandOnlyNameList = isArray(payload)
        ? payload.map(({ brandName }) => brandName).sort(sortString)
        : []
      state.allBrands = isArray(payload) ? [...payload] : []
      persistentStore.setBrands(state.brandOnlyNameList)
    }
  }
})

const reducer = brandSlice.reducer
export default reducer
