import { configureStore } from '@reduxjs/toolkit'

import reducerList from './reducers'

const store = configureStore({
  reducer: reducerList,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck: false }),
  devTools: process.env.NODE_ENV !== 'production'
})

export default store
