export const reasonList = [
  {
    id: 1,
    type: 'Cancelación',
    reason: 'Sin Stock',
    view: 'Quiebre Stock Seller',
    responsability: 'Seller'
  },
  {
    id: 2,
    type: 'Cancelación',
    reason: 'Anular Orden / Arrepentimiento de Compra',
    view: 'Anulación de Cliente',
    responsability: 'Cliente'
  },
  {
    id: 3,
    type: 'Devolución',
    reason: 'Retracto / Arrepentimiento de Compra',
    view: 'Retracto Cliente (Despachado)',
    responsability: 'Cliente'
  },
  {
    id: 4,
    type: 'Devolución',
    reason: 'Producto Defectuoso/Fallado',
    view: 'Producto Defectuoso',
    responsability: 'Seller'
  },
  {
    id: 5,
    type: 'Devolución',
    reason: 'SKU Erróneo',
    view: 'SKU Erróneo',
    responsability: 'Seller'
  },
  {
    id: 6,
    type: 'Devolución',
    reason: 'Pedido Incompleto',
    view: 'Pedido Incompleto',
    responsability: 'Seller/OPL'
  },
  {
    id: 7,
    type: 'Devolución',
    reason: 'Incidencia Courier',
    view: 'Incidencia Courier',
    responsability: 'Seller/OPL'
  },
  {
    id: 8,
    type: 'Reenvío',
    reason: 'Producto Defectuoso/Fallado',
    view: 'Producto Defectuoso',
    responsability: 'Seller'
  },
  {
    id: 9,
    type: 'Reenvío',
    reason: 'SKU Erróneo',
    view: 'SKU Erróneo',
    responsability: 'Seller'
  },
  {
    id: 10,
    type: 'Reenvío',
    reason: 'Pedido Incompleto',
    view: 'Pedido Incompleto',
    responsability: 'Seller/OPL'
  },
  {
    id: 11,
    type: 'Reenvío',
    reason: 'Incidencia Courier',
    view: 'Incidencia Courier',
    responsability: 'Seller/OPL'
  },
  {
    id: 12,
    type: 'Reenvío',
    reason: 'Entrega Fallida',
    view: 'Entrega Fallida',
    responsability: 'Seller/OPL'
  }
]

export const typeList = {
  sale: 'Venta',
  return: 'Devolución',
  cancellation: 'Cancelación',
  forwarding: 'Reenvío'
}

export const statusList = {
  notDispatched: 'No Despachado',
  dispatched: 'Despachado',
  joined: 'Ingresado',
  rejected: 'Rechazado',
  returned: 'Devuelto',
  cancelled: 'Cancelado'
}
