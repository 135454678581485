import styled from 'styled-components'
import CardWithTitleAndButton from '../../../molecules/CardWithTitleAndButton/CardWithTitleAndButton'
import SkeletonText from '../../../atomics/SkeletonText'
import { useRef, useState } from 'react'
import Modal from '../../../atomics/Modal/Modal'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import CancelButton from '../../../atomics/Button/CancelButton'
import SubmitButton from '../../../atomics/Button/SubmitButton/SubmitButton'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import fetchSellersUpdate from '../../../../../domain/features/sellers/sellerUpdate/fetchSellersUpdate'
import { deliverySchema } from '../../../../../domain/schemas/sellerUpdateSchema'
import { useEffect } from 'react'
import { getSellerUpdate } from '../../../../../domain/features/sellers/sellerUpdate/getSellersUpdate'
import CardWithTitle from '../../../molecules/CardWithTitle/CardWithTitle'
import { CardButton } from '../seller.components'
import { resetUpdateSlice } from '../../../../../domain/features/sellers/sellerUpdate/sellerUpdateSlice'
import SellerDeliveryInformationForm from '../../SellerDeliveryInformationForm'

const List = styled('ul')`
  padding: 0px;
  margin: 0px;
  list-style-type: none;
  width: 100%;
  display: inline-block;
`
const ListItem = styled('li')`
  padding: 0px;
  margin: 0px;
  margin-bottom: 10px;
  font-size: 15px;
`
const TitleItem = styled('label')``
const BodyItem = styled('div')`
  font-weight: bold;
  clear: both;
  padding-top: 0.5rem;
`
const ButtonContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 95%;
  margin-top: 4px;
  margin-right: 4%;
`

const ButtonCancel = styled(CancelButton)`
  background: #ffffff;
  border: 1px solid #6b6b6b;
  border-radius: 5px;
  color: #6b6b6b;
  box-shadow: none;
`
const ApiKey = styled('p')`
  font-weight: bold;
  max-width: 600px;
  word-wrap: break-word;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  -o-hyphens: auto;
  hyphens: auto;
`
const SellerCard = ({
  seller,
  slugSeller,
  statusSeller,
  onUpdate,
  role
}) => {
  const prevStatus = useRef(null)
  const dispatch = useDispatch()
  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting }
  } = useForm({
    resolver: yupResolver(deliverySchema),
    mode: 'all'
  })

  useEffect(() => {
    dispatch(resetUpdateSlice())
  }, [dispatch])

  const { status, seller: sellerEdited } = useSelector(getSellerUpdate)

  const [visibleModal, setVisibleModal] = useState(false)

  const openModal = () => {
    reset({
      idSellerEnviame: seller.idSellerEnviame,
      apiKey: seller.apiKey,
      nameSeller: seller.nameSeller,
      bussinesName: seller.bussinesName === '-' ? '' : seller.bussinesName,
      giro: seller.giro === '-' ? '' : seller.giro,
      rut: seller.rut,
      email: seller.email,
      phoneNumber: seller.phoneNumber === '-' ? '' : seller.phoneNumber
    })
    setVisibleModal(true)
  }
  const closeModal = () => {
    setVisibleModal(false)
  }
  const onSubmit = handleSubmit(
    useCallback(
      data => {
        dispatch(fetchSellersUpdate({ ...data, slugSeller }))
      },
      [dispatch, slugSeller]
    )
  )
  useEffect(() => {
    if (status === 'finished' && prevStatus.current !== 'finished') {
      onUpdate(
        sellerEdited?.slugSeller !== slugSeller
          ? sellerEdited?.slugSeller
          : undefined
      )
      setVisibleModal(false)
    }
    prevStatus.current = status
  }, [status, onUpdate, sellerEdited?.slugSeller, slugSeller])

  return (
    <>
      <Modal open={visibleModal} onClose={closeModal}>
        <CardWithTitle title="Editar información de envío">
          <SellerDeliveryInformationForm control={control} />
          <ButtonContainer>
            <ButtonCancel onClick={closeModal}>Cancelar</ButtonCancel>
            <SubmitButton
              color={'#65B8D6'}
              onClick={onSubmit}
              disabled={role === 'viewer'}
              isLoading={isSubmitting || status === 'loading'}
            >
              Guardar cambios
            </SubmitButton>
          </ButtonContainer>
        </CardWithTitle>
      </Modal>
      <CardWithTitleAndButton
        title={'Gestión de envíos'}
        render={
          statusSeller === 'finished' &&
          ['admin', 'operations'].includes(role)
            ? () => (
                <>
                  <CardButton
                    title="Editar datos de envío"
                    onClick={openModal}
                  />
                </>
              )
            : null
        }
      >
        <List>
          <ListItem>
            <TitleItem>ID de Seller en Enviame</TitleItem>:
            <BodyItem>
              <SkeletonText text={seller?.idSellerEnviame} />
            </BodyItem>
          </ListItem>
          <div> </div>
          <ListItem>
            <TitleItem>API Key de Seller Center</TitleItem>:{' '}
            {seller?.apiKey && <ApiKey>{seller?.apiKey}</ApiKey>}
          </ListItem>
        </List>
      </CardWithTitleAndButton>
    </>
  )
}

export default SellerCard
