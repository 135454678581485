import PropTypes from 'prop-types'
import { useEffect, useCallback } from 'react'
import isEmpty from 'lodash/isEmpty'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { useTransition, animated, useSpring } from '@react-spring/web'

import Button from '@material-ui/core/Button'
import Collapse from '@material-ui/core/Collapse'

import sessionPersistentStore from '../../../../data/sessionPersistentStore'

import dateFormat from '../../../../domain/adpaters/days/dateFormat'
import {
  removeAllSeller,
  removeOneSeller
} from '../../../../domain/features/sellers/getSellers'
import {
  removeAllAccountingStatement,
  removeOneAccountingStatement
} from '../../../../domain/features/ui/accountingStatementSlice'
import {
  removeAllStatus,
  removeOneStatus
} from '../../../../domain/features/ui/dispatchedSlice'
import { resetRangeOfDaysPayout } from '../../../../domain/features/ui/rangeOfDaysPayoutSlice'
import { resetRangeOfDays } from '../../../../domain/features/ui/rangeOfDaysSlice'
import {
  removeAllType,
  removeOneType
} from '../../../../domain/features/ui/typeSlice'
import {
  removeAllreason,
  removeOnereason
} from '../../../../domain/features/ui/reasonSlice'
import {
  removeAllDocument,
  removeOneDocument
} from '../../../../domain/features/ui/documentSlice'
import Chip from '../../atomics/Chip'
import {
  addChip as add,
  allchips,
  removeAllChips,
  removeOneChip
} from '../../../../domain/features/ui/chipsSlice'

const StyledChip = styled(animated(Chip))`
  display: inline-flex;
  margin-left: 5px;
  margin-right: 5px;
  border-color: #f5f5f5;
  background-color: ${({ theme }) =>
    theme.palette.type === 'dark' ? '#058060' : '#eeeeee'};
`

const Content = styled('div')`
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  padding: 1rem;
  padding-top: 20px;
  @media (max-width: 700px) {
    padding-left: 10px;
  }
`

const DeleteAll = styled(animated(Button))`
  text-decoration: underline;
  color: rgba(47, 113, 209, 1);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
`

const ChipManager = ({
  date,
  seller,
  payoutDate,
  type,
  reason,
  status,
  accountingStatement,
  category,
  brand,
  productStatus,
  document,
  setBrand,
  setStatus,
  setCategory
}) => {
  const dispatch = useDispatch()

  const chipList = useSelector(allchips)

  const deleteAll = () => {
    removeDate()
    removePayoutDate()
    removeAllAccountingStatementAction()
    removeAllDocumentAction()
    removeAllStatusAction()
    removeAllTypeAction()
    removeAllSellerAction()
    removeAllBrand()
    removeAllCategory()
    removeAllProductStatus()
    removeAllreason()
    dispatch(removeAllChips())
  }

  const addChips = useCallback(
    (name, label, deleteAction) => {
      const oneChip = {
        label: `${name}: ${label}`,
        onDelete: deleteAction,
        value: label
      }
      return dispatch(add(oneChip))
    },
    [dispatch]
  )

  const onDelete = chip => () => {
    dispatch(removeOneChip(chip.label))
    chip.onDelete()
  }

  const removeDate = () => {
    dispatch(resetRangeOfDays())
  }
  const removeType = type => () => {
    dispatch(removeOneType(type))
  }
  const removeReason = reason => () => {
    dispatch(removeOnereason(reason))
  }
  const removeStatus = status => () => {
    dispatch(removeOneStatus(status))
  }

  const removeAccountingStatement = status => () => {
    dispatch(removeOneAccountingStatement(status))
  }
  const removeDocument = status => () => {
    dispatch(removeOneDocument(status))
  }
  const removeSeller = seller => () => {
    dispatch(removeOneSeller(seller))
  }

  const removeAllTypeAction = () => {
    dispatch(removeAllType())
  }

  const removeAllStatusAction = () => {
    dispatch(removeAllStatus())
  }

  const removeAllAccountingStatementAction = () => {
    dispatch(removeAllAccountingStatement())
  }
  const removeAllDocumentAction = () => {
    dispatch(removeAllDocument())
  }
  const removeAllSellerAction = () => {
    dispatch(removeAllSeller())
  }
  const removePayoutDate = () => {
    dispatch(resetRangeOfDaysPayout())
  }

  const removeBrand = br => () => {
    setBrand(brands => brands.filter(b => br !== b))
  }
  const removeCategory = ca => () => {
    setCategory(cat => cat.filter(c => ca !== c))
  }
  const removeProductStatus = st => () => {
    setStatus(sta => sta.filter(s => st !== s))
  }

  const removeAllBrand = () => {
    setBrand([])
    sessionPersistentStore?.setBrandSelect([])
  }
  const removeAllCategory = () => {
    setCategory([])
    sessionPersistentStore?.setCategorySelect([])
  }
  const removeAllProductStatus = () => {
    setStatus([])
    sessionPersistentStore?.setStateFilter([])
  }
  useEffect(() => {
    dispatch(removeAllChips())
    if (date?.length > 0 && date[1] !== undefined && date[1] !== null)
      addChips(
        'Fecha',
        `${date[0] ? dateFormat(date[0]) : 'Inicio'} - ${dateFormat(
          date[1]
        )}`,
        removeDate
      )
    status?.map(s => addChips('Estado', s, removeStatus(s)))
    productStatus?.map(s => addChips('Estado', s, removeProductStatus(s)))
    seller?.map(t => addChips('Seller', t, removeSeller(t)))
    type?.map(t => addChips('Tipo', t, removeType(t)))
    reason?.map(r => addChips('Motivo postventa', r, removeReason(r)))

    accountingStatement?.forEach(s => {
      if (s) {
        addChips('Estado contable', s, removeAccountingStatement(s))
      }
    })
    if (
      payoutDate?.length > 0 &&
      payoutDate[1] !== undefined &&
      payoutDate[1] !== null
    )
      addChips(
        'Fecha Liquidación',
        `${dateFormat(payoutDate[0])} - ${dateFormat(payoutDate[1])}`,
        removePayoutDate
      )
    document?.map(s =>
      addChips('Documento tributario', s, removeDocument(s))
    )
    brand?.map(s => addChips('Marca', s, removeBrand(s)))
    category?.map(s => addChips('Categoría', s, removeCategory(s)))
    // eslint-disable-next-line
  }, [
    date,
    seller,
    type,
    status,
    accountingStatement,
    payoutDate,
    category,
    brand,
    productStatus,
    document,
    reason
  ])

  const transitions = useTransition(chipList, {
    key: ({ label }) => label,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 400 },
    exitBeforeEnter: true,
    native: true
  })

  const fade = useSpring({
    to: { opacity: isEmpty(chipList) ? 0 : 1 },
    from: { opacity: 0 },
    config: { duration: 500 },
    native: true
  })

  return (
    <Collapse in={chipList.length > 0}>
      <Content>
        {transitions((springs, item) => (
          <StyledChip
            key={item.label}
            label={item.label}
            onDelete={onDelete(item)}
            style={springs}
          />
        ))}

        <DeleteAll
          style={fade}
          onClick={deleteAll}
          disabled={isEmpty(chipList)}
        >
          Borrar Filtros
        </DeleteAll>
      </Content>
    </Collapse>
  )
}

ChipManager.propTypes = {
  setBrand: PropTypes.func,
  setStatus: PropTypes.func,
  setCategory: PropTypes.func
}
ChipManager.defaultProps = {
  setBrand: () => {},
  setStatus: () => {},
  setCategory: () => {}
}
export default ChipManager
