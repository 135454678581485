import { createAsyncThunk } from '@reduxjs/toolkit'
import dateApiFormat from '../../adpaters/days/dateApiFormat'
import axios from 'axios'
import subOrderService from '../../../data/services/subOrderService'
import dateOperations from '../../adpaters/days/dateOperations'

const fetchPayoutDownload = createAsyncThunk(
  'payouts/export',
  async (
    { startDate, endDate, sellerList, detailed },
    { signal, rejectWithValue }
  ) => {
    try {
      const source = axios.CancelToken.source()
      signal.addEventListener('abort', () => {
        source.cancel()
      })
      const response = await subOrderService.exportLiquidations({
        startDate: startDate ? dateApiFormat(startDate) : undefined,
        endDate: endDate ? dateApiFormat(endDate) : undefined,
        sellers: sellerList?.join(','),
        detailed
      })

      if (!response) {
        return rejectWithValue(
          `Descargar lista de liquidaciones falló. ${response?.message}`
        )
      }

      if (response) {
        const resJson = await response
        const blob = resJson.file

        if (blob) {
          const newBlob = new Blob([new Uint8Array(resJson.file.data)])

          const blobUrl = window.URL.createObjectURL(newBlob)

          const link = document.createElement('a')
          link.href = blobUrl
          const title = startDate
            ? `Payouts del ${dateOperations(
                startDate
              )} al ${dateOperations(endDate)}`
            : `Payouts al ${dateOperations(endDate)}`
          link.setAttribute('download', `${title}.xlsx`)
          document.body.appendChild(link)
          link.click()
          link.remove()
          window.URL.revokeObjectURL(newBlob)
        }

        return resJson
      } else {
        throw new Error('file not found')
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export default fetchPayoutDownload
