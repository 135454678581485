import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { getProducts } from '../../../../domain/features/productSearchApi/getProducts'

import SearchInput from '../../molecules/SearchInput'

const SearchProduct = ({ query, setQuery, ...props }) => {
  const { status } = useSelector(getProducts)

  const handleChange = useCallback(
    event => {
      setQuery(event.target.value)
    },
    [setQuery]
  )
  const handleSubmit = useCallback(() => {
    setQuery(query)
  }, [setQuery, query])

  return (
    <SearchInput
      {...props}
      data-testid="ProductSearchInput"
      id="search"
      value={query}
      error={status === 'error'}
      onChange={handleChange}
      onClick={handleSubmit}
    />
  )
}
SearchProduct.propTypes = {
  query: PropTypes.string,
  setQuery: PropTypes.func
}
export default SearchProduct
