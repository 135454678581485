import React from 'react'
import styled, { css } from 'styled-components'
import { useLocation, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import isNaN from 'lodash/isNaN'
import isString from 'lodash/isString'

import Paper from '@material-ui/core/Paper'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import Button from '@material-ui/core/Button'
import ErrorIcon from '@material-ui/icons/Error'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import Grid from '@material-ui/core/Grid'
import Collapse from '@material-ui/core/Collapse'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import fechOrderDetail from '../../../domain/features/orderDetail/fetchOrderDetail'
import {
  afterSaleOrderList,
  currentOrderDetail,
  getOrderDetail
} from '../../../domain/features/orderDetail/getOrderDetail'
import { statusColorMapper } from '../../../domain/adpaters/map/statusColorMapper'
import dateFormat from '../../../domain/adpaters/days/dateFormat'
import fetchProductListBySku from '../../../domain/features/productListBySku/fetchProductListBySku'
import { getProductListBySku } from '../../../domain/features/productListBySku/getProductListBySku'
import tableDateFormat from '../../../domain/adpaters/days/dateFormat'

import CardAfterSale from '../organims/CardAfterSale/CardAfterSale'
import ModalAfterSale from '../organims/ModalAfterSale'
import CardBillingOrder from '../organims/CardBillingOrder/CardBillingOrder'
import SelectUpdateStatus from '../organims/SelectUpdateStatus'
import Tooltip from '../atomics/Tooltip'
import Navigate from '../atomics/Navigate'
import Title from '../atomics/Typography/Title'
import Text from '../atomics/Typography/Text'
import Select from '../atomics/Select/Select'
import image from '../../assets/image/placeholderImage.png'
import Alert from '../atomics/Alert/Alert'
import Tag from '../atomics/Tag'
import { resetEditValidateStatus } from '../../../domain/features/orderUpdateStatus/orderUpdateStatusSlice'
import { useAuth } from '../pages/auth/useAuth'

import ButtonPreviewProduct from '../atomics/ButtonPreviewProduct/ButtonPreviewProduct'
import ButtonEditProduct from '../atomics/ButtonEditProduct/ButtonEditProduct'
import { getColorCode } from '../atomics/FieldColorAutocomplete/colors'

const Circle = styled.div`
  position: absolute;
  top: 1rem;
  left: 1rem;
  width: 15px;
  height: 15px;
  background-color: ${props => props.color || 'transparent'};
  border-radius: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #313234;
  border: ${props =>
    props.color === 'Sin color' || props.color === 'Blanco'
      ? '1px solid #313234'
      : 'transparent'};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  cursor: help;
  &:hover {
    opacity: 1;
    visibility: visible;
    box-shadow: 0 4px 8px rgba(0.1, 0.1, 0.1, 0.4);
  }
`

const ImageContainer = styled.div`
  position: relative;
  display: inline-block;
  &:hover ${Circle} {
    opacity: 1;
    visibility: visible;
  }
  @media (max-width: 780px) {
    width: 100%;
  }
`

const LeftColumn = styled('div')`
  display: flex;
  flex-direction: column;
  @media (max-width: 780px) {
    width: 100%;
  }
`

const IconContainer = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-top: 10px;
  @media (max-width: 700px) {
    width: 100%;
    margin-top: 10%;
    margin-bottom: 10%;
    align-items: center;
    justify-content: space-between;
  }
`

const SelectOrder = styled(Select)`
  background-color: ${({ theme }) =>
    theme.palette.type === 'dark' ? '#313234' : '#ffffff'};
  border: 1px solid #b7b7b7;
  box-sizing: border-box;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  text-align: center;
  width: 208px;
  display: flex;
  ${props =>
    props.disabled
      ? css`
          cursor: not-allowed;
        `
      : css`
          cursor: pointer;
        `};
  @media (max-width: 1100px) {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    margin-left: 1rem;
    width: 100%;
    padding-left: 0px;
  }
`

const WarningIcon = styled(ErrorIcon)`
  margin-right: 0.5rem;
  fill: ${({ theme }) => theme.palette.warning.main};
`

const SuccessIcon = styled(CheckCircleIcon)`
  margin-right: 0.5rem;
  fill: #92e6b5;
`

const ContainerMainData = styled('div')`
  margin-left: 26px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  @media (max-width: 700px) {
    margin-left: 0px;
  }
`

const Container = styled('div')`
  margin-top: 2px;
  border-radius: 8px;
  margin-bottom: 1rem;
  background-color: ${({ theme }) =>
    theme.palette.type === 'dark' ? '#202223' : '#f4f5f4'};
`
const Header = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  @media (max-width: 820px) {
    flex-direction: column;
    align-items: flex-start;
  }
  @media (max-width: 1240px) and (min-width: 1100px) {
    flex-direction: column;
    align-items: flex-start;
  }
`
const HeaderTitle = styled(Header)`
  margin-top: 3rem;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1100px) {
    flex-wrap: wrap;
  }
  @media (max-width: 700px) {
    margin-top: 1rem;
  }
`

const TitleSeller = styled(Title)`
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0.2px;
`

const TitleSellerId = styled(Title)`
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 32px;
  letter-spacing: 0.2px;
`

const TextDate = styled(Text)`
  font-weight: 300;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  font-size: 14px;
`

const ItemCard = styled(Paper)`
  padding-top: 16px;
  padding-left: 50px;
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin-bottom: 1rem;
  box-shadow: 0px 0.836312px 3.34525px rgba(33, 33, 33, 0.25);
  box-sizing: border-box;
  border-radius: 8px;
  @media (max-width: 1100px) {
    padding-left: 1rem;
  }
`
const StatusContainer = styled('div')`
  display: flex;
  gap: 10px;
  align-items: center;
`

const TitleProduct = styled(Text)`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
`

const TitleStatus = styled(Text)`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
`
const Divider = styled('div')`
  height: 15px;
`

const TextProduct = styled(Text)`
  font-size: 13px;
  line-height: 32px;
  letter-spacing: 0.2px;
  display: flex;
  flex-direction: row;
  align-items: center;
`
const TextProductBold = styled(Text)`
  font-weight: 600;
  margin-left: 0.3rem;
`

const ItemContainer = styled('div')`
  display: flex;
  flex-direction: row;
  padding-right: 3rem;
  margin-bottom: 1rem;
  @media (max-width: 1100px) {
    padding-right: 1rem;

    flex-direction: column;
  }
`

const ItemContainerTotal = styled(ItemContainer)`
  margin-top: 2rem;
  flex-direction: column;
  align-items: flex-end;
`

const ItemContainerTotalLeft = styled(ItemContainerTotal)`
  align-items: flex-start;
`

const ItemCardTotal = styled(ItemCard)`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`

const ButtonBack = styled(Button)`
  border: 1px solid #c1c1c1;
  margin: 0px;
  padding-left: 5px;
  padding-right: 5px;
  margin-right: 1rem;
  min-width: 15px;
  @media (max-width: 1100px) {
    margin-right: 0.5rem;
    margin-left: 1rem;
  }
`

const Image = styled('img')`
  display: block;
  width: 150px;
  height: auto;
  margin-right: 3rem;
  object-fit: contain;
  @media (max-width: 1100px) {
    margin-right: 1%;
  }
  @media (max-width: 700px) {
    width: 100%;
    height: auto;
  }
`

const StyledTag = styled(Tag)`
  width: 150px;
  height: 30px;
  display: flex;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  padding-top: 0px;
`

const Column = styled('div')`
  margin-left: 5%;
  @media (max-width: 1100px) {
    margin-left: 15px;
  }
`
const ContainerColumn = styled('div')`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  width: 100%;
`

const TagContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  width: 100%;
  @media (max-width: 700px) {
    margin-bottom: 3rem;
    flex-wrap: wrap;
    align-items: baseline;
    justify-content: flex-start;
  }
`

const ItemContainerPrincipal = styled('div')`
  width: 100%;
`

const MessageInfo = styled(Paper)`
  box-shadow: 0px 0.836312px 3.34525px rgba(33, 33, 33, 0.25);
  box-sizing: border-box;
  border-radius: 8px;
  @media (max-width: 1100px) {
    padding-left: 1rem;
  }
  padding: 1.5rem;
  font-weight: bold;
  color: #c58f42;
  line-height: 18px;
  font-size: 15px;
  border-left: 10px solid;
`

const typeText = x =>
  new Map([
    ['finished', `Carga exitosa para pedido ${x}`],
    ['error', `Error de carga para pedido ${x}`],
    ['loading', `Cargando post ventas para pedido ${x}`]
  ])

const typeAlert = new Map([
  ['finished', 'success'],
  ['error', 'error'],
  ['loading', 'info']
])

const SelectOrderDetail = props => {
  const { currentUser: user } = useAuth()
  const isInvalid = String(user?.role)?.toLowerCase() === 'viewer'
  return (
    <SelectOrder
      id="add-post-order"
      label="Ingresar solicitud"
      handleDelete={() => undefined}
      disabled={props.disabled || isInvalid}
      {...props}
      list={['Devolución', 'Cancelación', 'Reenvío']}
      click
    />
  )
}

const OrderDetail = () => {
  const params = useParams()
  const { state } = useLocation()
  const dispatch = useDispatch()

  const { currentUser: user } = useAuth()

  React.useEffect(() => {
    const promise = dispatch(fechOrderDetail({ parentId: params.id }))
    return () => {
      promise.abort()
    }
  }, [dispatch, params.id])

  const { status } = useSelector(getOrderDetail)

  const orderDetail = useSelector(currentOrderDetail(params?.id))

  const detail = React.useMemo(() => orderDetail?.pop(), [orderDetail])

  const itemsOrderDetail = React.useMemo(() => {
    if (status === 'finished' && !isEmpty(detail?.id)) {
      return [...(detail?.itemList || [])]
    }

    if (!isEmpty(state) && state !== undefined) {
      return [...(state?.itemList || [])]
    }

    return []
  }, [state, detail, status])

  const listSku = React.useMemo(
    () => itemsOrderDetail?.map(({ sku }) => String(sku)),
    [itemsOrderDetail]
  )

  React.useEffect(() => {
    const promise = dispatch(fetchProductListBySku({ listSku }))
    return () => {
      promise.abort()
    }
  }, [dispatch, listSku])

  const orderWithAfterSale = useSelector(afterSaleOrderList(params?.id))

  const [orderType, setOrderType] = React.useState('')

  const [open, setOpen] = React.useState(false)

  const handleClose = () => {
    setOrderType('')
    setOpen(false)
    return 'backdropClick'
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const [openAlert, setOpenAlert] = React.useState(false)

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpenAlert(false)
  }
  React.useEffect(
    () => (status !== 'idle' ? setOpenAlert(true) : null),
    [status]
  )
  React.useEffect(() => {
    dispatch(resetEditValidateStatus())
  }, [dispatch])

  const { products } = useSelector(getProductListBySku)
  const getProductBySku = React.useCallback(
    key => products?.find(({ sku }) => String(sku) === String(key)),
    [products]
  )

  const statusBase = React.useMemo(
    () => (detail?.status ? detail?.status : state?.isDispatched),
    [detail?.status, state?.isDispatched]
  )
  const accountingStatement = React.useMemo(
    () =>
      detail?.accountingStatement
        ? detail?.accountingStatement
        : state?.accountingStatement,
    [detail?.accountingStatement, state?.accountingStatement]
  )

  const isObjectIdMongo = /^(?=[a-f\d]{24}$)(\d+[a-f]|[a-f]+\d)/i

  const matches = useMediaQuery('(max-width:700px)')

  return (
    <>
      <ModalAfterSale
        open={open}
        onClose={handleClose}
        onSubmit={handleClose}
        title={`Post Venta - ${orderType}`}
        headerText="Ingrese los datos"
        fieldArray={itemsOrderDetail.map(
          ({ id, name, quantity, price }) => ({
            value: id,
            name,
            quantity,
            price
          })
        )}
        id={params?.id}
        orderType={orderType}
      />
      <Container>
        <HeaderTitle>
          <ContainerMainData>
            <Navigate
              to={state?.isOperation ? '/operaciones' : '/pedidos'}
            >
              <ButtonBack color="inherit">
                <ArrowBackIcon />
              </ButtonBack>
            </Navigate>
            <div>
              <Navigate to={`/sellers/${detail?.slugSeller}`}>
                <TitleSeller>
                  {state?.sellerName || detail?.sellerName}
                </TitleSeller>
              </Navigate>

              <TitleSellerId>{detail?.id || state?.id}</TitleSellerId>
              <TextDate>{detail?.date || state?.date}</TextDate>
            </div>
          </ContainerMainData>
          <TagContainer>
            {status === 'loading' ? null : (
              <SelectUpdateStatus
                value={statusBase}
                suborderId={detail?.id}
                isDispatched={
                  !(
                    statusBase === 'No Despachado' &&
                    detail?.type === 'Venta' &&
                    detail?.accountingStatement === 'No Liquidado' &&
                    user?.role !== 'viewer'
                  )
                }
              />
            )}
            <Tooltip title="Estado contable">
              <StyledTag
                status={statusColorMapper.get(accountingStatement)}
              >
                {accountingStatement}
              </StyledTag>
            </Tooltip>
            <SelectOrderDetail
              value={orderType}
              onChange={e => {
                setOrderType(e.target.value)
                handleOpen()
              }}
            />
          </TagContainer>
        </HeaderTitle>
        {detail && !detail?.documentName ? (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={8}>
              <MessageInfo>
                Recuerda que debes adjuntar el documento tributario
                electrónico correspondiente
              </MessageInfo>
            </Grid>
          </Grid>
        ) : null}
        <Grid container spacing={3}>
          <Grid item xs={12} sm={8}>
            {itemsOrderDetail.map(product => {
              const priceTotal = product?.price * product?.quantity
              const range = String(product?.rangeDate).split(' - ')
              const rangeDate =
                dateFormat(range[0]) + ' - ' + dateFormat(range[1])
              const productBySku = product.sku
                ? getProductBySku(product?.sku)
                : {}

              return (
                <ItemCard key={product?.name}>
                  <ItemContainer>
                    <LeftColumn>
                      <ImageContainer>
                        <Image
                          src={
                            product?.image ||
                            productBySku?.image ||
                            productBySku?.product?.featuredImage?.url ||
                            image
                          }
                          alt={
                            product?.name ||
                            productBySku?.alt ||
                            productBySku?.product?.featuredImage
                              ?.altText ||
                            'sin imagen'
                          }
                        />{' '}
                        {isString(productBySku?.color) && (
                          <Tooltip
                            followCursor={true}
                            title={`Color ${productBySku?.color}`}
                          >
                            <Circle
                              color={getColorCode(productBySku?.color)}
                            />
                          </Tooltip>
                        )}
                      </ImageContainer>
                      <Collapse
                        in={isString(
                          productBySku?.onlineStorePreviewUrl ||
                            productBySku?.product?.onlineStorePreviewUrl
                        )}
                      >
                        <IconContainer>
                          {productBySku?.to && (
                            <ButtonEditProduct
                              fontSize={!matches ? 'small' : 'large'}
                              to={productBySku?.to}
                              title={'Editar producto'}
                            />
                          )}
                          {productBySku?.onlineStorePreviewUrl &&
                            !productBySku?.onlineStoreUrl && (
                              <ButtonPreviewProduct
                                fontSize={!matches ? 'small' : 'large'}
                                title="Vista previa del producto"
                                to={productBySku?.onlineStorePreviewUrl}
                              />
                            )}
                          {productBySku?.onlineStoreUrl && (
                            <ButtonPreviewProduct
                              fontSize={!matches ? 'small' : 'large'}
                              isProduction={true}
                              title="Ver producto en kitchencenter.cl"
                              to={productBySku?.onlineStoreUrl}
                            />
                          )}
                        </IconContainer>
                      </Collapse>{' '}
                    </LeftColumn>
                    <ItemContainerPrincipal>
                      <Header>
                        <StatusContainer>
                          {product?.isDispatched ? (
                            <SuccessIcon />
                          ) : (
                            <WarningIcon />
                          )}
                          <TitleStatus>
                            {product?.isDispatched
                              ? 'Despachado'
                              : 'No despachado'}{' '}
                            ({product?.quantity})
                          </TitleStatus>
                        </StatusContainer>

                        <ContainerColumn>
                          <Column>
                            <TitleProduct>
                              CLP {product?.price?.toLocaleString('es-CL')}{' '}
                              x {product?.quantity}
                            </TitleProduct>
                          </Column>
                          <Column>
                            <TitleProduct>
                              CLP {priceTotal?.toLocaleString('es-CL')}
                            </TitleProduct>
                          </Column>
                        </ContainerColumn>
                      </Header>
                      {productBySku?.to ? (
                        <Navigate to={productBySku?.to}>
                          <TitleProduct>{product?.name}</TitleProduct>
                        </Navigate>
                      ) : (
                        <TitleProduct>{product?.name}</TitleProduct>
                      )}
                      {product?.sku && (
                        <>
                          <TextProduct>
                            SKU: {product?.sku}{' '}
                            {product?.ownSku || productBySku?.ownSku
                              ? ` | SKU Propio ${
                                  product?.ownSku || productBySku?.ownSku
                                }`
                              : null}
                          </TextProduct>
                          <Divider />
                        </>
                      )}
                      <TextProduct>
                        Marca:
                        <TextProductBold>
                          {isObjectIdMongo.test(product?.brand)
                            ? detail?.brandName || state?.brandName
                            : product?.brand}
                        </TextProductBold>
                      </TextProduct>
                      <TextProduct>
                        Fecha de preparación:
                        <TextProductBold>
                          {tableDateFormat(
                            String(product?.fullfillmentDate)?.substr(
                              0,
                              10
                            )
                          )}
                        </TextProductBold>
                      </TextProduct>
                      <TextProduct>
                        Fecha de entrega:
                        <TextProductBold>
                          {dateFormat(
                            product?.deliveryDate?.substr(0, 10)
                          )}
                        </TextProductBold>
                      </TextProduct>
                      {product?.rangeDate && (
                        <TextProduct>
                          Rango de entrega:
                          <TextProductBold>{rangeDate}</TextProductBold>
                        </TextProduct>
                      )}
                      <TextProduct>
                        Transporte:
                        <TextProductBold>
                          CLP{' '}
                          {new Intl.NumberFormat('es-CL')?.format(
                            product?.shippingPrice || 0
                          )}
                        </TextProductBold>
                      </TextProduct>
                      <TextProduct>
                        Peso:
                        <TextProductBold>
                          {product?.grams} (g)
                        </TextProductBold>
                      </TextProduct>
                      {product?.commissionPercentage &&
                        !isNaN(product?.commissionPercentage) && (
                          <TextProduct>
                            Comisión:
                            <TextProductBold>
                              {Math.trunc(
                                product?.commissionPercentage * 100
                              )}
                              %
                            </TextProductBold>
                          </TextProduct>
                        )}
                    </ItemContainerPrincipal>
                  </ItemContainer>
                </ItemCard>
              )
            })}

            <ItemCardTotal>
              <ItemContainerTotalLeft>
                <TitleProduct>Venta</TitleProduct>
                <TitleProduct>Venta despachada</TitleProduct>
                <TitleProduct>Comisión</TitleProduct>
                <TitleProduct>Payout</TitleProduct>
              </ItemContainerTotalLeft>
              <ItemContainerTotal>
                <TitleProduct>
                  CLP{' '}
                  {detail?.totalSale?.toLocaleString('es-CL') ||
                    state?.totalSale?.toLocaleString('es-CL')}
                </TitleProduct>
                <TitleProduct>
                  CLP{' '}
                  {detail?.dispatchedSale?.toLocaleString('es-CL') ||
                    state?.dispatchedSale?.toLocaleString('es-CL')}
                </TitleProduct>
                <TitleProduct>
                  CLP{' '}
                  {detail?.commission?.toLocaleString('es-CL') ||
                    state?.commission?.toLocaleString('es-CL')}
                </TitleProduct>
                <TitleProduct>
                  CLP{' '}
                  {detail?.payout?.toLocaleString('es-CL') ||
                    state?.payout?.toLocaleString('es-CL')}
                </TitleProduct>
              </ItemContainerTotal>
            </ItemCardTotal>
          </Grid>

          {detail && (
            <Grid item xs={12} sm={4}>
              <CardBillingOrder
                detail={detail || state}
                isLoading={status === 'loading'}
                role={user?.role}
              />{' '}
            </Grid>
          )}
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={8}>
            {orderWithAfterSale &&
              orderWithAfterSale
                ?.filter(({ type }) => type !== 'Venta')
                .map(afterSale => (
                  <CardAfterSale
                    key={afterSale.id}
                    getProductBySku={getProductBySku}
                    {...afterSale}
                  />
                ))}
          </Grid>
        </Grid>
      </Container>
      <Alert
        open={openAlert}
        onClose={handleCloseAlert}
        title={typeText(params.id).get(status)}
        isLoading={status === 'loading'}
        severity={typeAlert.get(status)}
        isError={status === 'error'}
      />
    </>
  )
}

export default OrderDetail
