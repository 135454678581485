import React from 'react'
import styled from 'styled-components'
import { statusColorMapper } from '../../../../domain/adpaters/map/statusColorMapper'
import Tag from '../Tag'
import CellTooltip from './CellBase'

const CellStatusColor = styled(CellTooltip)``

const TagTable = styled(Tag)`
  height: 20px;
  width: 100%;
  font-size: 13px;
  white-space: nowrap;
  padding-bottom: 0.15rem;
  padding-left: 0.5rem;
  margin-left: 0px;
  margin-right: 10px;
  @media (max-width: 1100px) {
    margin-top: auto;
  }
`

const CellStatus = ({ text, ...props }) => {
  const textProduct = new Map([
    ['ACTIVE', 'Aprobado'],
    ['DRAFT', 'Pendiente aprobación'],
    ['ARCHIVED', 'Deshabilitado']
  ]).get(text)

  return (
    <CellStatusColor {...props}>
      <TagTable status={statusColorMapper.get(text)}>
        {textProduct ? textProduct : text}
      </TagTable>
    </CellStatusColor>
  )
}

export default CellStatus
