import capitalizeName from '../../utils/capitalizeName'

/**
 * Normalizes the delivery information for an order.
 *
 * @param {Object} params - The order parameters.
 * @param {Object} params.destination - The delivery destination information.
 * @param {string} params.destination.destination_county - The county for the delivery destination.
 * @param {string} params.destination.destination_region - The region for the delivery destination.
 * @param {string} params.destination.destination_address1 - The first line of the delivery address.
 * @param {string} [params.destination.destination_address2] - The second line of the delivery address (optional).
 * @param {Object} params.customer - The customer information.
 * @param {string} params.customer.customer_name - The name of the customer.
 * @param {string} params.customer.customer_rut - The RUT (tax identification number) of the customer.
 *
 * @returns {Object} The normalized delivery information.
 */
const normalizeOrderDelivery = ({
  destination: {
    destination_county,
    destination_region,
    destination_address1 = '',
    destination_address2 = ''
  } = {},
  customer: { customer_name, customer_rut } = {}
}) => ({
  name: capitalizeName(customer_name),
  rut: customer_rut,
  address: capitalizeName(
    `${destination_address1}${
      destination_address2 ? `, ${destination_address2}` : ''
    }`
  ),
  destination_county: capitalizeName(destination_county),
  destination_region: capitalizeName(destination_region)
})

export default normalizeOrderDelivery
