import axios from 'axios'
import map from 'lodash/fp/map'
import pipe from 'lodash/fp/pipe'
import dayjs from 'dayjs'
import { createAsyncThunk } from '@reduxjs/toolkit'

import liquidationService from '../../../data/services/liquidationService'
import normalizeLiquidations from './normalizeLiquidations'
import deleteUndefinedEntries from '../../utils/deleteUndefinedEntries'

const fetchLiquidations = createAsyncThunk(
  'liquidations/filter',
  async (
    {
      startDate,
      endDate,
      accountingStatement,
      skip,
      limit = 25,
      sellerList = []
    },
    { signal, rejectWithValue }
  ) => {
    const source = axios.CancelToken.source()
    signal.addEventListener('abort', () => {
      source.cancel()
    })
    if (limit === -1) {
      limit = 0
    }
    const { data, pagination } = await liquidationService.filter(
      deleteUndefinedEntries({
        startDate:
          startDate && String(startDate).toLowerCase() !== 'invalid date'
            ? dayjs(startDate).startOf('day').format()
            : undefined,
        endDate:
          endDate && String(endDate).toLowerCase() !== 'invalid date'
            ? dayjs(endDate).endOf('day').format()
            : undefined,
        accountingStatement: accountingStatement?.join(','),
        sellers: sellerList?.join(','),
        skip: limit < 0 ? undefined : skip,
        limit,
        cancelToken: source.token
      })
    )

    const liquidationList = pipe(map(normalizeLiquidations))(data)
    if (Array(...liquidationList).length === 0) {
      return rejectWithValue('Sin contenido')
    }
    return { liquidationList, pagination }
  }
)
export default fetchLiquidations
