import React from 'react'
import {
  Routes as AppRoutes,
  BrowserRouter,
  Route,
  Navigate
} from 'react-router-dom'

import Layout from '../styleSheet/View/Layout'
import LayoutCenter from '../styleSheet/View/LayoutCenter'

import Paylouts from '../components/pages/Payouts'
import Orders from '../components/pages/Orders'
import OrderDetail from '../components/pages/OrderDetail'
import Liquidations from '../components/pages/Liquidations'
import Login from '../components/pages/auth/Login'
import Error404 from '../components/pages/errors/Error404'
import Products from '../components/pages/Products'
import ProductDetails from '../components/pages/ProductDetails'
import AddProduct from '../components/pages/AddProduct'
import AdminSellers from '../components/pages/AdminSellers'
import SellerCreate from '../components/pages/SellerCreate'
import Campaigns from '../components/pages/Campaigns'
import CreateCampaigns from '../components/pages/CreateCampaigns'
import Operations from '../components/pages/Operations'
import SellerDetails from '../components/pages/SellerDetails'
import AuthWrapper from '../components/pages/auth/AurthWrapper'
import useRefreshToken from '../hooks/useRefreshToken'
import { useAuth } from '../components/pages/auth/useAuth'

const Routers = () => {
  useRefreshToken()
  const { currentUser } = useAuth()

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL || '/'}>
      <AppRoutes>
        <Route element={<LayoutCenter />}>
          <Route path="ingresar" element={<Login />} />
          <Route
            path="*"
            element={
              currentUser !== null ? (
                <Navigate to="/pedidos" />
              ) : (
                <Error404 />
              )
            }
          />
        </Route>
        <Route
          element={
            <AuthWrapper>
              <Layout />
            </AuthWrapper>
          }
        >
          <Route path="pedidos" element={<Orders />} />
          <Route path="pedidos/:id" element={<OrderDetail />} />
          <Route path="liquidaciones" element={<Liquidations />} />{' '}
          <Route path="payouts" element={<Paylouts />} />{' '}
          <Route path="productos" element={<Products />} />
          <Route path="productos/nuevo" element={<AddProduct />} />
          <Route path="productos/:id" element={<ProductDetails />} />
          <Route path="sellers" element={<AdminSellers />} />
          <Route path="sellers/nuevo" element={<SellerCreate />} />
          <Route path="sellers/:slugSeller" element={<SellerDetails />} />
          <Route path="campanas" element={<Campaigns />} />
          <Route path="campanas/nuevo" element={<CreateCampaigns />} />
          <Route path="operaciones" element={<Operations />} />
        </Route>
      </AppRoutes>
    </BrowserRouter>
  )
}
export default Routers
