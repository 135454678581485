import PropTypes from 'prop-types'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import isArray from 'lodash/isArray'
import { Controller, useWatch } from 'react-hook-form'
import CheckboxImage from '../../atomics/CheckboxImage'

export default function CheckboxesMedia({
  items,
  name,
  control,
  defaultValue,
  isVariantModalOpened
}) {
  const mediaForVariant = useWatch({
    control,
    name
  })
  const [checkedValues, setCheckedValues] = useState(
    isArray(mediaForVariant) ? mediaForVariant : []
  )

  function handleSelect(checkedId) {
    const newIds = checkedValues?.includes(checkedId)
      ? checkedValues?.filter(name => name !== checkedId)
      : [...(checkedValues ?? []), checkedId]
    setCheckedValues(newIds)
    return newIds
  }

  return (
    <>
      <Controller
        name={name}
        defaultValue={defaultValue}
        render={({ field: { onChange, ref }, formState: { errors } }) =>
          items?.map((item, index) => (
            <FormControlLabel
              key={item.id}
              control={
                <CheckboxImage
                  index={index}
                  key={item?.name}
                  disabled={
                    errors?.media && !isEmpty(errors?.media[index])
                  }
                  inputRef={ref}
                  src={
                    item?.preview?.image?.url
                      ? item?.preview?.image?.url
                      : item?.src
                  }
                  onChange={() => {
                    onChange(
                      handleSelect(
                        item?.preview ? item.preview?.image?.url : item.alt
                      )
                    )
                  }}
                  checked={checkedValues.includes(
                    item?.preview ? item.preview?.image?.url : item.alt
                  )}
                  alt={
                    item?.preview
                      ? item.preview?.image?.altText
                      : item?.alt || item?.name
                  }
                  size="large"
                  isVariantModalOpened={isVariantModalOpened}
                />
              }
            />
          ))
        }
        control={control}
      />
    </>
  )
}
CheckboxesMedia.propTypes = {
  items: PropTypes.array,
  name: PropTypes.string,
  control: PropTypes.any,
  defaultValue: PropTypes.any,
  isVariantModalOpened: PropTypes.bool
}
