import Menu from '@material-ui/core/Menu'
import React from 'react'
import styled from 'styled-components'

const DropdownListStyled = styled(Menu)`
  margin-top: 73px;
`

const Dropdown = props => {
  return (
    <DropdownListStyled
      elevation={4}
      anchorReference="anchorEl"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      {...props}
    />
  )
}

export default Dropdown
