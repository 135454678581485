import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import afterSaleService from '../../../data/services/afterSaleService'
import fechOrderDetail from '../orderDetail/fetchOrderDetail'

const fetchUpdateAfterSale = createAsyncThunk(
  'afterSale/update',
  async (
    { id, type, status, parentId },
    { rejectWithValue, signal, dispatch }
  ) => {
    const source = axios.CancelToken.source()
    signal.addEventListener('abort', () => {
      source.cancel()
    })
    try {
      const data = await afterSaleService.update({
        id,
        type,
        status,
        cancelToken: source.token
      })

      if (data?.isAxiosError) {
        return rejectWithValue({ message: data.response.data.message })
      }

      dispatch(fechOrderDetail({ parentId }))

      return data
    } catch (error) {
      return rejectWithValue({ error })
    }
  }
)
export default fetchUpdateAfterSale
