import React from 'react'
import PropTypes from 'prop-types'
import Card from '../../atomics/CardProduct'
import Title from '../../atomics/Typography/TitleProduct'
import styled from 'styled-components'

const StyledTitle = styled(Title)`
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.2px;
`
const StyledCard = styled(Card)`
  width: 100%;
`

const CardWithTitle = ({ title, children, ...props }) => {
  return (
    <StyledCard {...props}>
      {title && <StyledTitle>{title}</StyledTitle>}
      {children}
    </StyledCard>
  )
}
CardWithTitle.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node
}
export default CardWithTitle
