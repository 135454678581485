import { fetchCommissionsDecoded } from '../client/fetchDecoded'
import persistentStore from '../persistentStore'

const campaignService = (baseUrl, endpoint) => ({
  campaignList: async ({ cancelToken }) => {
    try {
      const data = await fetchCommissionsDecoded(
        `${baseUrl}/${endpoint}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            authorization: persistentStore.getToken(),
            app: process.env.REACT_APP_APPLICATION_NAME
          },
          cancelToken
        }
      )
      return data
    } catch (error) {
      throw error
    }
  }
})

export default campaignService(
  process.env.REACT_APP_API_COMMISIONS_PATH,
  'campaign'
)
