import React from 'react'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import styled from 'styled-components'
import Tooltip from '@material-ui/core/Tooltip'
import IconPencil from '../../atomics/Icon/IconPencil'

const StyledIconButton = styled(IconButton)`
  svg path {
    fill: ${({ theme }) =>
      theme.palette.type === 'dark' ? '#FFFFFF' : '#7C7C7C'};
    color: ${({ theme }) =>
      theme.palette.type === 'dark' ? '#FFFFFF' : '#7C7C7C'};
  }

  &:hover {
    svg path {
      fill: ${({ theme }) => theme.palette.warning.light || '#65B8D6'};
      color: ${({ theme }) => theme.palette.warning.light || '#65B8D6'};
    }
  }

  &:disabled {
    cursor: not-allowed;
    pointer-events: auto;
  }

  .Mui-disabled {
    cursor: not-allowed;
    pointer-events: auto;
  }
`

const ButtonWithPincel = ({ color, title, ...props }) => (
  <Tooltip title={title}>
    <StyledIconButton {...props}>
      <IconPencil />
    </StyledIconButton>
  </Tooltip>
)

ButtonWithPincel.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string
}
ButtonWithPincel.defaultProps = {
  color: '#7C7C7C'
}

const CardButton = React.memo(ButtonWithPincel)
CardButton.displayName = 'CardButton'
export { CardButton }
