import startCase from 'lodash/startCase'
import toLower from 'lodash/toLower'

export const colors = [
  { label: 'Sin color', code: 'transparent' },
  { label: 'Blanco', code: '#FFFFFF' },
  { label: 'Negro', code: '#000000' },
  { label: 'Gris', code: '#808080' },
  { label: 'Rojo', code: '#FF0000' },
  { label: 'Verde', code: '#008000' },
  { label: 'Azul', code: '#0000FF' },
  { label: 'Amarillo', code: '#FFFF00' },
  { label: 'Naranja', code: '#FFA500' },
  { label: 'Púrpura', code: '#800080' },
  { label: 'Rosa', code: '#FFC0CB' },
  { label: 'Marrón', code: '#A52A2A' },
  { label: 'Beige', code: '#F5F5DC' },
  { label: 'Cian', code: '#00FFFF' },
  { label: 'Magenta', code: '#FF00FF' },
  { label: 'Turquesa', code: '#40E0D0' },
  { label: 'Lavanda', code: '#E6E6FA' },
  { label: 'Lima', code: '#00FF00' },
  { label: 'Oro', code: '#FFF700' },
  { label: 'Plata', code: '#C0C0C0' },
  { label: 'Inox', code: '#B0B0B0' },
  { label: 'Bronce', code: '#CD7F32' },
  { label: 'Coral', code: '#FF7F50' },
  { label: 'Caqui', code: '#F0E68C' },
  { label: 'Cobre', code: '#B87333' },
  { label: 'Azul Marino', code: '#000080' },
  { label: 'Verde Oliva', code: '#808000' },
  { label: 'Verde Lima', code: '#32CD32' },
  { label: 'Chocolate', code: '#D2691E' },
  { label: 'Esmeralda', code: '#50C878' },
  { label: 'Salmón', code: '#FA8072' },
  { label: 'Carmesí', code: '#DC143C' },
  { label: 'Aguamarina', code: '#7FFFD4' },
  { label: 'Azul Cielo', code: '#87CEEB' },
  { label: 'Verde Claro', code: '#90EE90' },
  { label: 'Rojo Oscuro', code: '#8B0000' },
  { label: 'Azul Oscuro', code: '#00008B' },
  { label: 'Verde Oscuro', code: '#006400' },
  { label: 'Naranja Oscuro', code: '#FF8C00' },
  { label: 'Dorado', code: '#FFD700' },
  { label: 'Perla', code: '#EAE0C8' },
  { label: 'Rubí', code: '#E0115F' },
  { label: 'Zafiro', code: '#0F52BA' },
  { label: 'Topacio', code: '#FFC87C' },
  { label: 'Marfil', code: '#FFFFF0' },
  { label: 'Jade', code: '#00A86B' },
  { label: 'Celeste', code: '#B2FFFF' },
  { label: 'Avena', code: '#DAD7C7' },
  { label: 'Arena', code: '#C2B280' },
  { label: 'Gris Claro', code: '#D3D3D3' },
  { label: 'Acero', code: '#4682B4' },
  { label: 'Azul Acero', code: '#4682B4' },
  { label: 'Verde Lima', code: '#32CD32' },
  { label: 'Azul Verdoso', code: '#008080' },
  { label: 'Rojo Indian', code: '#CD5C5C' },
  { label: 'Azul Cobalto', code: '#0047AB' },
  { label: 'Verde Menta', code: '#98FF98' },
  { label: 'Rojo Escarlata', code: '#FF2400' },
  { label: 'Naranja Pastel', code: '#FFA07A' },
  { label: 'Azul Klein', code: '#002FA7' },
  { label: 'Verde Pistacho', code: '#93C572' },
  { label: 'Rojo Caramelo', code: '#FF6347' },
  { label: 'Amarillo Mostaza', code: '#FFDB58' },
  { label: 'Azul Eléctrico', code: '#7DF9FF' },
  { label: 'Verde Neón', code: '#39FF14' },
  { label: 'Rojo Ferrari', code: '#FF2800' },
  { label: 'Amarillo Neón', code: '#FFFF33' },
  { label: 'Azul Denim', code: '#1560BD' },
  { label: 'Verde Pastel', code: '#77DD77' },
  { label: 'Rojo Granate', code: '#800000' },
  { label: 'Amarillo Canario', code: '#FFEF00' },
  { label: 'Azul Pizarra', code: '#6A5ACD' },
  { label: 'Verde Pálido', code: '#98FB98' },
  { label: 'Rojo Ciruela', code: '#DDA0DD' },
  { label: 'Amarillo Pálido', code: '#FFFFE0' },
  { label: 'Azul Persa', code: '#1C39BB' },
  { label: 'Verde Primavera', code: '#00FF7F' },
  { label: 'Rojo Oxido', code: '#8A3324' },
  { label: 'Amarillo Claro', code: '#FFFACD' },
  { label: 'Azul Real', code: '#4169E1' },
  { label: 'Verde Mar', code: '#2E8B57' },
  { label: 'Rojo Terroso', code: '#4B0082' },
  { label: 'Amarillo Dorado', code: '#FFD720' },
  { label: 'Azul Ultramar', code: '#3F00FF' },
  { label: 'Rojo Tomate', code: '#FF6347' },
  { label: 'Amarillo Ámbar', code: '#FFBF00' },
  { label: 'Azul Zafiro', code: '#0F52BA' },
  { label: 'Verde Esmeralda', code: '#50C878' },
  { label: 'Rojo Frambuesa', code: '#E30B5C' },
  { label: 'Amarillo Maíz', code: '#FBEC5D' },
  { label: 'Verde Manzana', code: '#8DB600' },
  { label: 'Rojo Sangría', code: '#92000A' },
  { label: 'Amarillo Banana', code: '#FFE135' },
  { label: 'Rojo Coral', code: '#FF4040' },
  { label: 'Amarillo Limón', code: '#FFF44F' },
  { label: 'Verde Bosque', code: '#228B22' },
  { label: 'Rojo Tinto', code: '#722F37' },
  { label: 'Amarillo Nápoles', code: '#FADA5E' },
  { label: 'Azul Claro', code: '#ADD8E6' },
  { label: 'Verde Turquesa', code: '#40E0D0' },
  { label: 'Madera', code: '#8B4513' },
  { label: 'Grafito', code: '#2F4F4F' },
  { label: 'Mármol', code: '#E5E4E2' },
  { label: 'Gris Oscuro', code: '#A9A9A9' },
  { label: 'Plástico', code: '#E0E0E0' },
  { label: 'Burdeos', code: '#800000' },
  { label: 'Vino', code: '#722F37' },
  { label: 'Gris Mate', code: '#737373' },
  { label: 'Roble', code: '#B5651D' },
  { label: 'Nogal', code: '#6F4E37' },
  { label: 'Cerezo', code: '#D2691E' },
  { label: 'Arce', code: '#D2B48C' },
  { label: 'Pino', code: '#DEB887' },
  { label: 'Caoba', code: '#C04000' },
  { label: 'Teca', code: '#996633' },
  { label: 'Haya', code: '#F5F5DC' },
  { label: 'Abedul', code: '#FFE4C4' },
  { label: 'Olmo', code: '#A0522D' },
  { label: 'Girasol', code: '#FFD700' },
  { label: 'Lavanda', code: '#E6E6FA' },
  { label: 'Violeta', code: '#8A2BE2' },
  { label: 'Lirio', code: '#FFB6C1' },
  { label: 'Hortensia', code: '#6495ED' },
  { label: 'Margarita', code: '#FFFFFF' },
  { label: 'Crisantemo', code: '#FF4530' },
  { label: 'Orquídea', code: '#DA70D6' },
  { label: 'Jazmín', code: '#FFFACD' },
  { label: 'Clavel', code: '#FF69B4' },
  { label: 'Peonía', code: '#FF6EB4' },
  { label: 'Gerbera', code: '#FF4510' },
  { label: 'Amapola', code: '#FF0000' },
  { label: 'Naranja Crepúsculo', code: '#FF4520' },
  { label: 'Rosa Pastel', code: '#FFB6C1' },
  { label: 'Púrpura Atardecer', code: '#800880' },
  { label: 'Azul Crepúsculo', code: '#191970' },
  { label: 'Rojo Fuego', code: '#FF4800' },
  { label: 'Verde Primavera', code: '#00FF7F' },
  { label: 'Rosa Flor', code: '#FF69B4' },
  { label: 'Amarillo Pastel', code: '#FFFACD' },
  { label: 'Naranja Solar', code: '#FFA510' },
  { label: 'Azul Cielo', code: '#87CEEB' },
  { label: 'Marrón Tierra', code: '#8B4513' },
  { label: 'Turquesa', code: '#40E0D0' },
  { label: 'Naranja Otoñal', code: '#FF8C00' },
  { label: 'Rojo Cobrizo', code: '#B22222' },
  { label: 'Blanco Nieve', code: '#FFFFFF' },
  { label: 'Gris Invierno', code: '#A9A9A9' },
  { label: 'Azul Hielo', code: '#ADD8E6' }
]

const capitalizeWords = str => {
  return startCase(toLower(str))
}

export const getColorCode = label => {
  const color = colors.find(c => c.label === capitalizeWords(label))
  return color ? color.code : null
}
