import React from 'react'
import styled from 'styled-components'
import CardContainaer from '../../molecules/CardWithTitle'
import RowStyled from '../../atomics/Styles/Row'
import FieldInputController from '../../molecules/FieldInputController'

const Card = styled(CardContainaer)`
  justify-content: space-between;
`
const FieldContainer = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 1.4rem;
`
const Left = styled(FieldContainer)`
  @media (max-width: 1100px) {
    width: 100%;
  }
`
const Right = styled(FieldContainer)`
  margin-left: 2%;
  @media (max-width: 1100px) {
    width: 100%;
  }
`
const Row = styled(RowStyled)`
  width: 100%;
`

const CampaignGeneralInformationForm = ({ control, title }) => {
  return (
    <Card title={title}>
      <Row>
        <Left>
          <FieldInputController
            required
            name="nameCampaign"
            label="Nombre Campaña"
            control={control}
          />
        </Left>
      </Row>
      <Row>
        <Left>
          <FieldContainer>
            <FieldInputController
              required
              name="startDate"
              label="Fecha inicio"
              type="date"
              placeholder="Desde"
              helperText="Comienza a las 00:00 hs. del primer día."
              control={control}
            />
          </FieldContainer>
        </Left>
        <Right>
          <FieldContainer>
            <FieldInputController
              required
              name="endDate"
              label="Fecha término"
              type="date"
              placeholder="Desde"
              helperText="Finaliza a las 23:59 hs. del último día."
              control={control}
            />
          </FieldContainer>
        </Right>
      </Row>
    </Card>
  )
}

export default CampaignGeneralInformationForm
