import { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import FieldAutoComplete from '../FieldAutoComplete'
import Label from '../../atomics/Label'
import fetchBrands from '../../../../domain/features/brandsSlugSeller/fetchBrands'
import { getBrands } from '../../../../domain/features/brandsSlugSeller/getBrands'
import { useWatch } from 'react-hook-form'
import fetchMultipleBrands from '../../../../domain/features/brandsSlugSeller/fetchMultipleBrands'

const FieldBrand = ({ control, defaultValue, setValue, clearErrors }) => {
  const dispatch = useDispatch()
  useEffect(() => {
    const promise = dispatch(fetchMultipleBrands())
    return () => promise.abort()
  }, [dispatch])
  useEffect(() => {
    const promise = dispatch(fetchBrands())
    return () => promise.abort()
  }, [dispatch])
  const { brandOnlyNameList, status, allBrands } = useSelector(getBrands)

  const sellerWatch = useWatch({
    name: 'seller',
    control
  })

  const brandWatch = useWatch({
    name: 'brand',
    control
  })

  const brandsMemo = useMemo(() => {
    return allBrands
  }, [allBrands])

  useEffect(() => {
    if (!sellerWatch) {
      return
    }
    clearErrors(['brand'])
    setValue(
      'brand',
      brandsMemo
        .filter(brand => brand.seller.nameSeller === sellerWatch)
        .filter(brand => brand.brandName === brandWatch).length < 1
        ? brandsMemo
            .filter(brand => brand.seller.nameSeller === sellerWatch)
            .map(brand => brand.brandName)[0]
        : brandsMemo
            .filter(brand => brand.seller.nameSeller === sellerWatch)
            .filter(brand => brand.brandName === brandWatch)
            .map(brand => brand.brandName)[0]
    )
  }, [
    brandWatch,
    brandsMemo,
    clearErrors,
    defaultValue,
    sellerWatch,
    setValue
  ])
  const isObjectIdMongo = /^(?=[a-f\d]{24}$)(\d+[a-f]|[a-f]+\d)/i
  return (
    <>
      <Label required>Marca</Label>
      <FieldAutoComplete
        control={control}
        options={
          sellerWatch
            ? brandsMemo
                .filter(brand => brand.seller.nameSeller === sellerWatch)
                .map(brand => brand.brandName)
                .filter(brand => !isObjectIdMongo.test(brand))
            : brandOnlyNameList.filter(
                brand => !isObjectIdMongo.test(brand)
              )
        }
        defaultValue={defaultValue}
        name="brand"
        placeholder="Selecciona una marca"
        disabled={status === 'loading'}
        loading={status === 'loading'}
        loadingText="Cargando Marcas..."
      />
    </>
  )
}

FieldBrand.propTypes = {
  control: PropTypes.any,
  defaultValue: PropTypes.any,
  seller: PropTypes.string,
  setValue: PropTypes.func,
  clearErrors: PropTypes.func
}
export default FieldBrand
