import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'

import Paper from '@material-ui/core/Paper'
import Skeleton from '@material-ui/lab/Skeleton'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import fechOrderBilling from '../../../../domain/features/orderBilling/fetchOrderBilling'
import { getOrderBilling } from '../../../../domain/features/orderBilling/getOrderBilling'

import Text from '../../atomics/Typography/Text'
import LoadDocument from '../LoadDocument'
import Alert, { typeAlert } from '../../atomics/Alert/Alert'
import {
  deleteDocumentList,
  deleteIdle,
  downloadIdle,
  getDeleteDocument,
  getDownloadDocument,
  getUpdateDocument,
  updateDocumentList
} from '../../../../domain/features/orders/orderSlice'
import { deleteDocument } from '../../../../domain/features/orderDetail/orderDetailSlice'
import capitalizeName from '../../../../domain/utils/capitalizeName'
import ButtonCopy from '../../atomics/ButtonCopy/ButtonCopy'

const TextNormal = styled('p')`
  font-size: 14px;
  line-height: 30px;
  font-weight: 300;
  letter-spacing: 0.2px;
  padding: 0px;
  margin: 0px;
`
const TextUnselected = styled(TextNormal)`
  color: #d1d0d0;
  font-weight: bold;
  font-size: 17px;
`
const TextNormalSemiBold = styled('span')`
  font-weight: 400;
`

const TitleProduct = styled(Text)`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
`
const TitleProductBold = styled(TitleProduct)`
  font-weight: bold;
`
const TitleProductBoldItalic = styled(TitleProductBold)`
  font-style: italic;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
`

const ItemCard = styled(Paper)`
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  margin-bottom: 1rem;
  box-shadow: 0px 0.836312px 3.34525px rgba(33, 33, 33, 0.25);
  box-sizing: border-box;
  border-radius: 8px;

  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'default')};
`

const TitleStatus = styled(Text)`
  font-style: normal;
  font-weight: bold;
  font-weight: 700;
  font-size: 23px;
  line-height: 23px;
  margin-bottom: 20px;
`

const Divider = styled('div')`
  margin-bottom: 1.2rem;
  margin-right: 2.5rem;
`

const ItemCardTribute = styled(ItemCard)``
const BillingType = styled('div')`
  display: grid;
  grid-template-columns: 30px 1fr;
  flex-direction: row;
  align-items: center;
`
const SuccessIcon = styled(CheckCircleIcon)`
  margin-right: 0.5rem;
  fill: ${({ theme }) => theme.palette.success.dark};
`
const ILoadDocument = styled('div')`
  cursor: pointer;
  float: left;
`
const ContainerDestintion = styled('div')`
  display: flex;
  flex: 1;
  justify-content: space-between;
  flex-direction: row;
  min-width: 100%;
`

const CardBillingOrder = ({ detail, isLoading, role }) => {
  const dispatch = useDispatch()
  const [showDocumentDownload, setShowDocumentDownload] = useState(false)
  const [showUpdateDocument, setShowUpdateDocument] = useState(false)

  const [showDeleteDocument, setShowDeleteDocument] = useState(false)

  const { status: updateDocumentStatus } = useSelector(getUpdateDocument)

  const { status: downloadDocumentStatus } = useSelector(
    getDownloadDocument
  )

  const { status: deleteDocumentStatus } = useSelector(getDeleteDocument)

  useEffect(() => {
    if (
      updateDocumentStatus === 'loading' ||
      updateDocumentStatus === 'error'
    ) {
      setShowUpdateDocument(true)
    } else if (updateDocumentStatus === 'finished') {
      dispatch(updateDocumentList(detail?.idOrder))
      setTimeout(() => {
        setShowUpdateDocument(false)
      }, 5000)
    } else {
      setShowUpdateDocument(false)
    }
    if (downloadDocumentStatus === 'loading') {
      setShowDocumentDownload(true)
    } else if (downloadDocumentStatus === 'finished') {
      setShowDocumentDownload(true)
      setTimeout(() => {
        dispatch(downloadIdle())
        setShowDocumentDownload(false)
      }, 5000)
    } else {
      setShowDocumentDownload(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateDocumentStatus, downloadDocumentStatus, dispatch])

  useEffect(() => {
    if (deleteDocumentStatus === 'loading') {
      setShowDeleteDocument(true)
    } else if (deleteDocumentStatus === 'finished') {
      dispatch(deleteDocument(detail?.idOrder))
      dispatch(deleteDocumentList(detail?.idOrder))
      setShowDeleteDocument(true)
      setTimeout(() => {
        setShowDeleteDocument(false)
        dispatch(deleteIdle())
      }, 5000)
    } else if (deleteDocumentStatus === 'error') {
      setTimeout(() => {
        setShowDeleteDocument(false)
      }, 5000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteDocumentStatus, dispatch])

  useEffect(() => {
    if (
      String(detail?.documentType).toLowerCase() === 'factura' ||
      isEmpty(detail?.documentType) ||
      isNil(detail?.documentType)
    ) {
      const promise = dispatch(fechOrderBilling({ id: detail?.order }))
      return () => {
        promise.abort()
      }
    }
  }, [dispatch, detail?.order, detail?.documentType])

  const { status, billing, delivery, documentType } =
    useSelector(getOrderBilling)

  if (status === 'error') {
    return null
  }

  if (status === 'loading' || isLoading) {
    return (
      <div>
        <Skeleton variant="rectangular" height={210} />
        <Divider />
        <Divider />
        <Skeleton variant="rectangular" height={300} />
      </div>
    )
  }

  const getCopyText = () => {
    return `${delivery?.address}, ${delivery?.destination_county}, ${delivery?.destination_region}`
  }

  const getCopyTextBilling = () => {
    return `
      Documento Tributario: ${
        documentType === 'factura' ? 'FACTURA' : 'BOLETA'
      }

      Nombre o razón social: ${billing?.name || '-'}
      Rut: ${billing?.rut || '-'}
      Giro: ${delivery?.giro || ''}
      Mail: ${billing?.mail || ''}
      Dirección de despacho:: ${billing?.address || '-'}
      Comuna: ${billing?.destination_county || '-'}
      Región: ${billing?.destination_region || '-'}

     ${billing?.address},${billing?.destination_county}, ${
       billing?.destination_region
     }
    `
  }

  return (
    <>
      <ItemCardTribute key={'Boleta'}>
        <TitleStatus>Documento Tributario</TitleStatus>
        <div>
          <BillingType>
            <SuccessIcon fontSize="small" />
            <TitleProductBold>
              {(detail?.documentType || documentType) === 'factura'
                ? 'FACTURA'
                : 'BOLETA'}
            </TitleProductBold>
          </BillingType>
          <BillingType>
            <div></div>
            <TextUnselected>
              {(detail?.documentType || documentType) === 'factura'
                ? 'BOLETA'
                : 'FACTURA'}
            </TextUnselected>
          </BillingType>

          <ILoadDocument>
            <LoadDocument
              value={detail?.documentName}
              name={detail?.idOrder}
              label={detail?.documentType}
              role={role}
              disabled={downloadDocumentStatus === 'loading'}
            />
          </ILoadDocument>
        </div>

        <Divider />
        {documentType === 'factura' && (
          <>
            <Divider />
            <ContainerDestintion>
              <TitleProductBoldItalic>
                Detalles de facturación
              </TitleProductBoldItalic>
              <ButtonCopy
                title="Copiar detalle de facturación"
                getText={getCopyTextBilling}
              />
            </ContainerDestintion>

            <TextNormal>
              Rut: <TextNormalSemiBold>{billing?.rut}</TextNormalSemiBold>
            </TextNormal>
            <TextNormal>
              Nombre o razón social:{' '}
              <TextNormalSemiBold>{billing?.name}</TextNormalSemiBold>
            </TextNormal>
            <TextNormal>
              Giro:{' '}
              <TextNormalSemiBold>{billing?.giro}</TextNormalSemiBold>
            </TextNormal>
            <TextNormal>
              Dirección tributaria:{' '}
              <TextNormalSemiBold>{billing?.address}</TextNormalSemiBold>
            </TextNormal>
            <TextNormal>
              Comuna:{' '}
              <TextNormalSemiBold>
                {billing?.destination_country}
              </TextNormalSemiBold>
            </TextNormal>
            <TextNormal>
              Región:{' '}
              <TextNormalSemiBold>
                {billing?.destination_region}
              </TextNormalSemiBold>
            </TextNormal>
            <TextNormal>
              Mail:{' '}
              <TextNormalSemiBold>{billing?.mail}</TextNormalSemiBold>
            </TextNormal>
          </>
        )}
      </ItemCardTribute>
      <ItemCardTribute>
        <ContainerDestintion>
          <TitleStatus>Detalles de entrega</TitleStatus>
          <ButtonCopy title="Copiar dirección" getText={getCopyText} />
        </ContainerDestintion>
        <TextNormal>
          Nombre:{' '}
          <TextNormalSemiBold>
            {capitalizeName(
              detail?.customer?.customerName || delivery?.name
            )}
          </TextNormalSemiBold>
        </TextNormal>
        <TextNormal>
          Rut:{' '}
          <TextNormalSemiBold>
            {detail?.customer?.customerRut || delivery?.rut}
          </TextNormalSemiBold>
        </TextNormal>
        <TextNormal>
          Dirección de despacho:{' '}
          <TextNormalSemiBold>
            {capitalizeName(
              detail?.destination?.destinationAddress1 +
                ', ' +
                detail?.destination?.destinationAddress2 ||
                delivery?.address
            )}
          </TextNormalSemiBold>
        </TextNormal>
        <TextNormal>
          Comuna:{' '}
          <TextNormalSemiBold>
            {detail?.destination?.destinationCounty ||
              delivery?.destinationCounty}
          </TextNormalSemiBold>
        </TextNormal>
        <TextNormal>
          Región:{' '}
          <TextNormalSemiBold>
            {detail?.destination?.destinationRegion ||
              delivery?.destinationRegion}
          </TextNormalSemiBold>
        </TextNormal>
      </ItemCardTribute>

      <Alert
        key="upload-document"
        open={
          showUpdateDocument &&
          (updateDocumentStatus === 'error' ||
            updateDocumentStatus === 'loading')
        }
        onClose={() => {
          setShowUpdateDocument(false)
        }}
        title={
          updateDocumentStatus === 'loading'
            ? 'subiendo archivo'
            : updateDocumentStatus === 'error'
              ? 'Se ha producido un error'
              : 'Archivo descargado exitosamente'
        }
        isLoading={updateDocumentStatus === 'loading'}
        isError={updateDocumentStatus === 'error'}
        severity={
          updateDocumentStatus === 'error'
            ? typeAlert.get('error')
            : typeAlert.get('success')
        }
      />

      <Alert
        key="download-document"
        open={
          showDocumentDownload &&
          (downloadDocumentStatus === 'finished' ||
            downloadDocumentStatus === 'error' ||
            downloadDocumentStatus === 'loading')
        }
        onClose={() => {
          dispatch(downloadIdle())
          setShowDocumentDownload(false)
        }}
        title={
          downloadDocumentStatus === 'error'
            ? 'Se ha producido un error'
            : downloadDocumentStatus === 'loading'
              ? 'Descargando archivo'
              : 'Archivo descargado exitosamente'
        }
        isLoading={downloadDocumentStatus === 'loading'}
        isError={downloadDocumentStatus === 'error'}
        severity={
          downloadDocumentStatus === 'error'
            ? typeAlert.get('error')
            : typeAlert.get('success')
        }
      />

      <Alert
        key="delete-document"
        open={
          showDeleteDocument &&
          (deleteDocumentStatus === 'finished' ||
            deleteDocumentStatus === 'error' ||
            deleteDocumentStatus === 'loading')
        }
        onClose={() => {
          dispatch(deleteIdle())
          setShowDeleteDocument(false)
        }}
        title={
          deleteDocumentStatus === 'error'
            ? 'Se ha producido un error'
            : deleteDocumentStatus === 'loading'
              ? 'Eliminando archivo'
              : deleteDocumentStatus === 'finished'
                ? 'Archivo eliminado exitosamente'
                : ''
        }
        isLoading={deleteDocumentStatus === 'loading'}
        isError={deleteDocumentStatus === 'error'}
        severity={
          deleteDocumentStatus === 'error'
            ? typeAlert.get('error')
            : typeAlert.get('success')
        }
      />
    </>
  )
}
CardBillingOrder.propTypes = {
  detail: PropTypes.any,
  isLoading: PropTypes.bool
}
export default CardBillingOrder
