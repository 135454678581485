import { createAsyncThunk } from '@reduxjs/toolkit'
import productApiService from '../../../data/services/productApiService'

const fetchUpdateProductStatusFromDetail = createAsyncThunk(
  'productsUpdate/status/from/detail',
  async ({ id, status }, { rejectWithValue }) => {
    try {
      const response = await productApiService.updateProductStatusById({
        id,
        status
      })
      const data = response['productUpdate']['product']
      const newData = {
        ...data,
        id: data.id
      }
      return newData
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
export default fetchUpdateProductStatusFromDetail
