import * as yup from 'yup'

export const schema = yup
  .object({
    nameCampaign: yup
      .string()
      .max(100, 'Ingresa un texto de menos de 100 caracteres')
      .required('Nombre de campaña es un campo requerido'),
    startDate: yup
      .date()
      .default(() => new Date())
      .required(),
    endDate: yup
      .date()
      .required()
      .min(
        yup.ref('startDate'),
        'Fecha término debe ser mayor a fecha inició'
      ),
    sellers: yup.array().of(
      yup.object().shape({
        seller: yup
          .string()
          .ensure()
          .required('Debes seleccionar una categoría'),
        commissionSeller: yup.number(),
        commisionPerCategory: yup.array().of(
          yup.object().shape({
            nameProductType: yup
              .string()
              .ensure()
              .required('Debes seleccionar una categoría'),
            commissionProductType: yup
              .number()
              .transform(value => (isNaN(value) ? undefined : value))
              .required('Ingresa un número entero entre 1 y 100 inclusive')
              .test(
                'len',
                'comisión marca debe ser un valor entre 1 y 100',
                val => {
                  return val || val === 0 ? val <= 100 && val > 0 : true
                }
              )
          })
        )
      })
    )
  })
  .required()
