import { useState, useEffect, useCallback } from 'react'
import isNil from 'lodash/isNil'

import * as serviceWorker from '../../serviceWorkerRegistration'

const useServiceWorker = () => {
  const [registerWorker, setRegisterWorker] = useState(null)
  const [isUpdateWorker, setUpdateWorker] = useState(false)
  const [isSuccessWorker, setSuccessWorker] = useState(false)
  const [waitingWorker, setWaitingWorker] = useState(null)

  const onSWUpdate = useCallback(async registration => {
    setUpdateWorker(true)
    setWaitingWorker(registration.waiting)
    setRegisterWorker(registration)
    // Detalles en: https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle
    if (registration && registration.waiting) {
      await registration.unregister()
      registration.waiting.postMessage({ type: 'SKIP_WAITING' })
      // Des-registramos el SW para recargar la página y obtener la nueva versión. Lo cual permite que el navegador descargue lo nuevo y que invalida la cache que tenía previamente.
      return window.location.reload()
    }
    if (registration && isNil(registration.waiting)) {
      registration?.registrationWorker?.update()
      return window.location.reload()
    }
  }, [])

  const onSWSuccess = useCallback(
    registration => {
      setSuccessWorker(true)
      setRegisterWorker(registration)
    },
    [setRegisterWorker]
  )

  useEffect(() => {
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.register({
      onUpdate: onSWUpdate,
      onSuccess: onSWSuccess
    })
  }, [onSWSuccess, onSWUpdate])

  const reloadPage = useCallback(async () => {
    setUpdateWorker(true)

    // We want to run this code only if we detect a new service worker is
    // waiting to be activated.
    // Details about it: https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle
    // lear more: https://github.com/facebook/create-react-app/issues/5316#issuecomment-591075209
    if (waitingWorker && registerWorker) {
      await registerWorker.unregister()
      // Makes Workbox call skipWaiting()
      waitingWorker.postMessage({
        type: 'SKIP_WAITING'
      })
      return window.location.reload()
    }

    if (isNil(waitingWorker) && registerWorker) {
      // eslint-disable-next-line no-unused-expressions
      registerWorker?.registrationWorker?.update()
      return window.location.reload()
    }
  }, [registerWorker, waitingWorker])

  return {
    isUpdateWorker,
    isSuccessWorker,
    reloadPage
  }
}

export default useServiceWorker
