import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import fetchProductCreate from './fetchProductCreate'

export const productCreateAdpater = createEntityAdapter()

export const productCreateSlice = createSlice({
  name: 'product/create',
  initialState: productCreateAdpater.getInitialState({
    status: 'idle',
    product: undefined
  }),
  reducers: {
    resetProductCreate(state) {
      state.status = 'idle'
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchProductCreate.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(fetchProductCreate.rejected, state => {
      state.status = 'error'
    })
    builder.addCase(fetchProductCreate.fulfilled, (state, { payload }) => {
      state.status = 'finished'
      state.product = payload
    })
  }
})
export const { resetProductCreate } = productCreateSlice.actions
const reducer = productCreateSlice.reducer
export default reducer
