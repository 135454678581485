import * as yup from 'yup'

export const schema = yup.object().shape({
  seller: yup.string().ensure().required('Seller es un campo requerido'),
  productName: yup
    .string()
    .max(100, 'Nombre producto debe contener entre 1 y 100 caracteres')
    .required('Nombre producto es un campo requerido'),
  category: yup
    .string()
    .ensure()
    .required('Categoría es un campo requerido'),
  brand: yup.string().ensure().required('Marca es un campo requerido'),
  detailedDescription: yup
    .string()
    .max(
      850,
      'Descripción detallada debe contener entre 1 y 850 caracteres'
    )
    .required('Descripción detallada es un campo requerido'),
  productHeight: yup
    .number()
    .transform(value => (isNaN(value) ? undefined : value))
    .typeError('El valor debe ser numérico')
    .min(0, 'El valor debe ser mayor o igual a 0'),
  productWidth: yup
    .number()
    .transform(value => (isNaN(value) ? undefined : value))
    .typeError('El valor debe ser numérico')
    .min(0, 'El valor debe ser mayor o igual a 0'),
  productDepth: yup
    .number()
    .transform(value => (isNaN(value) ? undefined : value))
    .typeError('El valor debe ser numérico')
    .min(0, 'El valor debe ser mayor o igual a 0'),
  productMaterial: yup
    .string()
    .required('Material es una valor requerido')
    .max(200, 'Material debe contener entre 1 y 200 caracteres'),
  productWarranty: yup
    .string()
    .required('Garantía es una valor requerido')
    .max(50, 'Garantía debe contener entre 1 y 50 caracteres'),
  specialFeatures: yup
    .string()
    .required('Caracteristicas especiales es una valor requerido')
    .max(
      1000,
      'Caracteristicas especiales debe contener entre 1 y 1000 caracteres'
    ),
  manufacturedCountry: yup
    .string()
    .required('País de fabricación es una valor requerido')
    .max(
      100,
      'País de fabricación debe contener entre 1 y 100 caracteres'
    ),
  productTension: yup
    .string()
    .max(25, 'Tensión debe contener entre 1 y 25 caracteres'),
  productInstalation: yup
    .string()
    .max(50, 'Instalación debe contener menos de 50 caracteres'),
  productAccesories: yup
    .string()
    .max(300, 'Accesorios debe contener menos de 300 caracteres'),
  productConsiderations: yup
    .string()
    .test(
      'len',
      'Consideraciones debe contener entre 1 y 1000 caracteres',
      val => (val ? val.length < 1000 : true)
    ),
  plug: yup
    .string()
    .test(
      'len',
      'Enchufe debe contener menos entre 1 y 50 caracteres',
      val => (val ? val.length < 50 : true)
    ),
  commands: yup
    .string()
    .test(
      'len',
      'Mandos debe contener menos entre 1 y 50 caracteres',
      val => (val ? val.length < 50 : true)
    ),
  power: yup
    .string()
    .test('len', 'Potencia debe contener entre 1 y 25 caracteres', val =>
      val ? val.length < 25 : true
    ),
  energyClass: yup
    .string()
    .test(
      'len',
      'Clase energética debe contener entre 1 y 25 caracteres',
      val => (val ? val.length < 25 : true)
    ),
  totalUsableCapacity: yup
    .string()
    .test(
      'len',
      'Capacidad útil total debe contener entre 1 y 10 caracteres',
      val => (val ? val.length < 10 : true)
    ),
  embeddedMeasurements: yup
    .string()
    .test(
      'len',
      'Medidas de encastre debe contener entre 1 y 100 caracteres',
      val => (val ? val.length < 100 : true)
    ),
  light: yup
    .string()
    .test('len', 'Luz debe contener entre 1 y 25 caracteres', val =>
      val ? val.length < 25 : true
    ),
  panel: yup
    .string()
    .test(
      'len',
      'Panel debe contener menos entre 1 y 50 caracteres',
      val => (val ? val.length < 50 : true)
    ),
  energyType: yup
    .string()
    .test(
      'len',
      'Tipo de energía debe contener entre 1 y 25 caracteres',
      val => (val ? val.length < 25 : true)
    ),
  speedsNumber: yup
    .string()
    .test(
      'len',
      'Cantidad de velocidades debe contener entre 1 y 10 caracteres',
      val => (val ? val.length < 10 : true)
    ),
  built: yup
    .string()
    .test('len', 'Armado debe contener entre 1 y 25 caracteres', val =>
      val ? val.length < 25 : true
    ),
  consume: yup
    .string()
    .test('len', 'Consumo debe contener entre 1 y 10 caracteres', val =>
      val ? val.length < 10 : true
    ),
  temperature: yup
    .string()
    .test('len', 'Luz debe contener entre 1 y 25 caracteres', val =>
      val ? val.length < 25 : true
    ),
  video: yup.string().url('ingrese un'),
  burnersHeatLevels: yup
    .string()
    .test(
      'len',
      'Niveles de calor quemadores debe contener entre 1 y 25 caracteres',
      val => (val ? val.length < 25 : true)
    ),
  countertopType: yup
    .string()
    .test(
      'len',
      'Tipo de encimera debe contener entre 1 y 25 caracteres',
      val => (val ? val.length < 25 : true)
    ),
  burnersNumber: yup
    .string()
    .test(
      'len',
      'Cantidad de quemadores debe contener menos de 20 caracteres',
      val => (val ? val.length < 10 : true)
    ),
  ovenType: yup
    .string()
    .test(
      'len',
      'Tipo de horno debe contener entre 1 y 25 caracteres',
      val => (val ? val.length < 25 : true)
    ),
  ovenProgramsNumber: yup
    .string()
    .test(
      'len',
      'Cantidad de programas horno debe contener entre 1 y 10 caracteres',
      val => (val ? val.length < 10 : true)
    ),
  maxSuction: yup
    .string()
    .test(
      'len',
      'Aspiración máxima debe contener entre 1 y 10 caracteres',
      val => (val ? val.length < 10 : true)
    ),
  bellType: yup
    .string()
    .test(
      'len',
      'Tipo de campana debe contener menos entre 1 y 50 caracteres',
      val => (val ? val.length < 50 : true)
    ),
  maxNoise: yup
    .string()
    .test(
      'len',
      'Ruido máximo debe contener entre 1 y 10 caracteres',
      val => (val ? val.length < 10 : true)
    ),
  grillType: yup
    .string()
    .test(
      'len',
      'Tipo de parrilla debe contener menos entre 1 y 50 caracteres',
      val => (val ? val.length < 50 : true)
    ),
  tubeDiameter: yup
    .string()
    .test(
      'len',
      'Diametro de tubo debe contener entre 1 y 10 caracteres',
      val => (val ? val.length < 10 : true)
    ),
  coldTechnology: yup
    .string()
    .test(
      'len',
      'Tecnología de frío debe contener entre 1 y 25 caracteres',
      val => (val ? val.length < 25 : true)
    ),
  refrigeratorType: yup
    .string()
    .test(
      'len',
      'Tipo de refrigerador debe contener entre 1 y 25 caracteres',
      val => (val ? val.length < 25 : true)
    ),
  freezerType: yup
    .string()
    .test(
      'len',
      'Tipo de freezer debe contener entre 1 y 25 caracteres',
      val => (val ? val.length < 25 : true)
    ),
  refrigeratorUsableCapacity: yup
    .string()
    .test(
      'len',
      'Capacidad útil de refrigerador debe contener entre 1 y 10 caracteres',
      val => (val ? val.length < 10 : true)
    ),
  freezerUsableCapacity: yup
    .string()
    .test(
      'len',
      'Capacidad útil freezer debe contener entre 1 y 10 caracteres',
      val => (val ? val.length < 10 : true)
    ),
  refrigerantGas: yup
    .string()
    .test(
      'len',
      'Gas refrigerante debe contener entre 1 y 10 caracteres',
      val => (val ? val.length < 10 : true)
    ),
  washingProgramsNumber: yup
    .string()
    .test(
      'len',
      'Cantidad de programas lavado debe contener entre 1 y 10 caracteres',
      val => (val ? val.length < 10 : true)
    ),
  dryProgramsNumber: yup
    .string()
    .test(
      'len',
      'Cantidad de programas secado debe contener entre 1 y 10 caracteres',
      val => (val ? val.length < 10 : true)
    ),
  loadType: yup
    .string()
    .test(
      'len',
      'Tipo de carga debe contener menos entre 1 y 50 caracteres',
      val => (val ? val.length < 50 : true)
    ),
  intensity: yup
    .string()
    .test(
      'len',
      'Intensidad debe contener menos entre 1 y 50 caracteres',
      val => (val ? val.length < 50 : true)
    ),
  security: yup
    .string()
    .test(
      'len',
      'Seguridad debe contener menos entre 1 y 50 caracteres',
      val => (val ? val.length < 50 : true)
    ),
  cableLength: yup
    .string()
    .test(
      'len',
      'Largo del cable debe contener menos entre 1 y 50 caracteres',
      val => (val ? val.length < 50 : true)
    ),
  tapType: yup
    .string()
    .test(
      'len',
      'Tipo de grifería debe contener menos entre 1 y 50 caracteres',
      val => (val ? val.length < 50 : true)
    ),
  washingMachineUsableCapacity: yup
    .string()
    .test(
      'len',
      'Capacidad útil lavadora debe contener menos entre 1 y 50 caracteres',
      val => (val ? val.length < 50 : true)
    ),
  dryerUsableCapacity: yup
    .string()
    .test(
      'len',
      'Capacidad útil Secadora debe contener menos entre 1 y 50 caracteres',
      val => (val ? val.length < 50 : true)
    ),
  functionType: yup
    .string()
    .test(
      'len',
      'Tipo de función debe contener menos entre 1 y 50 caracteres',
      val => (val ? val.length < 50 : true)
    ),
  bottlesQuantity: yup
    .string()
    .test(
      'len',
      'Cantidad de botellas debe contener menos entre 1 y 50 caracteres',
      val => (val ? val.length < 50 : true)
    ),
  manual: yup
    .mixed()
    .nullable()
    .test(
      'fileSize',
      'El archivo debe tener un peso menor a 20MB',
      value => {
        return (
          (value && value[0]?.size <= 20971520) ||
          typeof value === 'string' ||
          value == null
        )
      }
    )
    .test('type', 'Tu archivo debe estar en formato PDF', value => {
      return (
        (value && ['application/pdf'].includes(value[0]?.type)) ||
        typeof value === 'string' ||
        value == null
      )
    })
    .notRequired(),
  dataSheet: yup
    .mixed()
    .nullable()
    .test(
      'fileSize',
      'El archivo debe tener un peso menor a 20MB',
      value =>
        (value && value[0]?.size <= 20971520) ||
        typeof value === 'string' ||
        value == null
    )
    .test('type', 'Tu archivo debe estar en formato PDF', value => {
      return (
        (value && ['application/pdf'].includes(value[0]?.type)) ||
        typeof value === 'string' ||
        value == null
      )
    })
    .notRequired(),
  certificate: yup
    .mixed()
    .nullable()
    .test(
      'fileSize',
      'El archivo debe tener un peso menor a 20MB',
      value =>
        (value && value[0]?.size <= 20971520) ||
        typeof value === 'string' ||
        value == null
    )
    .test('type', 'Tu archivo debe estar en formato PNG o JPG', value => {
      return (
        (value && ['image/jpeg', 'image/png'].includes(value[0]?.type)) ||
        typeof value === 'string' ||
        value == null
      )
    })
    .notRequired(),
  efficiency: yup
    .mixed()
    .nullable()
    .test(
      'fileSize',
      'El archivo debe tener un peso menor a 20MB',
      value =>
        (value && value[0]?.size <= 20971520) ||
        typeof value === 'string' ||
        value == null
    )
    .test('type', 'Tu archivo debe estar en formato PNG o JPG', value => {
      return (
        (value && ['image/jpeg', 'image/png'].includes(value[0]?.type)) ||
        typeof value === 'string' ||
        value == null
      )
    })
    .notRequired(),
  inpage: yup
    .mixed()
    .nullable()
    .test(
      'fileSize',
      'El archivo debe tener un peso menor a 20MB',
      value =>
        (value && value[0]?.size <= 20971520) ||
        typeof value === 'string' ||
        value == null
    )
    .test('type', 'Tu archivo debe estar en formato PDF', value => {
      return (
        (value && ['application/pdf'].includes(value[0]?.type)) ||
        typeof value === 'string' ||
        value == null
      )
    })
    .notRequired(),
  descriptionImage: yup
    .mixed()
    .nullable()
    .test(
      'fileSize',
      'El archivo debe tener un peso menor a 20MB',
      value => {
        if (value && typeof value === 'string') {
          return value && value.includes('https://')
        }
        return value ? value[0]?.size <= 20971520 : true
      }
    )
    .test('type', 'Tu archivo debe estar en formato PNG o JPG', value => {
      if (value && typeof value === 'string') {
        return value && value.includes('https://')
      }
      return value
        ? ['image/jpeg', 'image/png'].includes(value[0]?.type)
        : true
    })
    .notRequired(),
  variants: yup.array().of(
    yup.object().shape({
      compareAtPrice: yup
        .number()
        .typeError('El precio normal debe ser un valor numérico')
        .integer('Colocar un valor numérico entero mayor o igual a 1000')
        .min(1000, 'Colocar un valor numérico entero mayor o igual a 1000')
        .required('Por favor, ingresé el precio normal'),
      price: yup
        .number()
        .nullable()
        .transform(value => (isNaN(value) ? undefined : value))
        .typeError('El precio oferta debe ser un valor numérico')
        .integer(
          'Colocar un valor numérico entero menor o igual al precio normal'
        )
        .min(1000, 'Colocar un valor numérico entero mayor o igual a 1000')
        .test(
          'compareAtPrice',
          'Precio oferta debe ser menor al precio normal y mayor o igual al 20% del precio normal',
          function (val) {
            const normalPrice = this.parent.compareAtPrice
            return (
              (val <= normalPrice && val >= normalPrice * 0.2) ||
              val === undefined ||
              val === null
            )
          }
        ),
      sku: yup.string(),
      color: yup
        .string()
        .max(25, 'Color debe contener entre 1 y 25 caracteres')
        .required('Por favor, ingresé el color'),
      inventoryQuantity: yup
        .number()
        .transform(value => (isNaN(value) ? undefined : value))
        .typeError('Colocar un valor numérico entero mayor o igual a 0')
        .integer('Colocar un valor numérico entero mayor o igual a 0')
        .min(0, 'Colocar un valor numérico entero mayor o igual a 0')
        .required('Por favor, ingresé la cantidad disponible'),
      weight: yup
        .number()
        .transform(value => (isNaN(value) ? undefined : value))
        .typeError('El peso debe ser un valor numérico')
        .min(
          0,
          'Colocar un valor numérico mayor a 0, indicando decimales con un punto'
        )
        .required(
          'Colocar un valor numérico mayor a 0, indicando decimales con un punto'
        ),
      mediaIds: yup.array().optional().nullable().notRequired()
    })
  ),
  media: yup.array()
})
