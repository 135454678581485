import React, { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { DevTool } from '@hookform/devtools'
import { yupResolver } from '@hookform/resolvers/yup'
import { schema } from '../../../domain/schemas/campaignCreateSchema'
import Center from '../atomics/Center'
import goBack from '../../assets/goBack.svg'
import SubmitButton from '../atomics/Button/SubmitButton/SubmitButton'
import fetchSellersCreate from '../../../domain/features/sellers/sellerCreate/fetchSellersCreate'
import { useNavigate } from 'react-router-dom'
import CancelButton from '../atomics/Button/CancelButton'
import styled from 'styled-components'
import Alert from '../atomics/Alert/Alert'
import { Button } from '@material-ui/core'
import CampaignGeneralInformationForm from '../organims/CampaingGeneralInformationForm'
import SellersCampaignCard from '../organims/SellersCampaignCard.jsx/SellersCampaignCard'
import { useAuth } from '../pages/auth/useAuth'

const ButtonContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 80%;
  margin-top: 5%;
  margin-bottom: 5%;
  margin-right: 4%;
`

const ButtonCancel = styled(CancelButton)`
  background: #ffffff;
  border: 1px solid #6b6b6b;
  border-radius: 5px;
  color: #6b6b6b;
`

const Img = styled('img')``

const Left = styled('div')`
  display: flex;
`

const Head = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: row;
  width: 80%;
  align-items: center;
  justify-content: space-between;
  margin-top: 5%;
  margin-left: 10%;
  margin-bottom: 5%;
  @media (max-width: 1100px) {
    width: 100%;
  }
`

const ButtonStyled = styled(Button)`
  font-weight: 400;
  font-size: 30px;
  line-height: 32px;
  @media (max-width: 1100px) {
    margin-left: 0px;
  }
`

const CreateCampaigns = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { currentUser: user } = useAuth()
  const role = useMemo(
    () => String(user?.role)?.toLowerCase(),
    [user?.role]
  )
  const {
    handleSubmit,
    formState: { isSubmitting },
    control
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all'
  })

  const onSubmit = handleSubmit(
    React.useCallback(
      data => {
        dispatch(fetchSellersCreate(data))
      },
      [dispatch]
    )
  )
  const [openAlert, setOpenAlert] = useState(false)
  const handleCloseAlert = (_, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenAlert(false)
  }

  return (
    <>
      {process.env.NODE_ENV === 'development' ? (
        <DevTool control={control} placement="top-left" />
      ) : null}
      <Head>
        <Left>
          <ButtonStyled
            variant="text"
            onClick={() => navigate('/campanas')}
            startIcon={<Img src={goBack} height="29" alt="back" />}
          >
            Crear Campaña
          </ButtonStyled>
        </Left>
      </Head>
      <Center>
        <CampaignGeneralInformationForm
          title="Datos Generales"
          control={control}
        />
        <SellersCampaignCard control={control} />
        <ButtonContainer>
          <ButtonCancel onClick={() => navigate('/campanas')}>
            Cancelar
          </ButtonCancel>
          <SubmitButton
            onClick={onSubmit}
            disabled={role === 'viewer'}
            isLoading={isSubmitting}
          >
            Crear campaña
          </SubmitButton>
        </ButtonContainer>
        <Alert open={openAlert} onClose={handleCloseAlert} />
      </Center>
    </>
  )
}

export default CreateCampaigns
