import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { formatPhoneNumber } from '../../../../../domain/adpaters/phone/formatPhone'
import { resetUpdateSlice } from '../../../../../domain/features/sellers/sellerUpdate/sellerUpdateSlice'
import { getSellerUpdate } from '../../../../../domain/features/sellers/sellerUpdate/getSellersUpdate'
import fetchSellersUpdate from '../../../../../domain/features/sellers/sellerUpdate/fetchSellersUpdate'
import { basicSchema } from '../../../../../domain/schemas/sellerUpdateSchema'

import SellerGeneralInformationForm from '../../SellerGeneralInformationForm'
import CardWithTitleAndButton from '../../../molecules/CardWithTitleAndButton/CardWithTitleAndButton'
import SkeletonText from '../../../atomics/SkeletonText'
import Modal from '../../../atomics/Modal/Modal'
import CancelButton from '../../../atomics/Button/CancelButton'
import SubmitButton from '../../../atomics/Button/SubmitButton/SubmitButton'
import StaticAlert from '../../../atomics/StaticAlert/StaticAlert'
import CardWithTitle from '../../../molecules/CardWithTitle/CardWithTitle'
import FieldCheckboxController from '../../../molecules/FieldCheckboxController/FieldCheckboxController'
import { CardButton } from '../seller.components'
import ButtonCopy from '../../../atomics/ButtonCopy/ButtonCopy'

const List = styled('ul')`
  padding: 0px;
  margin: 0px;
  list-style-type: none;
  width: 100%;
  display: inline-block;
`
const ListItem = styled('li')`
  padding: 0px;
  margin: 0px;
  margin-bottom: 10px;
  font-size: 15px;
`
const TitleItem = styled('label')``
const BodyItem = styled('label')`
  font-weight: bold;
`
const ButtonContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 95%;
  margin-top: 4px;
  margin-right: 4%;
`

const ButtonCancel = styled(CancelButton)`
  background: #ffffff;
  border: 1px solid #6b6b6b;
  border-radius: 5px;
  color: #6b6b6b;
  box-shadow: none;
`

const SellerBasic = ({
  seller,
  slugSeller,
  onUpdate,
  statusSeller,
  role
}) => {
  const prevStatus = useRef(null)
  const dispatch = useDispatch()
  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting }
  } = useForm({
    resolver: yupResolver(basicSchema),
    mode: 'all'
  })

  useEffect(() => {
    dispatch(resetUpdateSlice())
  }, [dispatch])

  const { status, seller: sellerEdited } = useSelector(getSellerUpdate)

  const [visibleModal, setVisibleModal] = useState(false)

  const openModal = () => {
    reset({
      nameSeller: seller.nameSeller,
      bussinesName: seller.bussinesName === '-' ? '' : seller.bussinesName,
      giro: seller.giro === '-' ? '' : seller.giro,
      rut: seller.rut,
      email: seller.email,
      phoneNumber: seller.phoneNumber === '-' ? '' : seller.phoneNumber
    })
    setVisibleModal(true)
  }
  const closeModal = () => {
    setVisibleModal(false)
  }
  const onSubmit = handleSubmit(
    useCallback(
      data => {
        dispatch(fetchSellersUpdate({ ...data, slugSeller }))
      },
      [dispatch, slugSeller]
    )
  )
  useEffect(() => {
    if (status === 'finished' && prevStatus.current !== 'finished') {
      onUpdate(
        sellerEdited?.slugSeller !== slugSeller
          ? sellerEdited?.slugSeller
          : undefined
      )
      setVisibleModal(false)
    }
    prevStatus.current = status
  }, [status, onUpdate, sellerEdited?.slugSeller, slugSeller])

  const getCopyText = () => {
    return `
      Nombre empresa (seller): ${seller?.nameSeller || ''}
      Razón social: ${seller?.bussinesName || '-'}
      Rut empresa: ${seller?.rut || ''}
      Giro: ${seller?.giro || '-'}
      Email contacto: ${seller?.email || '-'}
      Teléfono empresa: ${
        seller?.phoneNumber ? formatPhoneNumber(seller.phoneNumber) : '-'
      }
      Marcas: ${(seller?.brands || [])
        .map(brand => brand.brandName)
        .join(', ')}
    `
  }

  return (
    <>
      <Modal open={visibleModal} onClose={closeModal}>
        <CardWithTitle title="Editar datos empresa">
          <SellerGeneralInformationForm control={control} />

          <StaticAlert
            color="#C58F42"
            style={{ width: '50%' }}
            title="Recuerda que debes validar que el seller tenga la misma informacion en SAP."
          />

          <FieldCheckboxController
            control={control}
            defaultValue={false}
            name="accept"
            label="Entendido, editar de todas formas."
            showIconError={true}
          />

          <ButtonContainer>
            <ButtonCancel onClick={closeModal}>Cancelar</ButtonCancel>
            <SubmitButton
              color={'#65B8D6'}
              onClick={onSubmit}
              disabled={role === 'viewer'}
              isLoading={isSubmitting || status === 'loading'}
            >
              Guardar cambios
            </SubmitButton>
          </ButtonContainer>
        </CardWithTitle>
      </Modal>
      <CardWithTitleAndButton
        title="Datos empresa"
        render={
          statusSeller === 'finished' &&
          ['admin', 'operations'].includes(role)
            ? () => (
                <>
                  <ButtonCopy
                    title={`Copiar datos empresa ${seller?.nameSeller}`}
                    message={`Datos empresa ${seller?.nameSeller}, copiados!`}
                    getText={getCopyText}
                  />

                  <CardButton
                    title="Editar datos empresa"
                    onClick={openModal}
                  />
                </>
              )
            : null
        }
      >
        <List>
          <ListItem>
            <TitleItem>Nombre empresa (seller)</TitleItem>:{' '}
            <BodyItem>
              <SkeletonText text={seller?.nameSeller} />
            </BodyItem>
          </ListItem>
          {seller?.bussinesName && (
            <ListItem>
              <TitleItem>Razón social</TitleItem>:{' '}
              <BodyItem>
                <SkeletonText text={seller?.bussinesName} />
              </BodyItem>
            </ListItem>
          )}
          <ListItem>
            <TitleItem>Rut empresa</TitleItem>:{' '}
            <BodyItem>
              <SkeletonText text={seller?.rut} />
            </BodyItem>
          </ListItem>
          {seller?.giro && (
            <ListItem>
              <TitleItem>Giro</TitleItem>:{' '}
              <BodyItem>
                <SkeletonText text={seller?.giro} />
              </BodyItem>
            </ListItem>
          )}
          <ListItem>
            <TitleItem>Email contacto</TitleItem>:{' '}
            <BodyItem>
              <SkeletonText
                text={
                  statusSeller === 'finished' && !seller?.email
                    ? '-'
                    : seller?.email
                }
              />
            </BodyItem>
          </ListItem>
          {seller?.phoneNumber && (
            <ListItem>
              <TitleItem>Teléfono empresa</TitleItem>:{' '}
              <BodyItem>{formatPhoneNumber(seller.phoneNumber)}</BodyItem>
            </ListItem>
          )}
        </List>
      </CardWithTitleAndButton>
    </>
  )
}

export default SellerBasic
