import PropTypes from 'prop-types'
import styled from 'styled-components'
import Dropzone from 'react-dropzone'
import isEmpty from 'lodash/isEmpty'
import { Controller } from 'react-hook-form'

import uploadimage from '../../../assets/image/uploadimage.svg'

const getColor = props => {
  if (props.isDragAccept) {
    return props.theme.palette.primary.main
  }
  if (props.isDragReject || props.isError) {
    return props.theme.palette.error.dark
  }
  if (props.isDragActive) {
    return props.theme.palette.warning.main
  }
  if (props.isFocused) {
    return props.theme.palette.text.primary
  }
  return '#D1D0D0'
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 86px;
  height: 86px;
  min-width: 86px;
  border-width: 2px;
  border-radius: 6px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  color: black;
  outline: none;
  transition: border 0.24s ease-in-out;
  cursor: pointer;
`

const UploadIcon = styled('img')``

const UploadImageButton = ({ control, onChange: AddImage }) => {
  return (
    <Controller
      control={control}
      name="media"
      render={({ field: { onChange, onBlur }, formState: { errors } }) => (
        <Dropzone
          multiple
          onDrop={acceptedFiles => {
            AddImage(acceptedFiles)
          }}
        >
          {({
            getRootProps,
            getInputProps,
            isFocused,
            isDragAccept,
            isDragReject,
            isDragActive
          }) => (
            <Container
              {...getRootProps({
                isFocused,
                isDragAccept,
                isDragReject,
                isDragActive,
                isError: !isEmpty(errors?.media)
              })}
            >
              <input
                {...getInputProps({
                  id: 'spreadsheet',
                  onChange,
                  onBlur
                })}
              />
              <UploadIcon src={uploadimage} alt="upload" />
            </Container>
          )}
        </Dropzone>
      )}
    />
  )
}
UploadImageButton.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
  control: PropTypes.any
}
UploadImageButton.defaultProps = {
  name: 'media'
}
export default UploadImageButton
