import methods from '../client/httpMethods'
import request from '../client/request'

const afterSaleService = ({ post, patch }) => ({
  create: async ({
    id,
    type,
    items,
    status,
    penalty,
    transport,
    reason,
    isWithdrawable,
    cancelToken
  }) => {
    try {
      const response = await request(`/suborder/aftersale`, {
        method: post,
        data: JSON.stringify({
          id,
          type,
          status,
          reason,
          items,
          transport,
          isWithdrawable,
          penalty
        }),
        cancelToken
      })
      return await response.data
    } catch (error) {
      return error
    }
  },
  update: async ({ id, type, status, cancelToken }) => {
    try {
      const response = await request(`/suborder/aftersale`, {
        method: patch,
        data: JSON.stringify({
          id,
          type,
          status
        }),
        cancelToken
      })
      return await response.data
    } catch (error) {
      return error
    }
  }
})

export default afterSaleService(methods)
