import axios from 'axios'
import map from 'lodash/fp/map'
import pipe from 'lodash/fp/pipe'
import { createAsyncThunk } from '@reduxjs/toolkit'

import dateApiFormat from '../../adpaters/days/dateApiFormat'
import normalizeOrder from './normalizeOrder'
import subOrderService from '../../../data/services/subOrderService'
import deleteUndefinedEntries from '../../utils/deleteUndefinedEntries'

const fetchOrdersAftersale = createAsyncThunk(
  'orders/aftersale/filter',
  async (
    {
      startDate,
      endDate,
      startDatePayout,
      endDatePayout,
      status,
      accountingStatement,
      sellerList = [],
      typeList,
      shopifyId,
      skip,
      reasonList,
      limit = 25
    },
    { signal, rejectWithValue }
  ) => {
    const source = axios.CancelToken.source()
    signal.addEventListener('abort', () => {
      source.cancel()
    })
    if (limit === -1) {
      limit = 0
    }
    const { data, pagination } =
      await subOrderService.orderAftersaleFilter(
        deleteUndefinedEntries({
          startDate:
            startDate && String(startDate).toLowerCase() !== 'invalid date'
              ? dateApiFormat(startDate)
              : undefined,
          endDate:
            endDate && String(endDate).toLowerCase() !== 'invalid date'
              ? dateApiFormat(endDate)
              : undefined,
          startDatePayout:
            startDatePayout &&
            String(startDatePayout).toLowerCase() !== 'invalid date'
              ? dateApiFormat(startDatePayout)
              : undefined,
          endDatePayout:
            endDatePayout &&
            String(endDatePayout).toLowerCase() !== 'invalid date'
              ? dateApiFormat(endDatePayout)
              : undefined,
          status: status?.join(','),
          accountingStatement: accountingStatement?.join(','),
          sellers: sellerList?.join(','),
          type: typeList?.join(','),
          shopifyId,
          skip: limit < 0 ? undefined : skip,
          limit,
          cancelToken: source.token,
          reason: reasonList
        })
      )

    const orders = pipe(map(normalizeOrder))(data)
    if (Array(...orders).length === 0) {
      return rejectWithValue('Sin contenido')
    }
    return { orders, pagination }
  }
)
export default fetchOrdersAftersale
