import axios from 'axios'
import api from './api'

import persistentStore from '../persistentStore'
import { auth } from '../../presentation/components/pages/auth/firebaseConfig'

const request = axios.create({
  baseURL: api.settlement.BaseUrl,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    app: process.env.REACT_APP_APPLICATION_NAME || 'backOffice',
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
  }
})

request.interceptors.request.use(async function (config) {
  try {
    config.headers.authorization = persistentStore.getToken()
    return config
  } catch (error) {
    const token = await auth.currentUser.getIdToken(true)
    persistentStore.setToken(token)
    config.headers.authorization = token

    return config
  }
})

export default request
