import { drawerWidth } from '../components/templates/Drawer/Drawer'

const getTheme = modeTheme => ({
  palette: {
    background: {
      default: modeTheme === 'dark' ? '#313234' : '#F6F6F7'
    },
    type: modeTheme === 'dark' ? 'dark' : 'light',
    text: {
      primary: modeTheme === 'dark' ? '#FFFFFF' : '#202223',
      secondary: modeTheme === 'dark' ? '#F6F6F7' : '#5C5F62'
    },
    primary: {
      light: '#33997f',
      main: '#008060',
      dark: '#005943'
    },
    secondary: {
      light: '#204f92',
      main: '#2F71D1',
      dark: '#588dda'
    },
    error: {
      main: '#e72020',
      dark: 'red'
    },
    success: {
      light: '#F4FCF7',
      main: '#F4FCF7',
      dark: '#058060'
    },
    warning: {
      light: '#65B8D6,',
      main: '#FFC96B'
    },
    info: {
      light: modeTheme !== 'dark' ? '#313234' : '#c1c1c1',
      main: '#E9F4FD',
      dark: modeTheme !== 'dark' ? '#313234' : '#E9F4FD'
    },
    third: {
      light: '#F6F6F7',
      main: '#ffffff',
      dark: '#313234',
      contrastText: 'rgba(107, 107, 107, 1)'
    }
  },
  typography: {
    button: {
      fontSize: 16,
      letterSpacing: 0.2,
      textTransform: 'capitalize'
    },
    body1: {
      fontSize: '14px',
      lineHeight: '14px',
      letterSpacing: '0.2px'
    }
  },
  overrides: {
    MuiTableCell: {
      footer: {
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 2,
        position: 'sticky',
        backgroundColor: modeTheme === 'dark' ? '#313234' : '#F6F6F7'
      }
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: '#6B6B6B',
        fontSize: '12px'
      }
    },
    MuiDrawer: {
      paper: { width: `${drawerWidth}px` }
    },
    MuiRadio: {
      colorPrimary: '#2F71D1',
      root: {
        color: '#8D9195',
        $checked: '#2F71D1'
      }
    },
    MuiCheckbox: {
      root: {
        color: '#5C5F62',
        $checked: '#2F71D1'
      }
    }
  }
})

export default getTheme
