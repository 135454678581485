import React, { useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import fetchUpdateDocument from '../../../../domain/features/orders/fetchUpdateDocument'
import FieldFileCustom from '../FieldFile/FieldFileCustom'
import fetchDownloadDocument from '../../../../domain/features/orders/fetchDownloadDocument'
import Tooltip from '../../atomics/Tooltip/Tooltip'
import styled from 'styled-components'

import Alert, { typeAlert } from '../../atomics/Alert/Alert'
import {
  deleteDocumentList,
  getUpdateDocument
} from '../../../../domain/features/orders/orderSlice'
import { updateDocument } from '../../../../domain/features/orderDetail/orderDetailSlice'
import fetchDeleteDocument from '../../../../domain/features/orders/fetchDeleteDocument'
import Modal from '../../molecules/Modal/Modal'
import trash from '../../../assets/icon/trash.svg'
import { StyleButtonFileClean } from '../../atomics/ButtonDeleteFile/ButtonDeleteFile'
const Button = styled('a')`
  color: #0c66e4;
`
const DownloadTooltip = styled(Tooltip)`
  text-decoration: underline;
`
const LoadDocument = ({ name, value, label, role, disabled = false }) => {
  const handleClick = event => {
    event.preventDefault()
    event.stopPropagation()
  }

  const dispatch = useDispatch()
  const [updateDocumentMessage, setUpdateDocumentMessage] = useState({
    status: false,
    message: ''
  })
  const [isOpen, setIsOpen] = useState(false)
  const {
    register,
    control,
    formState: { errors, isSubmitting },
    setError
  } = useForm({
    mode: 'all',
    criteriaMode: 'firstError',
    shouldFocusError: true
  })

  const action = files => {
    const file = files[0]
    if (file.size > 2000000) {
      setError(name, {
        type: 'custom',
        message: 'El archivo no puede exceder 2 MB'
      })
      setUpdateDocumentMessage({
        status: 'error',
        message:
          'Error al adjuntar documento tributario. El archivo debe tener un peso menor a 2MB.'
      })
      return false
    } else if (file.type !== 'application/pdf') {
      setError(name, {
        type: 'custom',
        message:
          'Error al adjuntar documento tributario. El archivo debe ser formato PDF'
      })
      setUpdateDocumentMessage({
        status: 'error',
        message:
          'Error al adjuntar documento tributario. El archivo debe ser formato PDF'
      })
      return false
    } else {
      return true
    }
  }

  const { status } = useSelector(getUpdateDocument)
  useEffect(() => {
    if (status === 'finished') {
      dispatch(updateDocument(name))
    }
  }, [status, dispatch, name])

  const file = useWatch({ name, control })
  useEffect(() => {
    setUpdateDocumentMessage({ status: false, message: '' })
    if (file && file[0]) {
      if (action(file)) {
        dispatch(fetchUpdateDocument({ document: file[0], orderId: name }))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file, dispatch, setUpdateDocumentMessage])

  const handleDownload = orderId => e => {
    e.preventDefault()
    dispatch(fetchDownloadDocument({ orderId }))
  }

  const handleDeleteFile = orderId => e => {
    e.preventDefault()
    e.stopPropagation()
    setIsOpen(false)
    dispatch(fetchDeleteDocument({ orderId }))
    dispatch(deleteDocumentList(orderId))
  }
  return (
    <div>
      {name && value ? (
        <div onClick={handleClick}>
          <DownloadTooltip title="Descargar" followCursor>
            <Button
              disabled={disabled}
              onClick={disabled ? () => {} : handleDownload(name)}
            >{`${label}-${name}.pdf`}</Button>
          </DownloadTooltip>
          {name && role !== 'viewer' ? (
            <>
              <Tooltip title="Eliminar" followCursor>
                <StyleButtonFileClean
                  variant="text"
                  startIcon={<img src={trash} alt="delete" />}
                  onClick={e => {
                    e.preventDefault()
                    setIsOpen(true)
                  }}
                />
              </Tooltip>
              <Modal
                documentName={`${label}-${name}.pdf`}
                open={isOpen}
                onSubmit={handleDeleteFile(name)}
                onClose={() => {
                  setIsOpen(false)
                }}
              ></Modal>
            </>
          ) : (
            ''
          )}
        </div>
      ) : (
        <div
          onClick={e => {
            e.stopPropagation()
          }}
        >
          <FieldFileCustom
            name={name}
            label={label}
            tooltipTitle="Adjuntar documento tributario (PDF)"
            handleDeleteFile={() => {}}
            control={control}
            register={register}
            errors={errors}
            disabled={disabled}
          />
          <Alert
            open={
              isSubmitting ||
              updateDocumentMessage?.status === 'error' ||
              updateDocumentMessage?.status === 'loading'
            }
            onClose={() => {
              setUpdateDocumentMessage({ status: false, message: '' })
            }}
            title={updateDocumentMessage?.message}
            isLoading={updateDocumentMessage?.status === 'loading'}
            isError={updateDocumentMessage?.status === 'error'}
            severity={
              updateDocumentMessage?.status === 'error'
                ? typeAlert.get('error')
                : typeAlert.get('success')
            }
            onClick={e => {
              e.preventDefault()
            }}
          />
        </div>
      )}
    </div>
  )
}

export default LoadDocument
