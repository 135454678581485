import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import fetchPayouts from './fetchPayouts'
import averageWithRound from '../../utils/numeric/averageWithRound'

export const payoutAdapter = createEntityAdapter({
  selectId: payout => payout.sellerName,
  sortComparer: (a, b) => a.sellerName.localeCompare(b.sellerName)
})

const averages = {
  totalSale: 0,
  dispatchedSale: 0,
  payout: 0,
  commission: 0
}

export const payoutSlice = createSlice({
  name: 'payouts',
  initialState: payoutAdapter.getInitialState({
    listOfAverages: { ...averages },
    status: 'idle',
    id: [],
    entities: {}
  }),
  reducers: {
    updatePayouts: payoutAdapter.setAll,
    resetAverages(state) {
      state.listOfAverages = { ...averages }
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchPayouts.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(fetchPayouts.rejected, state => {
      state.status = 'error'
    })
    builder.addCase(fetchPayouts.fulfilled, (state, { payload }) => {
      state.status = 'finished'
      state.listOfAverages = {
        totalSale: averageWithRound(payload, 'totalSale'),
        dispatchedSale: averageWithRound(payload, 'dispatchedSale'),
        payout: averageWithRound(payload, 'payout'),
        commission: averageWithRound(payload, 'commission'),
        otherDiscount: averageWithRound(payload, 'otherDiscount')
      }
      payoutAdapter.setAll(state, payload)
    })
  }
})

const reducer = payoutSlice.reducer
export default reducer
