import { createSlice } from '@reduxjs/toolkit'
import { createSelector } from '@reduxjs/toolkit'
import fetchLiquidate from './fetchLiquidate'

const initialState = {
  status: 'idle',
  errorList: [],
  successList: []
}

const liqudiateSlice = createSlice({
  name: 'liquidate',
  initialState,
  reducers: {
    resetLiqudiate(state) {
      state.status = 'idle'
      state.errorList = []
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchLiquidate.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(fetchLiquidate.rejected, (state, { payload }) => {
      state.status = 'error'
      state.errorList = payload.errors?.filter(p => !p.Estado)
      state.successList = payload?.success?.filter(p => p.Estado)
      state.errorCsv = payload.csv
    })
    builder.addCase(fetchLiquidate.fulfilled, (state, { payload }) => {
      state.status = 'finished'
      state.errorList = payload?.errors?.filter(p => !p.Estado)
      state.successList = payload?.success?.filter(p => p.Estado)
    })
  }
})

const getLiquidateState = state => state.liquidate
export const { resetLiqudiate } = liqudiateSlice.actions
export const liquidateSelector = createSelector(
  [getLiquidateState],
  state => state
)

const reducer = liqudiateSlice.reducer
export default reducer
