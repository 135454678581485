import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import fetchOrderDetail from './fetchOrderDetail'

export const orderDetailAdapter = createEntityAdapter({
  selectId: order => order.idOrder
})

export const orderDetailSlice = createSlice({
  name: 'order',
  initialState: orderDetailAdapter.getInitialState({ status: 'idle' }),
  reducers: {
    updateOrders: orderDetailAdapter.setAll,
    updateDocument: (state, { payload }) => {
      orderDetailAdapter.updateOne(state, {
        id: payload,
        changes: {
          documentName: payload
        }
      })
    },
    deleteDocument: (state, { payload }) => {
      orderDetailAdapter.updateOne(state, {
        id: payload,
        changes: {
          documentName: null
        }
      })
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchOrderDetail.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(fetchOrderDetail.rejected, state => {
      state.status = 'error'
    })
    builder.addCase(fetchOrderDetail.fulfilled, (state, { payload }) => {
      state.status = 'finished'
      orderDetailAdapter.setAll(state, payload)
    })
  }
})

const reducer = orderDetailSlice.reducer
export const { updateDocument, deleteDocument } = orderDetailSlice.actions
export default reducer
