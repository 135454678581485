import sessionStore from '../../../data/sessionPersistentStore'
import { createSlice } from '@reduxjs/toolkit'
import { createSelector } from '@reduxjs/toolkit'
import dayjs from 'dayjs'

const initDates = [
  dayjs(new Date()).startOf('month').toISOString(),
  dayjs(new Date()).toISOString()
]

const initialState = {
  dates: sessionStore.isEmptyRangeDate()
    ? initDates
    : sessionStore.getRangeDate()
}

const rangeOfDaysSlice = createSlice({
  name: 'date',
  initialState,
  reducers: {
    addRangeOfDays(state, action) {
      const dates = action.payload
      const newDates = [
        dayjs(dates[0]).isValid()
          ? dayjs(dates[0]).startOf('day').toISOString()
          : null,
        dayjs(dates[1]).isValid()
          ? dayjs(dates[1]).endOf('day').toISOString()
          : null
      ]
      state.dates = newDates
      sessionStore.setRangeDate(newDates)
    },
    resetRangeOfDays(state) {
      sessionStore.removeRangeDate()
      state.dates = [null, null]
    }
  }
})

export const { addRangeOfDays, resetRangeOfDays } =
  rangeOfDaysSlice.actions

const getDaySelector = state => state.ui.rangeOfDay

export const getCurrentDate = createSelector([getDaySelector], state =>
  state.dates
    ? state.dates.map(date => (date ? dayjs(date).toDate() : null))
    : [null, null]
)

const reducer = rangeOfDaysSlice.reducer
export default reducer
