export const normalizeUser =
  token =>
  ({ displayName, email, photoURL, permissions, uid, ...user }) => {
    const customRole = permissions?.find(
      permission =>
        permission?.appId?.toString() ===
        process.env.REACT_APP_APP_ID.toString()
    )?.role

    return {
      ...user,
      token,
      name: displayName,
      email,
      picture: photoURL,
      photoURL,
      role: customRole,
      uid,
      permissions,
      displayName
    }
  }

const defaultRole = 'viewer'

export const normalizeAuth = ({
  email,
  photoURL,
  permissions,
  uid,
  name,
  picture,
  displayName
}) => {
  const customRole = permissions?.find(
    permission =>
      permission?.appId?.toString() ===
      process.env.REACT_APP_APP_ID.toString()
  )?.role

  return {
    email,
    picture: photoURL || picture,
    role: customRole ? String(customRole) : defaultRole,
    uid,
    permissions,
    displayName: name || displayName
  }
}
