import isEmpty from 'lodash/isEmpty'

const sessionStorage =
  typeof window === 'undefined'
    ? {
        getItem: () => undefined,
        setItem: () => undefined,
        removeItem: () => undefined
      }
    : window.sessionStorage

const keyList = {
  RANGE_DATE: '@range-date',
  RANGE_DATE_PAYOUT: '@range-date-payout',
  SELLER: '@seller-select',
  STATEPRODUCT: '@state-product-select',
  BRAND: '@brand-select',
  CATEGORY: '@category-select'
}

const getItem = key => {
  const item = sessionStorage.getItem(key)
  if (item === 'undefined') {
    return undefined
  }
  if (item === 'null') {
    return null
  }
  try {
    return JSON.parse(item, 'base64')
  } catch (cause) {
    return item
  }
}

const setItem = (key, value) =>
  sessionStorage.setItem(key, JSON.stringify(value))

const removeItem = key => sessionStorage.removeItem(key)

const sessionPersistentStore = () => ({
  getRangeDate: () => getItem(keyList.RANGE_DATE),
  setRangeDate: date => setItem(keyList.RANGE_DATE, date),
  removeRangeDate: () => removeItem(keyList.RANGE_DATE),
  isEmptyRangeDate: () => isEmpty(sessionPersistentStore().getRangeDate()),
  getRangeDatePayout: () => getItem(keyList.RANGE_DATE_PAYOUT),
  setRangeDatePayout: date => setItem(keyList.RANGE_DATE_PAYOUT, date),
  removeRangeDatePayout: () => removeItem(keyList.RANGE_DATE_PAYOUT),
  isEmptyRangeDatePayout: () =>
    isEmpty(sessionPersistentStore().getRangeDatePayout()),
  getSellerSelect: () => getItem(keyList.SELLER),
  setSellerSelect: seller => setItem(keyList.SELLER, seller),
  removeSellerSelect: () => removeItem(keyList.SELLER),
  isEmptySellerSelect: () =>
    isEmpty(sessionPersistentStore().getSellerSelect()),
  getBrandSelect: () => getItem(keyList.BRAND),
  setBrandSelect: brand => setItem(keyList.BRAND, brand),
  removeBrandSelect: () => removeItem(keyList.BRAND),
  isEmptyBrandSelect: () =>
    isEmpty(sessionPersistentStore().getBrandSelect()),
  getCategorySelect: () => getItem(keyList.CATEGORY),
  setCategorySelect: category => setItem(keyList.CATEGORY, category),
  removeCategorySelect: () => removeItem(keyList.CATEGORY),
  isEmptyCategorySelect: () =>
    isEmpty(sessionPersistentStore().getCategorySelect()),
  getStateFilter: () => getItem(keyList.STATEPRODUCT),
  setStateFilter: state => setItem(keyList.STATEPRODUCT, state),
  removeStateFilter: () => removeItem(keyList.STATEPRODUCT),
  isEmptyStateFilter: () =>
    isEmpty(sessionPersistentStore().getStateFilter())
})

export default sessionPersistentStore()
