export const createVariant = ({
  variantId,
  price,
  compareAtPrice,
  weight,
  sku,
  own_sku,
  color,
  availableQuantity,
  imageName,
  title,
  inventoryLevelId,
  availableDelta,
  locationId,
  currentAvailable,
  mediaForVariant,
  currentMedia,
  inventoryItemId
}) => ({
  variantId,
  price,
  compareAtPrice,
  weight,
  sku,
  own_sku: own_sku,
  color: color || title,
  availableQuantity,
  imageName,
  inventoryLevelId,
  availableDelta,
  locationId,
  currentAvailable,
  mediaForVariant,
  currentMedia,
  inventoryItemId
})
