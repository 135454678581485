import Paper from '@material-ui/core/Paper'
import React from 'react'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined'
import Modal from '../../atomics/Modal/Modal'
import Text from '../../atomics/Typography/Text'
import Title from '../../atomics/Typography/Title'

const ModalContainer = styled(Modal)``

const IconSuccess = styled(CheckCircleOutlineIcon)`
  font-size: 60px;
  fill: ${({ theme }) => theme.palette.success.main};
  margin-right: 1rem;
  line-height: 1px;
`

const IconError = styled(ReportProblemOutlinedIcon)`
  font-size: 60px;
  fill: ${({ theme }) => theme.palette.error.main};
  margin-right: 1rem;
  stroke-width: 0.5px;
`

const TitleModal = styled(Title)`
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
`

const Container = styled(Paper)`
  padding: 1.5rem;
  padding-top: 0.5rem;
  background-color: ${({ theme }) => theme.palette.background.default};
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  border-radius: 10px;

  @media (min-width: 1100px) {
    width: 574px;
  }

  @media (max-width: 1100px) {
    margin: 1rem;
  }
`

const Center = styled('div')`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`

const Divider = styled('div')`
  margin-bottom: 1rem;
`

const CloseButton = styled('div')`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
`
CloseButton.displayName = 'CloseButton'

const Head = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin-bottom: 2rem;
`
Head.displayName = 'Head'

const ModalLiquidate = ({
  title,
  headerText,
  body,
  footerText,
  footer,
  status,
  ...props
}) => {
  const iconType = React.useMemo(
    () =>
      new Map([
        ['finished', <IconSuccess key="success" />],
        ['error', <IconError key="error" />]
      ]),
    []
  )

  return (
    <ModalContainer {...props}>
      <Container elevation={2}>
        <IconButton aria-label="close" onClick={props?.onClose} />
        <Head>
          {status === 'error' || status === 'finished'
            ? iconType.get(status)
            : null}
          <TitleModal>{title}</TitleModal>
        </Head>
        <Divider />
        <Text>{headerText}</Text>
        <Divider />
        {body && body()}
        <Divider />
        {footerText ? <Text>{footerText}</Text> : null}
        <Divider />
        {footer ? <Center>{footer()}</Center> : null}
      </Container>
    </ModalContainer>
  )
}

export default ModalLiquidate
