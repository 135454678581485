import styled from 'styled-components'
import PropTypes from 'prop-types'

const LabelStyled = styled('label')`
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.04em;
  text-align: left;
  margin-bottom: 9px;
`
const Label = ({ required, children, ...props }) => (
  <LabelStyled {...props}>
    {children}
    {required ? ' *' : ''}
  </LabelStyled>
)
Label.propTypes = {
  required: PropTypes.bool,
  children: PropTypes.node
}
export default Label
