import { useEffect, useState } from 'react'
import { useFieldArray } from 'react-hook-form'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import Button from '@material-ui/core/Button'

import ToolTip from '../../atomics/Tooltip'
import FieldInputController from '../../molecules/FieldInputController'
import Label from '../../atomics/Label'
import trash from '../../../assets/icon/trash.svg'
import fetchBrandDelete from '../../../../domain/features/sellers/brandDelete/fetchBrandDelete'
import StaticAlert from '../../atomics/StaticAlert/StaticAlert'
import fetchMultipleBrands from '../../../../domain/features/brandsSlugSeller/fetchMultipleBrands'
import { useDispatch } from 'react-redux'

const StyleButtonFile = styled(Button)`
  box-sizing: border-box;
  justify-content: flex-end;
  height: 35px;
  border-radius: 4px;
  font-size: 17px;
  font-weight: 200;
  line-height: 20px;
  letter-spacing: 0.04em;
  text-align: center;
  text-decoration: underline;
  min-width: 25px;
  .MuiButtonBase-root {
    padding-left: 0;
  }
  .Mui-disabled {
    cursor: not-allowed;
  }
`

const StyledField = styled(FieldInputController)`
  width: 100%;
  ${props =>
    props.edit &&
    css`
      .MuiOutlinedInput-root {
        height: 36px;
        font-size: 13px;
        margin-top: 1%;
        border-color: #c58f42;
        & fieldset {
          border-color: #c58f42;
        }
      }
    `}
`

const InputContainer = styled('div')`
  display: flex;
  align-items: center;
  align-items: center;
  margin-bottom: 14px;
  width: 100%;
`

const AddButton = styled(Button)`
  color: #2469ce;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: 0.2px;
  text-decoration-line: underline;
  width: fit-content;
`

const Container = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px 50px;
  width: 100%;
  max-height: 60vh;
  overflow: auto;
`

export function BrandsEditCard({
  control,
  sellerName,
  setError,
  productsEditAlert
}) {
  const dispatch = useDispatch()
  const [showError, setShowError] = useState(false)
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'brands'
  })

  useEffect(() => {
    append('')
  }, [append])

  const onKeyUp = e => {
    if (e.key === 'Enter') {
      append('')
    }
  }

  const onDelete = async index => {
    const brand = fields.at(index)
    if (brand.name && brand.name.trim() !== '' && brand.initial.trim()) {
      const result = await fetchBrandDelete({
        sellerName: sellerName,
        brandName: brand.name
      })

      if (!result?.status) {
        setError(`brands[${index}].name`, {
          type: 'manual',
          message: ''
        })
        setShowError(true)
      } else {
        remove(index)
        dispatch(fetchMultipleBrands({ refetch: true }))
      }
    } else {
      remove(index)
      setShowError(false)
    }
  }

  return (
    <>
      <Label required>Nombre marca</Label>
      <Container>
        {fields.map((field, index) => {
          return (
            <InputContainer key={field.id}>
              <StyledField
                defaultValue={field.name}
                name={`brands[${index}].name`}
                control={control}
                placeholder="Ej: Simple Cook"
                onKeyUp={onKeyUp}
                edit={
                  field.name !== field.initial &&
                  field.initial &&
                  productsEditAlert > 0
                }
              />
              <ToolTip title="Eliminar">
                <StyleButtonFile
                  variant="text"
                  alt="delete"
                  onClick={() => onDelete(index)}
                >
                  <img src={trash} alt="delete" />
                </StyleButtonFile>
              </ToolTip>
            </InputContainer>
          )
        })}
        <AddButton
          onClick={() => {
            append('')
          }}
        >
          + Agregar marca
        </AddButton>
      </Container>

      {showError && (
        <StaticAlert
          color="#E72020"
          style={{ width: '70%' }}
          title="No puedes eliminar una marca asociada a productos existentes."
        />
      )}
    </>
  )
}

BrandsEditCard.propTypes = {
  control: PropTypes.any
}

export default BrandsEditCard
