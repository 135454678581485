import slugify from 'slugify'

export const createSlugSeller = name =>
  slugify(`seller-${name}`, {
    replacement: '-',
    remove: undefined,
    lower: true,
    strict: false,
    locale: 'es',
    trim: true
  })
