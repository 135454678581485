import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Scroll from '../atomics/ScrollTable'
import Title from '../atomics/Typography/Title'
import Row from '../atomics/Styles/Row'
import DataTable from '../templates/DataTable'
import { Button } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { getSellers } from '../../../domain/features/sellers/getSellers'
import fetchSellers from '../../../domain/features/sellers/fetchSellers'
import { usePagination } from '../../hooks/usePagination'
import FilterContainer from '../atomics/FilterContainer'
import SearchProduct from '../organims/SearchProduct/SearchProduct'
import { useDebounce } from 'use-debounce'
import { useAuth } from '../pages/auth/useAuth'

const Head = styled(Row)`
  justify-content: space-between;
`
const ContainerSearch = styled(Box)`
  width: 90%;
  @media (max-width: 700px) {
    margin-right: 0px;
  }
`

const AddSellerButton = styled(Button)`
  width: 163px;
  height: 35px;
  font-size: 15px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  margin-top: 58px;
  .MuiButton-startIcon {
    font-size: 15px;
  }
`

const AdminSellers = () => {
  const navigate = useNavigate()
  const [pageCurrentIndex, setPage] = React.useState(0)
  const [query, setQuery] = React.useState(undefined)
  const [debouncedQuery] = useDebounce(query, 800)
  const [perPage, setPerPage] = React.useState(25)
  const { currentUser: user } = useAuth()
  const dispatch = useDispatch()
  const role = useMemo(
    () => String(user?.role)?.toLowerCase(),
    [user?.role]
  )
  const { sellers, status } = useSelector(getSellers)

  useEffect(() => {
    const promise = dispatch(fetchSellers(debouncedQuery))
    return () => {
      promise.abort()
    }
  }, [dispatch, debouncedQuery])

  const isViewer = role === 'viewer'

  const [dataToDisplay, totalPages] = usePagination(
    sellers,
    perPage,
    pageCurrentIndex + 1
  )

  const columns = useMemo(
    () => [
      {
        Header: 'Seller',
        accessor: 'nameSeller'
      },
      {
        Header: 'Email contacto',
        accessor: 'email',
        width: 100
      },
      {
        Header: 'Rut empresa',
        accessor: 'rut',
        width: 80
      },
      {
        Header: 'Cantidad de marcas',
        accessor: 'brands',
        Cell: cell => {
          return cell.value ? cell.value.length : 0
        },
        width: 100
      },
      {
        Header: 'Comisión global',
        accessor: 'commissionSeller',
        width: 80
      },
      {
        Header: 'Comisión por categoría',
        accessor: 'commissionCategories',
        Cell: cell => {
          return cell.value && cell.value.length > 0 ? 'Si' : 'No'
        },
        width: 100
      },
      {
        Header: 'Campaña activa',
        accessor: 'campaigns',
        Cell: cell => {
          return cell.value && cell.value.length > 0 ? 'Si' : 'No'
        },
        width: 100
      }
    ],
    []
  )

  const rows = React.useMemo(
    () => (status === 'loading' ? Array(25).fill({}) : dataToDisplay),
    [status, dataToDisplay]
  )

  return (
    <>
      <>
        <Head>
          <Box mt="58px" mb="35px">
            <Title>Administración Sellers</Title>
          </Box>
          <AddSellerButton
            variant="contained"
            onClick={() => navigate('/sellers/nuevo')}
            disabled={isViewer}
            color="primary"
            startIcon={<AddIcon />}
          >
            Agregar seller
          </AddSellerButton>
        </Head>
        <Paper elevation={2}>
          <FilterContainer>
            <ContainerSearch mr="25px">
              <SearchProduct
                query={query}
                setQuery={setQuery}
                placeholder="Busca por nombre o rut del seller"
                style={{ maxWidth: '700px' }}
              />
            </ContainerSearch>
          </FilterContainer>

          <Scroll>
            <DataTable
              manualSortBy={false}
              isLoading={status === 'loading'}
              data={rows}
              columns={columns}
              isProductsPage
              withPage={true}
              setPage={setPage}
              perPage={perPage}
              setPerPage={setPerPage}
              pageCurrentIndex={pageCurrentIndex || 0}
              totalPage={totalPages}
            />
          </Scroll>
        </Paper>
      </>
    </>
  )
}

export default AdminSellers
