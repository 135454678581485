import React from 'react'
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'
import {
  addRangeOfDaysPayout,
  getCurrentDatePayout
} from '../../../../domain/features/ui/rangeOfDaysPayoutSlice'
import InputRangeDate from '../../atomics/Input/InputRangeDate'

const RangeOfDaysPayout = ({ withborder }) => {
  const date = useSelector(getCurrentDatePayout)
  const dispatch = useDispatch()

  return (
    <InputRangeDate
      withborder={withborder}
      prefixName="Fecha Liquidación"
      value={date}
      onChange={value => {
        dispatch(
          addRangeOfDaysPayout([
            dayjs(value[0]).startOf('day').toDate(),
            dayjs(value[1]).endOf('day').toDate()
          ])
        )
      }}
    />
  )
}

export default RangeOfDaysPayout
