import axios from 'axios'
import map from 'lodash/fp/map'
import pipe from 'lodash/fp/pipe'
import { createAsyncThunk } from '@reduxjs/toolkit'

import dateApiFormat from '../../adpaters/days/dateApiFormat'
import normalizeOrder from './normalizeOrder'
import subOrderService from '../../../data/services/subOrderService'
import deleteUndefinedEntries from '../../utils/deleteUndefinedEntries'

const fetchOrders = createAsyncThunk(
  'orders/filter',
  async (
    {
      startDate,
      endDate,
      startDatePayout,
      endDatePayout,
      status,
      accountingStatement,
      document,
      sellerList = [],
      typeList,
      shopifyId,
      skip,
      limit = 25
    },
    { signal, rejectWithValue }
  ) => {
    const source = axios.CancelToken.source()
    signal.addEventListener('abort', () => {
      source.cancel()
    })
    if (limit === -1) {
      limit = 0
    }
    const documentType =
      document?.indexOf('Boleta') >= 0
        ? document?.indexOf('Factura') >= 0
          ? undefined
          : { documentType: 'boleta' }
        : document?.indexOf('Factura') >= 0
          ? { documentType: 'factura' }
          : undefined
    const documentName =
      document?.indexOf('Documentos adjuntos') >= 0
        ? document?.indexOf('Documentos por adjuntar') >= 0
          ? undefined
          : { documentName: 1 }
        : document?.indexOf('Documentos por adjuntar') >= 0
          ? { documentName: 0 }
          : undefined
    const { data, pagination } = await subOrderService.orderFilter({
      ...deleteUndefinedEntries({
        startDate:
          startDate && String(startDate).toLowerCase() !== 'invalid date'
            ? dateApiFormat(startDate)
            : undefined,
        endDate:
          endDate && String(endDate).toLowerCase() !== 'invalid date'
            ? dateApiFormat(endDate)
            : undefined,
        startDatePayout:
          startDatePayout &&
          String(startDatePayout).toLowerCase() !== 'invalid date'
            ? dateApiFormat(startDatePayout)
            : undefined,
        endDatePayout:
          endDatePayout &&
          String(endDatePayout).toLowerCase() !== 'invalid date'
            ? dateApiFormat(endDatePayout)
            : undefined,
        status: status?.join(','),
        accountingStatement: accountingStatement?.join(','),
        sellers: sellerList?.join(','),
        type: typeList?.join(','),
        shopifyId,
        skip: limit < 0 ? undefined : skip,
        limit,
        cancelToken: source.token
      }),
      ...documentType,
      ...documentName
    })

    const orders = pipe(map(normalizeOrder))(data)
    if (Array(...orders).length === 0) {
      return rejectWithValue('Sin contenido')
    }
    return { orders, pagination }
  }
)
export default fetchOrders
