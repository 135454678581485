const normalizeSeller = ({
  _id,
  nameSeller,
  email,
  rut,
  slugSeller,
  tagSeller,
  updatedAt,
  campaigns,
  brands,
  commissionSeller
}) => ({
  _id,
  nameSeller,
  email,
  rut,
  slugSeller,
  tagSeller,
  updatedAt,
  campaigns,
  brands,
  commissionSeller,
  to: {
    pathname: `/sellers/${slugSeller}`,
    state: {
      _id,
      nameSeller,
      email,
      rut,
      slugSeller,
      tagSeller,
      updatedAt,
      campaigns,
      brands,
      commissionSeller,
      tooltip: 'Ver seller'
    },
    tooltip: 'Ver seller'
  }
})

export default normalizeSeller
