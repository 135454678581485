import { createSlice } from '@reduxjs/toolkit'
import { createSelector } from '@reduxjs/toolkit'
import fetchAfterSale from './fetchAfterSale'
import fetchUpdateAfterSale from './fetchUpdateAfterSale'

const initialState = {
  status: 'idle',
  errorMessage: ''
}

const afterSaleSlice = createSlice({
  name: 'afterSale',
  initialState,
  reducers: {
    resetAfterSale(state) {
      state.status = 'idle'
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchAfterSale.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(fetchAfterSale.rejected, (state, { payload }) => {
      state.status = 'error'
      state.errorMessage = payload?.message
    })
    builder.addCase(fetchAfterSale.fulfilled, state => {
      state.status = 'finished'
    })
    builder.addCase(fetchUpdateAfterSale.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(
      fetchUpdateAfterSale.rejected,
      (state, { payload }) => {
        state.status = 'error'
        state.errorMessage = payload?.message
      }
    )
    builder.addCase(fetchUpdateAfterSale.fulfilled, state => {
      state.status = 'finished'
    })
  }
})

export const { resetAfterSale } = afterSaleSlice.actions
const getAfterSale = state => state.afterSale
export const afterSaleSelector = createSelector(
  [getAfterSale],
  state => state
)

const reducer = afterSaleSlice.reducer
export default reducer
